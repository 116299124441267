import { GameEffect, GameEffectType } from './GameEffect';

export class MoveDunaiaEffect implements GameEffect {
  type: GameEffectType.MoveDunaia = GameEffectType.MoveDunaia;
  count: number;

  constructor(count: number = 1) {
    this.count = count;
  }
}

