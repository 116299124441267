import {OptionsSpec} from '@gamepark/rules-api'
import {TFunction} from 'i18next'
import GameState from './GameState'
import Color, {colors} from './color/Color';
import { Player } from '@gamepark/react-client';

/**
 * This is the options for each players in the game.
 */
export type DunaiaPlayerOptions = { id: Color }

/**
 * This is the type of object that the game receives when a new game is started.
 * The first generic parameter, "{}", can be changed to include game options like variants or expansions.
 */
export type DunaiaOptions = {
  players: DunaiaPlayerOptions[]
}

/**
 * Typeguard to help Typescript distinguish between a GameState and new game's options, for you main class constructor.
 * @param arg GameState or Game options
 * @return true if arg is a Game options
 */
export function isGameOptions(arg: GameState | DunaiaOptions): arg is DunaiaOptions {
  return typeof (arg as GameState).dice === 'undefined'
}

/**
 * This object describes all the options a game can have, and will be used by GamePark website to create automatically forms for you game
 * (forms for friendly games, or forms for matchmaking preferences, for instance).
 */
export const DunaiaOptionsSpec: OptionsSpec<DunaiaOptions> = {
  players: {
    id: {
      label: (t: TFunction) => t('Players color'),
      values: colors,
      valueSpec: color => ({label: t => getPlayerName(color, t)})
    }
  }
}


export const getName = (color: Color, playersInfo: Player<Color>[], t: TFunction) =>
  playersInfo.find((p) => p.id === color)?.name || getPlayerName(color, t);

export function getPlayerName(playerId: Color, t: TFunction) {
  switch (playerId) {
    case Color.Red:
      return t('Red player')
    case Color.Purple:
      return t('Purple player')
    case Color.Green:
      return t('Green player')
    case Color.Yellow:
      return t('Yellow player')
  }
}
