import { Prophecy, ProphecyColor } from './Prophecy';
import { Building } from '../building/Building';
import { getBuiltBuildings, isBuiltBuilding } from '../../utils/building.utils';
import { getCompletedArtefacts } from '../../utils/is-over.utils';

export const Prophecy1: Prophecy = {
  color: ProphecyColor.GREEN,
  score: 2,
  canPlayerGet: (player) => getBuiltBuildings(player).length >= 6,
};

export const Prophecy2: Prophecy = {
  color: ProphecyColor.GREEN,
  score: 3,
  canPlayerGet: (player) => getBuiltBuildings(player).length >= 7,
};

export const Prophecy3: Prophecy = {
  color: ProphecyColor.GREEN,
  score: 4,
  canPlayerGet: (player) => getBuiltBuildings(player).length >= 8,
};

export const Prophecy4: Prophecy = {
  color: ProphecyColor.PURPLE,
  score: 2,
  canPlayerGet: (player) => player.metalFlowers >= 12,
};

export const Prophecy5: Prophecy = {
  color: ProphecyColor.PURPLE,
  score: 2,
  canPlayerGet: (player) => getCompletedArtefacts(player.artefacts).length >= 2,
};

export const Prophecy6: Prophecy = {
  color: ProphecyColor.PURPLE,
  score: 2,
  canPlayerGet: (player) => getCompletedArtefacts(player.artefacts).length >= 1 && player.metalFlowers >= 7,
};

export const Prophecy7: Prophecy = {
  color: ProphecyColor.ORANGE,
  score: 3,
  canPlayerGet: (player) =>
    isBuiltBuilding(player.buildings[0][0]) &&
    isBuiltBuilding(player.buildings[2][0]) &&
    isBuiltBuilding(player.buildings[0][2]) &&
    isBuiltBuilding(player.buildings[2][2]),
};

export const Prophecy8: Prophecy = {
  color: ProphecyColor.ORANGE,
  score: 2,
  canPlayerGet: (player) =>
    [0, 1, 2].some(
      (index) =>
        isBuiltBuilding(player.buildings[index][0]) &&
        isBuiltBuilding(player.buildings[index][1]) &&
        isBuiltBuilding(player.buildings[index][2])
    ) ||
    [0, 1, 2].some(
      (index) =>
        isBuiltBuilding(player.buildings[0][index]) &&
        isBuiltBuilding(player.buildings[1][index]) &&
        isBuiltBuilding(player.buildings[2][index])
    ),
};

export const Prophecy9: Prophecy = {
  color: ProphecyColor.ORANGE,
  score: 3,
  canPlayerGet: (player, buildings: Building[]) =>
    [1, 2, 3].every((level) => getBuiltBuildings(player).some((b) => buildings[b!.building]?.level === level)),
};

export const Prophecies: Prophecy[] = [
  Prophecy1,
  Prophecy2,
  Prophecy3,
  Prophecy4,
  Prophecy5,
  Prophecy6,
  Prophecy7,
  Prophecy8,
  Prophecy9,
];
