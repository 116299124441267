import { ChooseDice } from '../moves/ChooseDice';
import MoveView from '../moves/MoveView';
import MoveType from '../moves/MoveType';
import { RecycleDice } from '../moves/RecycleDice';
import { GainArtefactPart } from '../moves/GainArtefactPart';
import { BuyBuilding } from '../moves/BuyBuilding';
import { MoveConstructionToken } from '../moves/MoveConstructionToken';
import { MoveDunaia } from '../moves/MoveDunaia';
import { GainMemoryChips } from '../moves/GainMemoryChips';
import { RetrieveProphecy } from '../moves/RetrieveProphecy';

export const isChooseDice = (move: MoveView): move is ChooseDice => move.type === MoveType.ChooseDice;
export const isRecycle = (move: MoveView): move is RecycleDice => move.type === MoveType.RecycleDice;
export const isRecycleOnBoard = (move: MoveView): move is RecycleDice =>
  isRecycle(move) && move.boardAction !== undefined;
export const isRecycleOnBuilding = (move: MoveView): move is RecycleDice =>
  isRecycle(move) && move.building !== undefined;

export const isGainArtefactPart = (move: MoveView): move is GainArtefactPart => move.type === MoveType.GainArtefactPart;
export const isBuyBuilding = (move: MoveView): move is BuyBuilding => move.type === MoveType.BuyBuilding;
export const isMoveConstructionToken = (move: MoveView): move is MoveConstructionToken =>
  move.type === MoveType.MoveConstructionToken;

export const isMoveDunaia = (move: MoveView): move is MoveDunaia => move.type === MoveType.MoveDunaia;
export const isRollDice = (move: MoveView): move is MoveDunaia => move.type === MoveType.RollDice;
export const isGainMemoryChip = (move: MoveView): move is GainMemoryChips => move.type === MoveType.GainMemoryChips;
export const isRetrieveProphecy = (move: MoveView): move is RetrieveProphecy => move.type === MoveType.RetrieveProphecy;
