import GameState from '../GameState';
import GameView from '../GameView';
import { Player } from '../player/Player';
import { BoardActions } from '../material/board/BoardActions';
import { GameEffectType } from '../effect/GameEffect';
import { BoardBuildingPrices } from '../material/board/BoardBuildingPrices';
import { getEffectRule } from './pending-effect.utils';



/**
 * Get all action available for the player
 * @param state The game state
 * @param player The player
 */
export const getBoardActions = (state: GameState | GameView, player: Player): number[] => {
  return Array.from(BoardActions.entries())
    .filter((entry) => canRecycleDieForAction(player, state.buildings, entry[0]))
    .filter((entry) => {
      const rule = getEffectRule(entry[1]);
      return !rule.legalMoves || rule.legalMoves(state, player).length
    })
    .map((e) => e[0]);
};

/**
 * Can the player do the board action
 * @param player The player
 * @param buildings available building for buying
 * @param action the action
 */
export const canRecycleDieForAction = (player: Player, buildings: number[][][], action: number): boolean => {
  if (player.pending.length) {
    return false;
  }

  switch (BoardActions[action].type) {
    case GameEffectType.GainMemoryChips:
      return player.memoryChips.length < 6;
    case GameEffectType.BuyBuilding:
      return (
        !!player.constructionTokens &&
        player.buildings.flat().some((b) => b === undefined || b === null) &&
        buildings.some((col, x) => col.some((_, y) => player.metalFlowers >= BoardBuildingPrices[x][y]))
      );
    default:
      return true;
  }
};
