import GameState from '../GameState';
import Move from '../moves/Move';
import { getPlayerPendingEffectRules } from './pending-effect.utils';
import { isPlayerWinProphecy } from './prophecy.utils';
import { retrieveProphecyMove } from '../moves/RetrieveProphecy';
import { getNonRecycledDie } from './dice.utils';
import { Prophecy } from '../material/prophecy/Prophecy';
import { Building } from '../material/building/Building';
import { getBuildingForPosition, isBuildingBuilding } from './building.utils';
import { DunaiaPositionBuildings } from '../default/DunaiaPositionBuildings';
import { Phase } from '../common/Phase';
import { awakeDunaiaMove } from '../moves/AwakeDunaia';
import { moveConstructionTokenMove } from '../moves/MoveConstructionToken';

/**
 * Return all possible automatic moves for a given moment of the game
 * @param state The game state
 * @param prophecies All prophecies
 * @param buildings All buildings
 */
export const getPredictableAutomaticMoves = (
  state: GameState,
  prophecies: Prophecy[],
  buildings: Building[]
): Move[] => {
  const moves: Move[] = [];
  const player = state.players.find((p) => p.color === state.activePlayer)!;
  const nonRecycledDice = getNonRecycledDie(player.dice);

  if (player.phase === Phase.DunaiaAwakening && !!nonRecycledDice) {
    const awakenDunaia = nonRecycledDice.dice.value - 1;
    const dunaiaPositionBuildings = DunaiaPositionBuildings[player.dunaias[awakenDunaia]];
    const moveConstructionTokens = dunaiaPositionBuildings
      .map((position) => ({
        position: position,
        building: getBuildingForPosition(player, position),
      }))
      .filter((b) => isBuildingBuilding(b.building))
      .map((b) => moveConstructionTokenMove(b.building?.building!));

    moves.push(
      ...moveConstructionTokens,
      awakeDunaiaMove(
        awakenDunaia,
        moveConstructionTokens.map((move) => move.building)
      )
    );
  }

  // In case there is prophecies won by the given player trigger
  if (player.phase === Phase.RecyclingDie && !nonRecycledDice && !player.pending.length && !player.end) {
    const wonProphecies = state.prophecies.filter((p) => isPlayerWinProphecy(player, prophecies[p], buildings));
    if (wonProphecies.length) {
      wonProphecies.forEach((p) => moves.push(retrieveProphecyMove(p)));
    }
  }

  if (player.pending.length) {
    getPlayerPendingEffectRules(player)
      .filter((effect) => effect.automaticMoves)
      .flatMap((effect) => effect.automaticMoves!(player))
      .forEach((move) => moves.push(move));
  }

  return moves;
};
