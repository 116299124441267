/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { modalOverlay } from '../styles/Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';
import { Images } from '../images/Images';

type HelpModalProps = {
  onClose: () => void;
} & HTMLAttributes<HTMLDivElement>;

const HelpModal: FC<HelpModalProps> = (props) => {
  const { onClose, title, children, ...rest } = props;

  const prevent = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const close = (e: any) => {
    prevent(e)
    onClose();
  }

  const modal = (
    <>
      <div
        key='overlay-viewer'
        css={[
          modalOverlay
        ]}
        onClick={close}
      />
      <div
        key="modal"
        css={modalStyle}
        onClick={prevent}
        { ...rest }
      >
        <div css={modalBackground} />
        <div css={closeModal} onClick={close}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div css={titleContainer}>
          <span>{title}</span>
        </div>
        {children}
      </div>
    </>

  )

  let letterBox = document.getElementById('letterbox')!;
  return createPortal(modal, letterBox);
}


const modalBackground = css`
  border-radius: 3em;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(${Images.ModalBackground}) no-repeat;
  background-size: cover;

  &:after {
    content: '';
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 3em;
  }
`

const titleContainer = css`
  margin-bottom: 4em;
  border-bottom: 0.2em solid white;
  > span {
    font-size: 3.5em;
  }
`

const modalStyle = css`
  transition: opacity 0.5s;
  position: absolute;
  white-space: pre-wrap;
  width: 100em;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%) translateZ(50em);
  left: 50%;
  margin-left: 0;
  background: white;
  border: 0.3em solid gold;
  box-shadow: 0 0 1em white;
  border-radius: 3em;
  padding: 3em 3em 3.4em;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const closeModal = css`
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  padding: 0.2em 0.4em;
  font-size: 7em;
  color: white !important;
  cursor: pointer;

  &:hover {
      color: black !important;
  }
`;

export {
  HelpModal
}
