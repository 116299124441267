import { GameEffect, GameEffectType } from './GameEffect';

export class GainMetalFlowerEffect implements GameEffect {
  type: GameEffectType.GainMetalFlower = GameEffectType.GainMetalFlower;
  automatic: boolean = true;
  metalFlowers: number;

  constructor(metalFlowers: number) {
    this.metalFlowers = metalFlowers;
  }
}
