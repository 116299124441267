/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import { MemoryChip } from './MemoryChip';
import { Player } from '@gamepark/dunaia/player/Player';
import { useAnimations, usePlay, usePlayerId } from '@gamepark/react-client';
import { memoryChipHeight, memoryChipWidth } from '../../styles/Style';
import { getMemoryChipEffect } from '@gamepark/dunaia/utils/pending-effect.utils';
import { DunaiaButton } from '../../button/DunaiaButton';
import { gainMemoryChipsMove } from '@gamepark/dunaia/moves/GainMemoryChips';

type MemoryChipChooserProps = {
  player: Player;
};

const MemoryChipChooser: FC<MemoryChipChooserProps> = (props) => {
  const { player } = props;
  const [selectedChips, setSelectedChips] = useState<number[]>([]);
  const playerId = usePlayerId();
  const play = usePlay();
  const canChoose = player.color === playerId;
  const memoryChipEffect = canChoose ? getMemoryChipEffect(player) : undefined;
  const animation = useAnimations();

  useEffect(() => {
    if (selectedChips.length && (!canChoose || !memoryChipEffect)) {
      setSelectedChips([]);
    }
  }, [selectedChips, memoryChipEffect, canChoose]);

  if (!canChoose || !memoryChipEffect || memoryChipEffect.count === 0 || !!animation?.length) {
    return null;
  }

  const remainingChips = (memoryChipEffect?.count || 1) - selectedChips.length;
  const selectChip = (memoryChip: number) => {
    if (!selectedChips.includes(memoryChip) && (isDisabled(memoryChip) || !remainingChips)) {
      return;
    }

    setSelectedChips((selectedChips) => {
      if (selectedChips.includes(memoryChip)) {
        return selectedChips.filter((m) => m !== memoryChip);
      }

      return [...selectedChips, memoryChip].sort();
    });
  };

  const isDisabled = (m: number) => player.memoryChips.includes(m);

  const gainMemoryChips = () => {
    if (remainingChips) {
      return;
    }

    setSelectedChips([]);
    play(gainMemoryChipsMove(selectedChips));
  };

  return (
    <>
      <div css={overlay} />
      {[1, 2, 3, 4, 5, 6].map((m) => (
        <MemoryChip
          key={m}
          memoryChip={m}
          css={[memoryChipsStyle(m), selectedChips.includes(m) && selected, isDisabled(m) && disabledChip]}
          onClick={() => selectChip(m)}
        />
      ))}
      <DunaiaButton
        labelKey={`button.get-memory-chips`}
        css={validateButton}
        highlighted={!!selectedChips.length}
        disabled={!!remainingChips}
        onClick={gainMemoryChips}
      />
    </>
  );
};

const overlay = css`
  position: absolute;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: rgba(0, 0, 0, 0.77);
  cursor: pointer;
  transform: translateZ(50em);
  
`;

const disabledChip = css`
  opacity: 0.8;
  filter: grayscale(1);
  cursor: not-allowed;
  &:hover {
    filter: grayscale(1);
  }
`;

const memoryChipChoiceHeight = memoryChipHeight * 2;
const memoryChipChoiceWidth = memoryChipWidth * 2;
const memoryChipsStyle = (memoryChip: number) => css`
  position: absolute;
  top: 25%;
  left: ${33.5 + memoryChip * (memoryChipChoiceWidth + 4)}em;
  transform: rotateZ(90deg) translateZ(50em);
  height: ${memoryChipChoiceHeight}em;
  width: ${memoryChipChoiceWidth}em;
  cursor: pointer;
  filter: brightness(50%);
`;

const selected = css`
  filter: brightness(100%);
`;

const validateButton = css`
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translateX(-50%) translateZ(50em) ;

  > span {
    font-size: 3em;
  }
`;

export { MemoryChipChooser };
