import GameView from '@gamepark/dunaia/GameView';

export type SelectDunaia = {
  type: 'SelectDunaia';
  dunaia?: number;
};

export const selectDunaiaMove = (dunaia?: number): SelectDunaia => ({
  type: 'SelectDunaia',
  dunaia,
});

export function selectDunaia(state: GameView, move: SelectDunaia) {
  state.selectedDunaia = move.dunaia;
}
