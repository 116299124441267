import { Player } from '../player/Player';
import { Position } from '../common/Position';
import { PlayerBuilding } from '../player/PlayerBuilding';
import { Building } from '../material/building/Building';
import { GameEffects, GameEffectType } from '../effect/GameEffect';
import { ScoringBuildingEffect, StandardBuildingEffect } from '../material/building/BuildingEffect';
import { BuildingEffectType } from '../material/building/BuildingEffectType';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import { GainMemoryChipsEffect } from '../effect/GainMemoryChipsEffect';
import { PlayerDice } from '../player/PlayerDice';
import { BoardBuildingPrices } from '../material/board/BoardBuildingPrices';
import Color from '../color/Color';
import { getEffectOfType } from './pending-effect.utils';
import { BuyBuildingEffect } from '../effect/BuyBuildingEffect';
import { Buildings } from '../material/building/Buildings';
import sum from 'lodash/sum';
import { sumBy } from 'lodash';

/**
 * Indicate if an effect is a Dunaia awakening effect
 * @param effect The given effect
 */
export const isStandardBuildingEffect = (
  effect: StandardBuildingEffect | ScoringBuildingEffect
): effect is StandardBuildingEffect => effect.type === BuildingEffectType.DunaiaAwakening;

/**
 * Get the player building on the position
 * @param player THe player
 * @param position The building position
 * @return The player building
 */
export const getBuildingForPosition = (player: Player, position: Position): PlayerBuilding | undefined => {
  return player.buildings[position.x][position.y];
};

/**
 * Compute the effect of a player building
 * @param player The player of the building
 * @param position The building position
 * @param playerBuilding The player building
 * @param buildings The building referential
 */
export const getBuildingEffects = (
  player: Player,
  position: Position,
  playerBuilding: PlayerBuilding,
  buildings: Building[]
): GameEffects[] => {
  const building = buildings[playerBuilding.building];

  const effects: GameEffects[] = [];
  const mainEffect = building.mainEffect;
  const adjacentEffects = building.adjacentEffects;

  if (mainEffect && mainEffect.isActive(player.buildings, playerBuilding, position)) {
    effects.push({ ...mainEffect.effect, building: playerBuilding.building });
  }

  const dunaiaAwakeningEffects: StandardBuildingEffect[] = (adjacentEffects || [])
    .filter((e) => isStandardBuildingEffect(e))
    .map((e) => e as StandardBuildingEffect)
    .filter((e) => e.isActive?.(player.buildings, playerBuilding, position));

  effects.push(...dunaiaAwakeningEffects.map((e) => ({ ...e.effect, building: playerBuilding.building })));

  return combineEffects(effects);
};

/**
 * Get the default effect from a position
 * @param playerDice The played dice
 * @param position The current position
 * @param defaultEffects The list of default effects
 */
export const getDefaultEffects = (
  playerDice: PlayerDice,
  position: Position,
  defaultEffects: GameEffects[][]
): GameEffects[] => {
  const effect: GameEffects = defaultEffects[position.x][position.y];
  if (effect.type === GameEffectType.GainMemoryChips && !effect.memoryChip && !effect.count) {
    return [new GainMemoryChipsEffect(true, undefined, playerDice.dice.value)];
  }

  return [effect];
};

/**
 * Combine all possible effects. For example, multiple same effects for the same building (4 memory chips for example)
 * @param effects The non combined effects
 * @return combinedEffect The incoming effect combined
 */
export const combineEffects = (effects: GameEffects[]): GameEffects[] => {
  const combined: Record<string, GameEffects[]> = groupBy(
    effects,
    (e) => `${e.type}-${e.building !== undefined ? e.building : ''}`
  );

  return values(combined).map((p: GameEffects[]) => {
    const type = p[0].type;
    switch (type) {
      case GameEffectType.GainMetalFlower:
        return ({ ...p[0], metalFlowers: sumBy(p, (p: any) => p.metalFlowers)});
      case GameEffectType.GainMemoryChips:
        return ({ ...p[0], })
      default:
        return ({ ...p[0], count: p.length });
    }
  });
};

/**
 * Get the building effects or defaults if there is no building
 * @param player The player of the building
 * @param position The building position
 * @param buildings The building referential
 * @param defaultEffects The list of default board area effects
 * @param playerBuilding The player building
 */
export const getBuildingEffectsOrDefaults = (
  player: Player,
  position: Position,
  buildings: Building[],
  defaultEffects: GameEffects[][],
  playerBuilding?: PlayerBuilding
): GameEffects[] => {
  if (playerBuilding) {
    return getBuildingEffects(player, position, playerBuilding, buildings);
  }

  const lastDice = player.dice[player.dice.length - 1];

  if (lastDice) {
    return getDefaultEffects(lastDice, position, defaultEffects);
  }

  return [];
};

/**
 * Sanitize effect that can't be played by the player
 * @param effects The list of effects
 * @param player The player
 */
export const sanitizeEffects = (effects: GameEffects[], player: Player): GameEffects[] => {
  const sanized: GameEffects[] = [];
  for (let effect of effects) {
    switch (effect.type) {
      case GameEffectType.MoveConstructionToken:
        if (player.constructionTokens < 3) {
          sanized.push(effect);
        }
        break;
      case GameEffectType.GainMemoryChips:
        const memoryChipsEffect = effect as GainMemoryChipsEffect;
        // If there is another memory chips gain for the same value, don't add it
        // If player has all memory chips, don't add it
        if (
          player.memoryChips.length < 6 &&
          (!memoryChipsEffect.memoryChip ||
            (!player.memoryChips.includes(memoryChipsEffect.memoryChip) &&
              !sanized.some(
                (s) =>
                  s.type === GameEffectType.GainMemoryChips &&
                  s.memoryChip &&
                  s.memoryChip === memoryChipsEffect.memoryChip
              )))
        ) {
          const maximumChips = 6 - player.memoryChips.length;
          if ((effect.count ?? 1) <= maximumChips) {
            sanized.push(effect);
          } else {
            sanized.push({ ...effect, count: maximumChips})
          }
        }
        break;
      default:
        sanized.push(effect);
        break;
    }
  }

  return sanized;
};

/**
 * Indicate if the building needs dice to be activated
 * @param building The building to check
 * @param buildings THe buildings referential
 */
export const isActivableByDice = (building: number, buildings: Building[]) => {
  return buildings[building].level === 1;
};

/**
 * Does the building has a dice ?
 * @param building Player building
 * @param players The list of players
 */
export const hasDice = (building: number, players: Array<Player>) => {
  return players.some((p) => p.dice.some((d) => d.building === building));
};

/**
 * Compute possible combination depending on the array and the size of the combination
 * @param array The list of element to pick
 * @param size number of element to pick
 */
export const getCombinations = (array: any[], size: number): any[] => {
  let i, j, combs, head, tailcombs;
  if (size > array.length || size <= 0) {
    return [];
  }
  if (size == array.length) {
    return [array];
  }
  if (size == 1) {
    combs = [];
    for (i = 0; i < array.length; i++) {
      combs.push([array[i]]);
    }
    return combs;
  }
  combs = [];
  for (i = 0; i < array.length - size + 1; i++) {
    head = array.slice(i, i + 1);
    tailcombs = getCombinations(array.slice(i + 1), size - 1);
    for (j = 0; j < tailcombs.length; j++) {
      combs.push(head.concat(tailcombs[j]));
    }
  }
  return combs;
};

/**
 * Get the list of player with recycling effect available
 * @param players All players
 * @param buildings The buildings
 */
export const getRecyclingBuildings = (players: Player[], buildings: Building[]): number[] =>
  players
    .flatMap((p) => p.buildings.flat())
    .filter((b) => b && buildings[b.building].recyclingEffect?.isActive(players, b))
    .map((b) => b!.building);

/**
 * Get the list of buyable buildings
 * @param metalFlowers The number of metal flowers
 * @param buildings THe building list
 * @param any can player any building (event those non visible)
 */
export const getBuyableBuildings = (metalFlowers: number, buildings: number[][][], any: boolean = false): number[] => {
  return buildings.flatMap((col, x) => {
    return (
      col
        .filter((_, y) => metalFlowers >= BoardBuildingPrices[x][y])
        // Only the first building of the pile can be bought if its by the board action
        .flatMap((buildings: number[]) => (any ? buildings : buildings[0] !== undefined ? [buildings[0]] : []))
        .flat()
    );
  });
};

/**
 * Is a building built
 * @param building player building
 */
export const isBuiltBuilding = (building: PlayerBuilding | undefined | null): building is PlayerBuilding =>
  building !== null && building !== undefined && !building.constructionToken;

/**
 * Is a building building
 * @param building player building
 */
export const isBuildingBuilding = (building: PlayerBuilding | undefined | null) =>
  building !== null && building !== undefined && !!building.constructionToken;

/**
 * Get built buildings for the player
 * @param player The player
 */
export const getBuiltBuildings = (player: Player): PlayerBuilding[] => player.buildings.flat().filter(isBuiltBuilding);

/**
 * Get constructing buildings
 * @param player The player
 */
export const getBuildingBuildings = (player: Player) => player.buildings.flat().filter(isBuildingBuilding);

/**
 * Does building has a specified color
 * @param building the player building
 * @param color the asked color
 * @param buildings All game buildings
 */
export const isBuildingOfColor = (building: PlayerBuilding | undefined, buildings: Building[], color: Color) => {
  if (building === undefined || building === null) {
    return false;
  }

  const theBuilding = buildings[building.building];
  return theBuilding.color === undefined || [theBuilding.color, ...building.additionalColors].includes(color);
};

/**
 * Can the player buy a building
 * @param player The active player
 */
export const canBuyBuilding = (player: Player) => !!getEffectOfType(player.pending, GameEffectType.BuyBuilding);

/**
 * Can the player buy one building only
 * @param player
 */
export const canBuyOneBuilding = (player: Player) => {
  const effect = getEffectOfType(player.pending, GameEffectType.BuyBuilding) ;
  return effect && !(effect as BuyBuildingEffect).any;
};

/**
 * Can buy any building
 */
export const canBuyAnyBuilding = (player: Player): boolean => {
  const effect = getEffectOfType(player.pending, GameEffectType.BuyBuilding);
  return !!effect && !!(effect as BuyBuildingEffect).any;
}

/**
 * Can the player move construction token
 * @param player The active player
 * @param playerBuilding The actual player building
 */
export const canMoveConstructionToken = (player: Player, playerBuilding: PlayerBuilding): boolean =>
  !!getEffectOfType(player.pending, GameEffectType.MoveConstructionToken) && !!playerBuilding.constructionToken;


/**
 * Can the player place color token
 * @param player The active player
 * @param playerBuilding The actual player building
 * @param buildings The list of building
 */
export const canPlaceColorToken = (player: Player, playerBuilding: PlayerBuilding, buildings: Building[]): boolean => {
  return !!getEffectOfType(player.pending, GameEffectType.PlaceColorToken) && canHaveNewColor(playerBuilding, buildings);
};

/**
 * Building can have a new color
 * @param playerBuilding The player building
 * @param buildings The list of building
 */
export const canHaveNewColor = (playerBuilding: PlayerBuilding, buildings: Building[]): boolean => !!buildings[playerBuilding.building].color && playerBuilding.additionalColors?.length < 3

/**
 * Does a building has a specified color
 * @param playerBuilding The player building
 * @param buildings The list of building
 * @param color The color to check
 */
export const hasColor = (playerBuilding: PlayerBuilding, buildings: Building[], color: Color) => {
  const baseColor = buildings[playerBuilding.building].color;
  const colors = baseColor? [baseColor, ...playerBuilding.additionalColors]: playerBuilding.additionalColors;
  return colors.includes(color);
}

/**
 * Get the price of a building
 * @param selectedBuilding The searched building
 * @param buildings The list of buildings
 */
export const getPrice = (selectedBuilding: number, buildings: number[][][]) => {
  let price = undefined;
  buildings.forEach((line, x) =>
    line.forEach((b, y) => {
      if (b.some((building) => building === selectedBuilding)) {
        price = BoardBuildingPrices[x][y];
      }
    })
  );
  return price;
};

/**
 * Get the column of a building
 * @param buildings main buildings
 * @param building the searched building
 */
export const getBuildingColumn = (buildings: number[][][], building: number) => {
  const column = buildings.findIndex((buildings) => buildings.flat().some((b) => b === building));

  if (column === undefined) {
    throw Error('Impossible to find building column in main board');
  }

  return column;
};

/**
 * Get the position of a building on all players board
 * @param players All players
 * @param building The building
 */
export const getBuildingPositionAndPlayerColor = (
  players: Player[],
  building: number
): { position: Position; color: Color } | undefined => {
  for (const player of players) {
    const position = getBuildingPosition(player, building);
    if (position) {
      return { position, color: player.color };
    }
  }

  return;
};

/**
 * Get the position of a building on a player board
 * @param player Player
 * @param building The building
 */
export const getBuildingPosition = (player: Player, building: number): Position | undefined => {
  for (let x = 0; x < player.buildings.length; x++) {
    const line = player.buildings[x];
    for (let y = 0; y < line.length; y++) {
      if (building === line[y]?.building) {
        return { x, y };
      }
    }
  }

  return undefined;
};

/**
 * Compute building score depending on the other built buildings on the board
 * @param buildings all player buildings
 * @param playerBuilding The building to compute score
 * @param position the player building position
 */
export const getBuildingScore = (buildings: (PlayerBuilding | undefined)[][], playerBuilding: PlayerBuilding, position: Position) => {
  const building = Buildings[playerBuilding.building];

  if (playerBuilding.constructionToken) {
    return 0;
  }

  const hasAdjacentEffects = building.adjacentEffects && building.adjacentEffects.length
  const effectsStatus = hasAdjacentEffects? building.adjacentEffects!.map((e) => e.isActive(buildings, playerBuilding, position)): [];

  return effectsStatus.map((e, index) => !e? 0: (building.adjacentEffects![index] as ScoringBuildingEffect)?.score || 0).reduce((a, b) => a + b, building.level);

}

/**
 * Compute score for all player buildings
 * @param buildings All player buildings
 */
export const getBuildingsScore = (buildings: (PlayerBuilding | undefined)[][]) => {
  return sum(buildings.flatMap(
    (col, x) =>
      col
        .filter((b) => !!b)
        .map((building, y) => getBuildingScore(buildings, building!, {x, y}))

  ))
}
