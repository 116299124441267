/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { DunaiaFace } from '../material/dunaia/DunaiaFace';
import { HelpModal } from './HelpModal';

type DunaiaHelpModalProps = {
  onClose: () => void;
  color: number;
  dunaia: number;
  button?: any;
}

const DunaiaHelpModal: FC<DunaiaHelpModalProps> = (props) => {
  const { dunaia, color, button, onClose } = props;
  const { t } = useTranslation();

  const modal = (
    <HelpModal onClose={onClose} title={t('help.dunaia.title', { dunaia: dunaia + 1 })}>
      <div css={description}>
        <div>
          <DunaiaFace color={color} dunaia={dunaia} css={dunaiaToken} scale={2} />
        </div>
        <span>{t('help.dunaia.explanation', { dunaia: dunaia + 1 })}</span>
      </div>
      { button }
    </HelpModal>

  );

  let letterBox = document.getElementById('letterbox')!;
  return createPortal(modal, letterBox);
};

const description = css`
  display: flex;
  flex-direction: row;

  > span {
    flex: 2;
    font-size: 3em;
    text-align: left;
  }

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const dunaiaToken = css`
  transform: none;
  position: unset;
`;

export {
  DunaiaHelpModal
};
