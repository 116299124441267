import {Action, Competitive, Rules, TimeLimit, Undo} from '@gamepark/rules-api'
import GameState from './GameState'
import Move from './moves/Move'
import {DunaiaOptions, isGameOptions} from './DunaiaOptions'
import Color from './color/Color'
import {GameInitializer} from './initializer/GameInitializer'
import MoveType from './moves/MoveType'
import {chooseDice} from './moves/ChooseDice'
import {moveDunaia} from './moves/MoveDunaia'
import {recycleDice} from './moves/RecycleDice'
import {retrieveProphecy} from './moves/RetrieveProphecy'
import {gainMetalFlowers} from './moves/GainMetalFlowers'
import {gainMemoryChips} from './moves/GainMemoryChips'
import {buyBuilding} from './moves/BuyBuilding'
import {moveConstructionToken} from './moves/MoveConstructionToken'
import {placeColorToken} from './moves/PlaceColorToken'
import {callElder} from './moves/CallElder'
import {gainArtefactPart} from './moves/GainArtefactPart'
import {getPredictableAutomaticMoves} from './utils/automatic-moves.utils'
import {LegalMovesUtils} from './utils/legal-moves.utils'
import {ElderPowers} from './material/power/ElderPowers'
import {Buildings} from './material/building/Buildings'
import {rollDice, rollDiceMove} from './moves/RollDice'
import {rollGameDices} from './utils/dice.utils'
import {isPlayerWinProphecy} from './utils/prophecy.utils'
import {Prophecies} from './material/prophecy/Prophecies'
import {endTurn} from './moves/EndTurn'
import {getPlayerScore, isGameOver} from './utils/is-over.utils'
import {BoardActions} from './material/board/BoardActions'
import {undoUtils} from './utils/undo.utils'
import {awakeDunaia} from './moves/AwakeDunaia'
import {DefaultBoardEffects} from './default/DefaultBoardEffects'

/**
 * Your Board Game rules must extend either "SequentialGame" or "SimultaneousGame".
 * When there is at least on situation during the game where multiple players can act at the same time, it is a "SimultaneousGame"
 * If the game contains information that players does not know (dices, hidden cards...), it must implement "IncompleteInformation".
 * If the game contains information that some players know, but the other players does not, it must implement "SecretInformation" instead.
 * Later on, you can also implement "Competitive", "Undo", "TimeLimit" and "Eliminations" to add further features to the game.
 */
export default class Dunaia extends Rules<GameState, Move, Color>
  implements Undo<GameState, Move, Color>,
    Competitive<GameState, Move, Color>,
    TimeLimit<GameState, Move, Color> {
  /**
   * This constructor is called when the game "restarts" from a previously saved state.
   * @param state The state of the game
   */
  constructor(state: GameState);
  /**
   * This constructor is called when a new game is created. If your game has options, or a variable number of players, it will be provided here.
   * @param options The options of the new game
   */
  constructor(options: DunaiaOptions);
  /**
   * In here you must code the construction of your class. Use a "typeguard" to distinguish a new game from a restored game.
   * @param arg The state of the game, or the options when starting a new game
   */
  constructor(arg: GameState | DunaiaOptions) {
    if (isGameOptions(arg)) {
      super(new GameInitializer(arg).buildState())
    } else {
      super(arg)
    }
  }

  rankPlayers(playerA: Color, playerB: Color): number {
    const a = this.state.players.find((p) => p.color === playerA)!
    const b = this.state.players.find((p) => p.color === playerB)!
    const scoreA = getPlayerScore(a), scoreB = getPlayerScore(b)
    if (scoreA !== scoreB) {
      return scoreB - scoreA
    }

    const propheciesA = a.prophecies.length, propheciesB = b.prophecies.length
    if (propheciesA !== propheciesB) {
      return propheciesB - propheciesA
    }

    return b.metalFlowers - a.metalFlowers
  }

  getScore(playerId: Color): number {
    return getPlayerScore(this.state.players.find((p) => p.color === playerId)!)
  }

  canUndo(action: Action<Move, Color>): boolean {
    return undoUtils(action, this.state.activePlayer)
  }

  /**
   * @return True when game is over
   */
  isOver(): boolean {
    return isGameOver(this.state)
  }

  /**
   * Retrieves the player which must act. It is used to secure the game and prevent players from acting outside their turns.
   * Only required in a SequentialGame.
   * @return The identifier of the player whose turn it is
   */
  getActivePlayer(): Color | undefined {
    return this.state.activePlayer // You must return undefined only when game is over, otherwise the game will be blocked.
  }

  /**
   * Return the exhaustive list of moves that can be played by the active player.
   * This is used for 2 features:
   * - security (preventing unauthorized moves from being played);
   * - "Dummy players": when a player leaves a game, it is replaced by a "Dummy" that plays random moves, allowing the other players to finish the game.
   * In a SimultaneousGame, as multiple players can be active you will be passed a playedId as an argument.
   * If the game allows a very large (or infinite) number of moves, instead of implementing this method, you can implement instead:
   * - isLegal(move: Move):boolean, for security; and
   * - A class that implements "Dummy" to provide a custom Dummy player.
   */
  getLegalMoves(playerId: Color): Move[] {
    if (playerId !== this.getActivePlayer()) return []
    return new LegalMovesUtils(this.state, ElderPowers, Buildings).build()
  }

  /**
   * This is the one and only play where you will update the game's state, depending on the move that has been played.
   *
   * @param move The move that should be applied to current state.
   */
  play(move: Move): Move[] {
    switch (move.type) {
      case MoveType.ChooseDice:
        chooseDice(this.state, move)
        break
      case MoveType.MoveDunaia:
        moveDunaia(this.state, move)
        break
      case MoveType.RecycleDice:
        recycleDice(this.state, move, BoardActions, Buildings)
        break
      case MoveType.RetrieveProphecy:
        retrieveProphecy(this.state, move)
        break
      case MoveType.GainMetalFlowers:
        gainMetalFlowers(this.state, move)
        break
      case MoveType.GainMemoryChips:
        gainMemoryChips(this.state, move)
        break
      case MoveType.BuyBuilding:
        buyBuilding(this.state, move)
        break
      case MoveType.MoveConstructionToken:
        moveConstructionToken(this.state, move, Buildings)
        break
      case MoveType.PlaceColorToken:
        placeColorToken(this.state, move)
        break
      case MoveType.CallElder:
        callElder(this.state, move)
        break
      case MoveType.GainArtefactPart:
        gainArtefactPart(this.state, move)
        break
      case MoveType.RollDice:
        rollDice(this.state, move)
        break
      case MoveType.EndTurn:
        endTurn(this.state)
        break
      case MoveType.AwakeDunaia:
        awakeDunaia(this.state, move, Buildings, DefaultBoardEffects)
        break
    }
    return []
  }

  /**
   * @return The next automatic consequence that should be played in current game state.
   */
  getAutomaticMoves(): Move[] {
    if (isGameOver(this.state)) {
      return []
    }

    const activePlayer = this.state.players.find((p) => p.color === this.state.activePlayer)!

    const wonProphecies = this.state.prophecies.filter((p) =>
      isPlayerWinProphecy(activePlayer, Prophecies[p], Buildings)
    )

    if (!wonProphecies.length && activePlayer.end && !this.state.dice.length) {
      return [rollDiceMove(rollGameDices(this.state.players.map((p) => p.color)))]
    }

    return getPredictableAutomaticMoves(this.state, Prophecies, Buildings)
  }

  giveTime(): number {
    return 90
  }
}
