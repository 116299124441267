import { GameInitializer } from '@gamepark/dunaia/initializer/GameInitializer';
import Color from '@gamepark/dunaia/color/Color';
import { BlackDice } from '@gamepark/dunaia/common/DiceColor';
import { Buildings, Level1YellowBuilding4, Level2RedBuilding1 } from '@gamepark/dunaia/material/building/Buildings';
import { ElderPowers, MoveConstructionToken } from '@gamepark/dunaia/material/power/ElderPowers';
import { Prophecies, Prophecy1, Prophecy4, Prophecy9 } from '@gamepark/dunaia/material/prophecy/Prophecies';

class TutorialInitializer extends GameInitializer {

  drawDice() {
    return [
      { color: Color.Green, value: 1 },
      { color: BlackDice.Black, value: 2 },
      { color: BlackDice.Black, value: 2 },
      { color: BlackDice.Black, value: 3 },
      { color: BlackDice.Black, value: 3 },
      { color: BlackDice.Black, value: 6 },
      { color: Color.Red, value: 6 },
    ]
  };

  drawProphecies(): number[] {
    return [
      Prophecies.indexOf(Prophecy1),
      Prophecies.indexOf(Prophecy4),
      Prophecies.indexOf(Prophecy9),
    ]
  }

  drawElderPowers(): any {
    const constructionTokenElderPower = ElderPowers.indexOf(MoveConstructionToken)
    const elderPowers = super.drawElderPowers().filter((e) => e !== constructionTokenElderPower)  ;
    return [
      elderPowers[0],
      constructionTokenElderPower,
      elderPowers[1]
    ]
  }

  shuffleBuildings() {
    const shuffled = super.shuffleBuildings();
    const [level1Buildings, level2Buildings, level3Buildings] = shuffled;

    const yellowBuildingIndex = Buildings.indexOf(Level1YellowBuilding4);
    const redBuildingIndex = Buildings.indexOf(Level2RedBuilding1)
    return [
      [level1Buildings.find((b) => b === yellowBuildingIndex)!, ...level1Buildings.filter((b) => b !== yellowBuildingIndex)],
      [level2Buildings.find((b) => b === redBuildingIndex)!, ...level2Buildings.filter((b) => b !== redBuildingIndex)],
      level3Buildings,
    ]
  }
}

export {
  TutorialInitializer
};
