import { Player } from '../player/Player';
import Color from '../color/Color';
import { getNonRecycledDie } from './dice.utils';
import { Phase } from '../common/Phase';

/**
 * Is it possible to end turn for the player
 * @param player The player
 * @param activePlayer The active player
 */
export const canEndTurn = (player: Player, activePlayer: Color): boolean => {
  return (
    player.color === activePlayer &&
    player.phase === Phase.RecyclingDie &&
    !getNonRecycledDie(player.dice) &&
    !player.pending.length
  );
};
