/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player } from '@gamepark/dunaia/player/Player';
import { PlayerBuilding } from '@gamepark/dunaia/player/PlayerBuilding';
import { Position } from '@gamepark/dunaia/common/Position';
import { FC } from 'react';
import { buildingHeight, buildingWidth } from '../../styles/Style';
import { Buildings } from '@gamepark/dunaia/material/building/Buildings';
import { getBuildingScore } from '@gamepark/dunaia/utils/building.utils';

export type PlayerBuildingScoreOverlayProps = {
  player: Player;
  playerBuilding: PlayerBuilding;
  position: Position;
  scale?: number;
}

const PlayerBuildingScoreOverlay: FC<PlayerBuildingScoreOverlayProps> = (props) => {
  const { player, playerBuilding, position, scale } = props;
  const building = Buildings[playerBuilding.building];

  const hasAdjacentEffects = building.adjacentEffects && building.adjacentEffects.length
  const hasEffectScore = building.level === 3 && building.color !== undefined && hasAdjacentEffects;
  const effectsStatus = hasAdjacentEffects? building.adjacentEffects!.map((e) => e.isActive(player.buildings, playerBuilding, position)): [];

  let score = getBuildingScore(player.buildings, playerBuilding, position);


  return (
    <div css={style(scale)}>
      <div css={levelScoring(scale)} />
      {hasAdjacentEffects && hasEffectScore && (
        <>
          <div css={leftEffectScoring(scale, effectsStatus[0])} />
          <div css={topEffectScoring(scale, effectsStatus[1])} />
          <div css={rightEffectScoring(scale, effectsStatus[2])} />
          <div css={bottomEffectScoring(scale, effectsStatus[3])} />
        </>
      )}
      <div css={totalScoring(scale)}><span>{score || 0}</span></div>
    </div>
  )
}

const style = (scale: number = 1) => css`
  pointer-events: none;
  height: ${buildingHeight * scale}em;
  width: ${buildingWidth * scale}em;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1em;
  transform-style: preserve-3d;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
`;

const levelScoring = (scale: number = 1) => css`
  position: absolute;
  width: ${3.5 * scale}em;
  height: ${3.5 * scale}em;
  left: ${1.9 * scale}em;
  top: ${1.5 * scale}em;
  border-radius: ${scale}em;
  ${succeed(scale)}

  > svg {
    transform: translateX(2.2em);
    color: green;
  }
`

const leftEffectScoring = (scale: number = 1, success: boolean) => css`
  position: absolute;
  width: ${3.5 * scale}em;
  height: ${2.5 * scale}em;
  left: ${1.2 * scale}em;
  top: ${5.75 * scale}em;
  border-radius: ${scale}em;
  ${success? succeed(scale): undefined}

  > svg {
    transform: translateX(2.2em);
    color: green;
  }
`
const topEffectScoring = (scale: number = 1, success: boolean) => css`
  position: absolute;
  width: ${2.5 * scale}em;
  height: ${3.35 * scale}em;
  left: ${9.1 * scale}em;
  top: ${1.25 * scale}em;
  border-radius: ${scale}em;
  ${success? succeed(scale): undefined}

  > svg {
    transform: translateX(2.2em);
    color: green;
  }
`
const bottomEffectScoring = (scale: number = 1, success: boolean) => css`
  position: absolute;
  width: ${2.5 * scale}em;
  height: ${3.35 * scale}em;
  left: ${9.1 * scale}em;
  bottom: ${1.1 * scale}em;
  border-radius: ${scale}em;
  ${success? succeed(scale): undefined}

  > svg {
    transform: translateX(2.2em);
    color: green;
  }
`

const rightEffectScoring = (scale: number = 1, success: boolean) => css`
  position: absolute;
  width: ${3.5 * scale}em;
  height: ${2.5 * scale}em;
  right: ${1.1 * scale}em;
  top: ${5.75 * scale}em;
  border-radius: ${scale}em;
  ${success? succeed(scale): undefined}

  > svg {
    transform: translateX(2.2em);
    color: green;
  }
`

const totalScoring = (scale: number = 1) => css`
  position: absolute; 
  width: ${2.5 * scale}em; 
  height: ${2.5 * scale}em; 
  border-radius: 50%; 
  display: flex;
  justify-content: center; 
  align-items: center;
  right: ${scale}em;
  bottom: ${scale}em;
  border: ${0.2 * scale}em solid black;
  background-color: gold;
  > span { 
    font-size: ${1.2 * scale}em;
    color: black;
    font-weight: bold;
    text-shadow: 0 0 ${0.1 * scale}em white, 0 0 ${0.1 * scale}em white, 0 0 ${0.1 * scale}em white, 0 0 ${0.1 * scale}em white;
  }
`

const succeed = (scale: number = 1) => css`
  border: ${0.2 * scale}em solid white;
`

export {
  PlayerBuildingScoreOverlay
}
