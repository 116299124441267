import MoveType from './MoveType';
import GameState from '../GameState';

export type RetrieveProphecy = {
  type: MoveType.RetrieveProphecy;
  prophecy: number;
};

export const retrieveProphecyMove = (prophecy: number): RetrieveProphecy => ({
  type: MoveType.RetrieveProphecy,
  prophecy,
});

export const retrieveProphecy = (state: GameState, move: RetrieveProphecy) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  player.prophecies.push(move.prophecy);
  state.prophecies = state.prophecies.filter((p) => p !== move.prophecy);

  if (!state.prophecies.length) {
    state.last = state.round + 1;
  }
};
