/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ElderPowers } from '@gamepark/dunaia/material/power/ElderPowers';
import { ElderPowerMemoryChips } from '@gamepark/dunaia/material/board/ElderPowerMemoryChips';

type ElderPowerHelpContentProps = {
  elderPower: number;
  elderPowerIndex: number;
  onClose: () => void;
}

const ElderPowerHelpContent: FC<ElderPowerHelpContentProps> = (props) => {
  const { t } = useTranslation();
        const { elderPower: elderPowerNumber, elderPowerIndex } = props;
  const baseMemoryChips = ElderPowerMemoryChips[elderPowerIndex];
  const power = ElderPowers[elderPowerNumber];
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => setVisible(true), 100);
    }
  }, [visible])

  return (
    <>
    <div css={[helpContainer, !visible && hidden]}>
      <div css={explanation}><span css={text}>{t('elder-power.help.explanation')}</span></div>
      <div css={powerLine}></div>
      <div css={powerText}><span css={text}>{t('elder-power.help.power')}</span></div>
      { (power.memoryChips || 0) > 0 && (
        <>
          <div css={leftChipLine}></div>
          <div css={leftChipText}><span css={text}>{t('elder-power.help.any-chip')}</span></div>
        </>
      )}
      { (power.memoryChips || 0) === 2 && (
        <>
          <div css={rightChipLine}></div>
          <div css={rightChipText}><span css={text}>{t('elder-power.help.any-chip')}</span></div>
        </>
      )}
      <div css={leftBaseChipLine}></div>
      <div css={leftBaseChipText}><span css={text}>{t('elder-power.help.chip', { memoryChip: baseMemoryChips[0]})}</span></div>
      <div css={rightBaseChipLine}></div>
      <div css={rightBaseChipText}><span css={text}>{t('elder-power.help.chip', { memoryChip: baseMemoryChips[1]})}</span></div>
    </div>
    </>
  )
}

const powerLine = css`
  transform: rotateZ(-45deg);
  position: absolute;
  top: 28.8em;
  left: 75.3em;
  width: 12em;
  height: 0.3em;
  background-color: black;
`

const powerText = css`
  text-align: center;
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  bottom: 54.8em;
  left: 84.2em;
  border-radius: 1em;
  background-color: white;
`

const leftChipLine = css`
  transform: rotateZ(0deg);
  position: absolute;
  top: 39.8em;
  left: 48.3em;
  width: 14em;
  height: 0.3em;
  background-color: black;
`

const leftChipText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 37em;
  right: 91em;
  max-width: 53em;
  border-radius: 1em;
  background-color: white;
`

const rightChipLine = css`  
  transform: rotateZ(0deg);
  position: absolute;
  top: 39.8em;
  left: 85.3em;
  width: 14em;
  height: 0.3em;
  background-color: black;
`

const rightChipText = css`
  text-align: center;
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 37em;
  left: 91em;
  max-width: 53em;
  border-radius: 1em;
  background-color: white;
`

const leftBaseChipLine = css`
  transform: rotateZ(-45deg);
  position: absolute;
  top: 54.8em;
  left: 56.3em;
  width: 14em;
  height: 0.3em;
  background-color: black;
`

const leftBaseChipText = css`
  text-align: center;
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 58em;
  right: 89em;
  max-width: 47em;
  border-radius: 1em;
  background-color: white;
`

const rightBaseChipLine = css`
  transform: rotateZ(45deg);
  position: absolute;
  top: 54.8em;
  left: 77.3em;
  width: 14em;
  height: 0.3em;
  background-color: black;
`

const rightBaseChipText = css`
  text-align: center;
  text-align: center;
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 58em;
  left: 89em;
  max-width: 47em;
  border-radius: 1em;
  background-color: white;
`

const explanation = css`
  position: absolute;
  transform: translateX(-50%);
  padding: 0.5em 1em;
  top: 7em;
  left: 50%;
  width: 85%;
  text-align: center;
`

const text = css`
  color: black;
  font-size: 3em;
`

const hidden = css`
  opacity: 0;
`;

const helpContainer = css`
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(50em);
  height: 80em;
  width: 80%;
`;

export {
  ElderPowerHelpContent
}
