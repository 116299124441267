import Move from '../../moves/Move';
import { Player } from '../../player/Player';
import { moveConstructionTokenMove } from '../../moves/MoveConstructionToken';
import GameState from '../../GameState';
import { getBuildingBuildings } from '../../utils/building.utils';
import { GameEffectRules } from './GameEffectRules';
import { MoveConstructionTokenEffect } from '../MoveConstructionTokenEffect';

class MoveConstructionTokenEffectRules extends GameEffectRules<MoveConstructionTokenEffect> {
  constructor(effect: MoveConstructionTokenEffect) {
    super(effect);
  }

  legalMoves(_state: GameState, player: Player): Move[] {
    if (this.effect.forBuilding !== undefined) {
      return [];
    }

    return getBuildingBuildings(player).map((b) => moveConstructionTokenMove(b!.building));
  }

  automaticMoves(): Move[] {
    if (this.effect.forBuilding === undefined) {
      return [];
    }

    return [moveConstructionTokenMove(this.effect.forBuilding)];
  }

  isIgnored(player: Player): boolean {
    return player.constructionTokens === 3;
  }
}

export { MoveConstructionTokenEffectRules };
