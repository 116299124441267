import Color from '../color/Color';
import { Dice } from '../common/Dice';
import { BlackDice } from '../common/DiceColor';
import { Player } from '../player/Player';
import { PlayerDice } from '../player/PlayerDice';
import { Phase } from '../common/Phase';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import sample from 'lodash/sample';

/**
 * Get the number of black dice depending on the player count
 * @param players The list of players
 */
export const getBlackDiceCount = (players: Color[]) => (players.length === 3 ? 4 : 5);

/**
 * Roll all dice and sort them
 * Sort: Per value and for the same value, first colored dice than black
 * @param players The players colors
 */
export const rollGameDices = (players: Color[]): Dice[] => {
  const blackDice: Dice[] = [...Array(getBlackDiceCount(players))].map((_) => ({
    color: BlackDice.Black,
    value: sample(d6)!,
  }));

  const coloredDice: Dice[] = players.map((p) => ({
    color: p,
    value: sample(d6)!,
  }));

  return [...blackDice, ...coloredDice].sort((a, b) => diceSorting(a, b, players));
};

/**
 * Sort dice depending on the value then the color
 * @param dice1 the first dice
 * @param dice2 the second dice
 * @param playerOrder The player color order
 */
export const diceSorting = (dice1: Dice, dice2: Dice, playerOrder: Color[]) => {
  const valueCompare = dice1.value - dice2.value;

  if (!valueCompare) {
    const dice1Color = dice1.color === BlackDice.Black ? dice1.color : playerOrder.findIndex((p) => p === dice1.color);
    const dice2Color = dice2.color === BlackDice.Black ? dice2.color : playerOrder.findIndex((p) => p === dice2.color);

    if (dice1Color === BlackDice.Black) {
      return -1;
    } else if (dice2Color === BlackDice.Black) {
      return 1;
    }

    return dice1Color - dice2Color;
  }

  return valueCompare;
};

/**
 * 6 faces dice
 */
export const d6 = [1, 2, 3, 4, 5, 6];

/**
 * Check the list of playable dices for the player
 * @param testedDice The tested dice
 * @param playerColor The player color
 * @param activePlayer The Active player
 * @return The list of dice that player can choose
 */
export const getPlayableDice = (testedDice: Dice[], playerColor: Color, activePlayer: Player): number[] => {
  if (activePlayer.color !== playerColor || activePlayer.phase !== Phase.ChoosingDie || activePlayer.pending.length) {
    return [];
  }

  const dice = Array.from(testedDice.entries());
  const blackDice = dice.filter((entry) => entry[1].color === BlackDice.Black);
  if (blackDice.length) {
    const blackDiceByKeys = mapValues(groupBy(blackDice, '[1].value'), (d) => d[0]);
    return values(blackDiceByKeys).map((d) => d[0]);
  } else {
    const playerDice = dice.filter((entry) => entry[1].color === playerColor);
    if (playerDice.length) {
      return playerDice.map((d) => d[0]);
    }
  }

  return [];
};

/**
 * Get the non recycled dice
 * @param dice The player dice to check
 */
export const getNonRecycledDie = (dice: PlayerDice[]): PlayerDice | undefined => {
  return dice.find((d) => d.building === undefined && d.boardAction === undefined);
};
