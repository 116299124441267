import MoveType from './MoveType';
import GameState from '../GameState';
import { Phase } from '../common/Phase';

export type EndTurn = {
  type: MoveType.EndTurn;
};

export const endTurnMove: EndTurn = {
  type: MoveType.EndTurn,
};

export const endTurn = (state: GameState) => {
  const actualPlayer = state.players.find((p) => p.color === state.activePlayer)!;
  actualPlayer.end = true;
  delete actualPlayer.phase;

  const lastColor = state.players[state.players.length - 1]?.color;
  if (state.last === state.round && actualPlayer.color === lastColor) {
    return;
  }

  const playerIndex = state.players.findIndex((p) => actualPlayer.color === p.color);

  if (state.dice.length) {
    if (actualPlayer.color === lastColor) {
      state.players.forEach((p) => { delete p.end })
      state.round++;
    }
    let nextPlayer;
    if (actualPlayer.color === lastColor) {
      nextPlayer = state.players[0];
    } else {
      nextPlayer = state.players[playerIndex + 1];
    }

    state.activePlayer = nextPlayer.color;
    nextPlayer.phase = Phase.ChoosingDie;
  }
};
