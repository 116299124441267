/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { FC, useMemo, useState } from 'react';
import { Die } from '../dice/Die';
import {
  diceProjection,
  diceSize,
  playerPanelDiceLeft,
  playerPanelDiceTop,
  recycleOnBoardActionTranslateX,
  recycleOnBoardActionTranslateY,
  recycleToBuildingTranslateX,
  recycleToBuildingTranslateY, translateToBuildingTranslateX, translateToBuildingTranslateY
} from '../../styles/Style';
import { Animation, useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { isRecycle, isRecycleOnBoard, isRecycleOnBuilding } from '@gamepark/dunaia/utils/is-move.utils';
import Color from '@gamepark/dunaia/color/Color';
import { RecycleDice } from '@gamepark/dunaia/moves/RecycleDice';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { Position } from '@gamepark/dunaia/common/Position';
import { getBuildingPositionAndPlayerColor } from '@gamepark/dunaia/utils/building.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import { playerPanelDice } from '../../draggable/PlayerPanelDice';
import Move from '@gamepark/dunaia/moves/Move';
import { Draggable } from '@gamepark/react-components';
import { Dice } from '@gamepark/dunaia/common/Dice';

export type PlayerPanelDiceProps = {
  activeColor: Color;
  displayedColor: Color;
  panelIndex: number;
  color: Color;
  die: Dice;
  diceRecycledOnBoard: PlayerDice[];
  players: Player[];
};

const PlayerPanelDice: FC<PlayerPanelDiceProps> = (props) => {
  const { die, activeColor, color, diceRecycledOnBoard, panelIndex, players, displayedColor } = props;
  const [isDragging, setDragging] = useState(false);
  const play = usePlay();
  const playerId = usePlayerId();
  const me = players?.find(p => p.color === playerId);
  const animation = useAnimation<RecycleDice>((animation) => {
    return isRecycle(animation.move) && activeColor === color;
  });
  const isRecycleOnBoardActionMove = animation && isRecycleOnBoard(animation.move);
  const targetBuildingPosition =
    animation && isRecycleOnBuilding(animation.move)
      ? getBuildingPositionAndPlayerColor(players, animation.move.building!)
      : undefined;
  const isRecycleOnBuildingMove = !!targetBuildingPosition && targetBuildingPosition.color === displayedColor;

  const recycledDiceByAction: Record<number, PlayerDice[]> = useMemo(
    () => groupBy(sortBy(diceRecycledOnBoard, 'recyclingOrder'), 'boardAction'),
    [diceRecycledOnBoard]
  );

  const onDrag = () => {
    setDragging(true);
    return playerPanelDice;
  };

  const onEndDrag = (_: any, monitor: any) => {
    if(!monitor.didDrop()) {
      setDragging(false);
    }
  };

  const onDrop = (move: Move) => {
    if (move) {
      play(move);
    }
  };

  const getAnimationToBoardAction = () => {
    if (!isRecycleOnBoardActionMove || !animation) {
      return;
    }

    if (isDragging) {
      return translateDiceToBoardAction(animation!, panelIndex,recycledDiceByAction[animation.move.boardAction!]?.length || 0);
    }

    return animateDiceToBoardAction(
      animation,
      panelIndex,
      recycledDiceByAction[animation.move.boardAction!]?.length || 0
    )
  }

  const getAnimationToBuilding = () => {
    if (!isRecycleOnBuildingMove || !animation) {
      return;
    }

    if (isDragging) {
      return translateDiceToBuilding(animation, targetBuildingPosition.position);
    }

    return animateDiceToBuilding(animation, panelIndex, targetBuildingPosition.position)
  }

  const translate = `translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em)`;
  return (
    <Draggable
      type={ playerPanelDice.type }
      projection={diceProjection}
      item={ onDrag }
      drop={ onDrop }
      end={ onEndDrag }
      canDrag={ playerId === color && !animation && !me?.pending?.length }
      preTransform={translate}
      css={ [
        diePosition,
        getAnimationToBoardAction(),
        getAnimationToBuilding()] }
    >
      <Die
        color={ die.color }
        value={ die.value }
      />
    </Draggable>
  );
};

const diePosition = css`
  position: absolute;
  transform-style: preserve-3d;
`;

const diceToBoardActionAnimation = (move: RecycleDice, panelIndex: number, positionOnBoardAction: number) => {
  const translateX = recycleOnBoardActionTranslateX(panelIndex, move.boardAction!, positionOnBoardAction);
  const translateY = recycleOnBoardActionTranslateY(panelIndex, positionOnBoardAction);
  return keyframes`
    10% {
      transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translateZ(${ diceSize + 1 }em);
    }
    90% {
      transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translateZ(${ diceSize + 1 }em) translate(${ translateX }em, ${ translateY }em);
    }
    to {
      transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translate(${ translateX }em, ${ translateY }em);

    }
  `;
};

const animateDiceToBoardAction = (
  animation: Animation<RecycleDice>,
  panelIndex: number,
  positionOnBoardAction: number
) => css`
  animation: ${ diceToBoardActionAnimation(animation.move, panelIndex, positionOnBoardAction) } ${ animation.duration }s linear both;
`;

const diceToBuildingAnimation = (position: Position, panelIndex: number) => {
  const translateX = recycleToBuildingTranslateX(position.x, panelIndex);
  const translateY = recycleToBuildingTranslateY(position.y, panelIndex);
  return keyframes`
    10% {
      transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translateZ(${ diceSize + 1 }em);
    }
    90% {
      transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translateZ(${ diceSize + 1 }em) translate(${ translateX }em, ${ translateY }em);
    }
    to {
      transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translate(${ translateX }em, ${ translateY }em);

    }
  `;
};

const animateDiceToBuilding = (animation: Animation<RecycleDice>, panelIndex: number, position?: Position) => {
  if (!position) {
    console.error('There is an error during animating dice to building, position is not defined');
    return;
  }

  return css`
    animation: ${ diceToBuildingAnimation(position!, panelIndex) } ${ animation.duration }s linear both;
  `;
};

const translateDiceToBoardAction = (animation: Animation<RecycleDice>, panelIndex: number, positionOnBoardAction: number) => {
  const translateX = recycleOnBoardActionTranslateX(panelIndex, animation.move.boardAction!, positionOnBoardAction);
  const translateY = recycleOnBoardActionTranslateY(panelIndex, positionOnBoardAction);

  return css`
    transition: transform ${animation.duration}s;
    transform: translate(${playerPanelDiceLeft}em,${playerPanelDiceTop}em) translate(${translateX}em, ${translateY}em);
  `;

}

const translateDiceToBuilding = (animation: Animation<RecycleDice>, position?: Position) => {
  if (!position) {
    console.error('There is an error during animating dice to building, position is not defined');
    return;
  }

  const translateX = translateToBuildingTranslateX(position.x);
  const translateY = translateToBuildingTranslateY(position.y);

  return css`
    transition: transform ${animation.duration}s;
    transform: translate(${translateX}em, ${translateY}em);
  `;

}

export { PlayerPanelDice };
