/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getMetalFlowerScoring } from '@gamepark/dunaia/utils/is-over.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import { MetalFlower } from '../../../metalflower/MetalFlower';
import { scoringMetalFlowerHeight, scoringMetalFlowerWidth } from '../../../../styles/Style';
import { MetalFlowerHelpModal } from '../../../../help/MetalFlowerHelpModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

type MetalFlowerScoreProps = {
  player: Player;
};

const MetalFlowerScore: FC<MetalFlowerScoreProps> = (props) => {
  const { player } = props;
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState(false);


  const displayHelp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHelp(true)
  }

  const metalFlowerScore = getMetalFlowerScoring(player.metalFlowers)
  return (
    <div css={subtitle}>
      <span>
        {t('scoring.metalflower')}
        <FontAwesomeIcon css={helpIcon} icon={faQuestionCircle} onClick={displayHelp} />
      </span>
      <div css={visuals}>
        <MetalFlower css={metalFlower} />
        <span> x { player.metalFlowers }</span>
      </div>
      <div css={score}>
        <span>&nbsp; { metalFlowerScore }</span>
      </div>
      { showHelp && <MetalFlowerHelpModal onClose={() => setShowHelp(false)} /> }
    </div>
  );
};

const helpIcon = css`
  margin-left: 0.5em;
  cursor: help;  
`;

const subtitle = css`
  grid-column: span 24;
  padding: 1em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  height: 10em;
  border: 0.06em solid gray;

  > span {
    font-size: 4em;
    text-align: left;
    flex: 2;
  }
`;
const visuals = css`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
  
  > span {
    font-size: 3em;
    margin-left: 0.5em;
  }
`

const score = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    font-size: 4em;
  }  
`

const metalFlower = css`
  height: ${scoringMetalFlowerHeight * 1.5}em;
  width: ${scoringMetalFlowerWidth * 1.5}em;
`;

export { MetalFlowerScore };
