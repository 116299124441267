import { DragObjectType } from './'

export type PlayerDunaia = {
  type: typeof DragObjectType.PlayerDunaia
  dunaia: number
}

export function playerDunaia(dunaia: number): PlayerDunaia {
    return {
      type: DragObjectType.PlayerDunaia,
      dunaia
    }
}
