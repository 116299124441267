/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import Color from '@gamepark/dunaia/color/Color';
import { Images } from '../../images/Images';
import { constructionTokenHeight, constructionTokenWidth } from '../../styles/Style';

export type ConstructionTokenProps = {
  color: Color;
  scale?: number;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

const ConstructionToken: FC<ConstructionTokenProps> = ({ color, scale, ...props }) => {
  return <div css={constructionTokenStyle(color, scale)} {...props} />;
};

const constructionTokenStyle = (color: Color, scale?: number) => css`
  position: absolute;
  background: url(${ConstructionTokenImages.get(color)});
  background-size: cover;
  height: ${constructionTokenHeight * (scale || 1)}em;
  width: ${constructionTokenWidth * (scale || 1)}em;
  filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
`;

const ConstructionTokenImages = new Map<Color, any>();
ConstructionTokenImages.set(Color.Yellow, Images.YellowToken);
ConstructionTokenImages.set(Color.Red, Images.RedToken);
ConstructionTokenImages.set(Color.Green, Images.GreenToken);
ConstructionTokenImages.set(Color.Purple, Images.PurpleToken);

export { ConstructionToken };
