/**
 * Background
 */
import Background from './background.jpg';

/**
 * Modal background
 */
import ModalBackground from './modal-background.jpg';

/**
 * Player board
 */
import PlayerBoard from '../material/player/visuals/board.jpg';

/**
 * Main board
 */
import MainBoard from '../material/board/visuals/board.png';

/**
 * Memory chip
 */
import MemoryChip1 from '../material/memorychip/visuals/chip1.png';
import MemoryChip2 from '../material/memorychip/visuals/chip2.png';
import MemoryChip3 from '../material/memorychip/visuals/chip3.png';
import MemoryChip4 from '../material/memorychip/visuals/chip4.png';
import MemoryChip5 from '../material/memorychip/visuals/chip5.png';
import MemoryChip6 from '../material/memorychip/visuals/chip6.png';

/**
 * Level 1 buildings
 */
import Level1GreenBuilding1Front from '../material/building/visuals/level1/Level1GreenBuilding1Front.jpg';
import Level1GreenBuilding2Front from '../material/building/visuals/level1/Level1GreenBuilding2Front.jpg';
import Level1GreenBuilding3Front from '../material/building/visuals/level1/Level1GreenBuilding3Front.jpg';
import Level1GreenBuilding4Front from '../material/building/visuals/level1/Level1GreenBuilding4Front.jpg';
import Level1GreenBuilding1Back from '../material/building/visuals/level1/Level1GreenBuilding1Back.jpg';
import Level1GreenBuilding2Back from '../material/building/visuals/level1/Level1GreenBuilding2Back.jpg';
import Level1GreenBuilding3Back from '../material/building/visuals/level1/Level1GreenBuilding3Back.jpg';
import Level1GreenBuilding4Back from '../material/building/visuals/level1/Level1GreenBuilding4Back.jpg';

import Level1RedBuilding1Front from '../material/building/visuals/level1/Level1RedBuilding1Front.jpg';
import Level1RedBuilding2Front from '../material/building/visuals/level1/Level1RedBuilding2Front.jpg';
import Level1RedBuilding3Front from '../material/building/visuals/level1/Level1RedBuilding3Front.jpg';
import Level1RedBuilding4Front from '../material/building/visuals/level1/Level1RedBuilding4Front.jpg';
import Level1RedBuilding1Back from '../material/building/visuals/level1/Level1RedBuilding1Back.jpg';
import Level1RedBuilding2Back from '../material/building/visuals/level1/Level1RedBuilding2Back.jpg';
import Level1RedBuilding3Back from '../material/building/visuals/level1/Level1RedBuilding3Back.jpg';
import Level1RedBuilding4Back from '../material/building/visuals/level1/Level1RedBuilding4Back.jpg';

import Level1YellowBuilding1Front from '../material/building/visuals/level1/Level1YellowBuilding1Front.jpg';
import Level1YellowBuilding2Front from '../material/building/visuals/level1/Level1YellowBuilding2Front.jpg';
import Level1YellowBuilding3Front from '../material/building/visuals/level1/Level1YellowBuilding3Front.jpg';
import Level1YellowBuilding4Front from '../material/building/visuals/level1/Level1YellowBuilding4Front.jpg';
import Level1YellowBuilding1Back from '../material/building/visuals/level1/Level1YellowBuilding1Back.jpg';
import Level1YellowBuilding2Back from '../material/building/visuals/level1/Level1YellowBuilding2Back.jpg';
import Level1YellowBuilding3Back from '../material/building/visuals/level1/Level1YellowBuilding3Back.jpg';
import Level1YellowBuilding4Back from '../material/building/visuals/level1/Level1YellowBuilding4Back.jpg';

import Level1PurpleBuilding1Front from '../material/building/visuals/level1/Level1PurpleBuilding1Front.jpg';
import Level1PurpleBuilding2Front from '../material/building/visuals/level1/Level1PurpleBuilding2Front.jpg';
import Level1PurpleBuilding3Front from '../material/building/visuals/level1/Level1PurpleBuilding3Front.jpg';
import Level1PurpleBuilding4Front from '../material/building/visuals/level1/Level1PurpleBuilding4Front.jpg';
import Level1PurpleBuilding1Back from '../material/building/visuals/level1/Level1PurpleBuilding1Back.jpg';
import Level1PurpleBuilding2Back from '../material/building/visuals/level1/Level1PurpleBuilding2Back.jpg';
import Level1PurpleBuilding3Back from '../material/building/visuals/level1/Level1PurpleBuilding3Back.jpg';
import Level1PurpleBuilding4Back from '../material/building/visuals/level1/Level1PurpleBuilding4Back.jpg';

/**
 * Level 2 Buildings
 */
import Level2GreenBuilding1Front from '../material/building/visuals/level2/Level2GreenBuilding1Front.jpg';
import Level2GreenBuilding2Front from '../material/building/visuals/level2/Level2GreenBuilding2Front.jpg';
import Level2GreenBuilding3Front from '../material/building/visuals/level2/Level2GreenBuilding3Front.jpg';
import Level2GreenBuilding1Back from '../material/building/visuals/level2/Level2GreenBuilding1Back.jpg';
import Level2GreenBuilding2Back from '../material/building/visuals/level2/Level2GreenBuilding2Back.jpg';
import Level2GreenBuilding3Back from '../material/building/visuals/level2/Level2GreenBuilding3Back.jpg';

import Level2RedBuilding1Front from '../material/building/visuals/level2/Level2RedBuilding1Front.jpg';
import Level2RedBuilding2Front from '../material/building/visuals/level2/Level2RedBuilding2Front.jpg';
import Level2RedBuilding3Front from '../material/building/visuals/level2/Level2RedBuilding3Front.jpg';
import Level2RedBuilding1Back from '../material/building/visuals/level2/Level2RedBuilding1Back.jpg';
import Level2RedBuilding2Back from '../material/building/visuals/level2/Level2RedBuilding2Back.jpg';
import Level2RedBuilding3Back from '../material/building/visuals/level2/Level2RedBuilding3Back.jpg';

import Level2YellowBuilding1Front from '../material/building/visuals/level2/Level2YellowBuilding1Front.jpg';
import Level2YellowBuilding2Front from '../material/building/visuals/level2/Level2YellowBuilding2Front.jpg';
import Level2YellowBuilding3Front from '../material/building/visuals/level2/Level2YellowBuilding3Front.jpg';
import Level2YellowBuilding1Back from '../material/building/visuals/level2/Level2YellowBuilding1Back.jpg';
import Level2YellowBuilding2Back from '../material/building/visuals/level2/Level2YellowBuilding2Back.jpg';
import Level2YellowBuilding3Back from '../material/building/visuals/level2/Level2YellowBuilding3Back.jpg';

import Level2PurpleBuilding1Front from '../material/building/visuals/level2/Level2PurpleBuilding1Front.jpg';
import Level2PurpleBuilding2Front from '../material/building/visuals/level2/Level2PurpleBuilding2Front.jpg';
import Level2PurpleBuilding3Front from '../material/building/visuals/level2/Level2PurpleBuilding3Front.jpg';
import Level2PurpleBuilding1Back from '../material/building/visuals/level2/Level2PurpleBuilding1Back.jpg';
import Level2PurpleBuilding2Back from '../material/building/visuals/level2/Level2PurpleBuilding2Back.jpg';
import Level2PurpleBuilding3Back from '../material/building/visuals/level2/Level2PurpleBuilding3Back.jpg';

/**
 * Level 3 buildings
 */

import Level3GreenBuilding1Back from '../material/building/visuals/level3/Level3GreenBuilding1Back.jpg';
import Level3GreenBuilding2Back from '../material/building/visuals/level3/Level3GreenBuilding2Back.jpg';
import Level3YellowBuilding1Back from '../material/building/visuals/level3/Level3YellowBuilding1Back.jpg';
import Level3YellowBuilding2Back from '../material/building/visuals/level3/Level3YellowBuilding2Back.jpg';
import Level3PurpleBuilding1Back from '../material/building/visuals/level3/Level3PurpleBuilding1Back.jpg';
import Level3PurpleBuilding2Back from '../material/building/visuals/level3/Level3PurpleBuilding2Back.jpg';
import Level3RedBuilding1Back from '../material/building/visuals/level3/Level3RedBuilding1Back.jpg';
import Level3RedBuilding2Back from '../material/building/visuals/level3/Level3RedBuilding2Back.jpg';
import Level3MultiBuildingsBack from '../material/building/visuals/level3/Level3MultiBuildingsBack.jpg';
import Level3GreenBuilding1Front from '../material/building/visuals/level3/Level3GreenBuilding1Front.jpg';
import Level3GreenBuilding2Front from '../material/building/visuals/level3/Level3GreenBuilding2Front.jpg';
import Level3YellowBuilding1Front from '../material/building/visuals/level3/Level3YellowBuilding1Front.jpg';
import Level3YellowBuilding2Front from '../material/building/visuals/level3/Level3YellowBuilding2Front.jpg';
import Level3PurpleBuilding1Front from '../material/building/visuals/level3/Level3PurpleBuilding1Front.jpg';
import Level3PurpleBuilding2Front from '../material/building/visuals/level3/Level3PurpleBuilding2Front.jpg';
import Level3RedBuilding1Front from '../material/building/visuals/level3/Level3RedBuilding1Front.jpg';
import Level3RedBuilding2Front from '../material/building/visuals/level3/Level3RedBuilding2Front.jpg';
import Level3MultiBuildingsFront from '../material/building/visuals/level3/Level3MultiBuildingsFront.jpg';

/**
 * Artefact parts
 */
import ArtefactPartOne from '../material/artefact/visuals/artefact-1.png';
import ArtefactPartTwo from '../material/artefact/visuals/artefact-2.png';
import ArtefactPartThree from '../material/artefact/visuals/artefact-3.png';

/**
 * Elder powers
 */
import ElderPowerMetalFlowers from '../material/elderpower/visuals/metal-flower.png';
import ElderPowerMoveDunaia from '../material/elderpower/visuals/move-dunaias.png';
import ElderPowerMoveConstruction from '../material/elderpower/visuals/move-construction.png';
import ElderPowerBuyBuilding from '../material/elderpower/visuals/buy-building.png';
import ElderPowerPlaceColor from '../material/elderpower/visuals/place-color.png';

/**
 * Elder powers positions
 */
import ElderPowerPosition1 from '../material/elderpower/visuals/position-1.png';
import ElderPowerPosition2 from '../material/elderpower/visuals/position-2.png';
import ElderPowerPosition3 from '../material/elderpower/visuals/position-3.png';

/**
 * Prophecies
 */

import Prophecy1 from '../material/prophecy/visuels/Prophecy1.jpg';
import Prophecy2 from '../material/prophecy/visuels/Prophecy2.jpg';
import Prophecy3 from '../material/prophecy/visuels/Prophecy3.jpg';
import Prophecy4 from '../material/prophecy/visuels/Prophecy4.jpg';
import Prophecy5 from '../material/prophecy/visuels/Prophecy5.jpg';
import Prophecy6 from '../material/prophecy/visuels/Prophecy6.jpg';
import Prophecy7 from '../material/prophecy/visuels/Prophecy7.jpg';
import Prophecy8 from '../material/prophecy/visuels/Prophecy8.jpg';
import Prophecy9 from '../material/prophecy/visuels/Prophecy9.jpg';

/**
 * Metal flower
 */
import MetalFlower from '../material/metalflower/visuals/metalflower.png';

/**
 * Construction tokens
 */
import YellowToken from '../material/constructiontoken/visuals/yellow-token.png';
import RedToken from '../material/constructiontoken/visuals/red-token.png';
import GreenToken from '../material/constructiontoken/visuals/green-token.png';
import PurpleToken from '../material/constructiontoken/visuals/purple-token.png';

/**
 * Dunaias
 */
import YellowDunaia1 from '../material/dunaia/visuals/yellow1.jpg';
import YellowDunaia2 from '../material/dunaia/visuals/yellow2.jpg';
import YellowDunaia3 from '../material/dunaia/visuals/yellow3.jpg';
import YellowDunaia4 from '../material/dunaia/visuals/yellow4.jpg';
import YellowDunaia5 from '../material/dunaia/visuals/yellow5.jpg';
import YellowDunaia6 from '../material/dunaia/visuals/yellow6.jpg';
import YellowDunaiaBase from '../material/dunaia/visuals/yellow-base.png';

import RedDunaia1 from '../material/dunaia/visuals/red1.jpg';
import RedDunaia2 from '../material/dunaia/visuals/red2.jpg';
import RedDunaia3 from '../material/dunaia/visuals/red3.jpg';
import RedDunaia4 from '../material/dunaia/visuals/red4.jpg';
import RedDunaia5 from '../material/dunaia/visuals/red5.jpg';
import RedDunaia6 from '../material/dunaia/visuals/red6.jpg';
import RedDunaiaBase from '../material/dunaia/visuals/red-base.png';

import GreenDunaia1 from '../material/dunaia/visuals/green1.jpg';
import GreenDunaia2 from '../material/dunaia/visuals/green2.jpg';
import GreenDunaia3 from '../material/dunaia/visuals/green3.jpg';
import GreenDunaia4 from '../material/dunaia/visuals/green4.jpg';
import GreenDunaia5 from '../material/dunaia/visuals/green5.jpg';
import GreenDunaia6 from '../material/dunaia/visuals/green6.jpg';
import GreenDunaiaBase from '../material/dunaia/visuals/green-base.png';

import PurpleDunaia1 from '../material/dunaia/visuals/purple1.jpg';
import PurpleDunaia2 from '../material/dunaia/visuals/purple2.jpg';
import PurpleDunaia3 from '../material/dunaia/visuals/purple3.jpg';
import PurpleDunaia4 from '../material/dunaia/visuals/purple4.jpg';
import PurpleDunaia5 from '../material/dunaia/visuals/purple5.jpg';
import PurpleDunaia6 from '../material/dunaia/visuals/purple6.jpg';
import PurpleDunaiaBase from '../material/dunaia/visuals/purple-base.png';

/**
 * Button
 */
import Button from '../button/button.png';

/**
 * ColorTokens
 */
import RedColorToken from '../material/colorToken/visuals/red.png';
import PurpleColorToken from '../material/colorToken/visuals/purple.png';
import YellowColorToken from '../material/colorToken/visuals/yellow.png';
import GreenColorToken from '../material/colorToken/visuals/green.png';

/**
 * Icon help
 */
import AnyMemoryChipIcon from '../material/icon-help/visuals/any-memory-chip.jpg';
import BuildingIcon from '../material/icon-help/visuals/building.jpg';
import DunaiaMemoryChipIcon from '../material/icon-help/visuals/dunaia-memory-chip.jpg';
import MetalFlowerIcon from '../material/icon-help/visuals/metal-flower.jpg';
import MoveDunaiaIcon from '../material/icon-help/visuals/move-dunaia.jpg';
import MoveToken from '../material/icon-help/visuals/move-token.jpg';

export const Images = {
  PlayerBoard,
  MainBoard,
  MemoryChip1,
  MemoryChip2,
  MemoryChip3,
  MemoryChip4,
  MemoryChip5,
  MemoryChip6,
  Level1GreenBuilding1Front,
  Level1GreenBuilding2Front,
  Level1GreenBuilding3Front,
  Level1GreenBuilding4Front,
  Level1GreenBuilding1Back,
  Level1GreenBuilding2Back,
  Level1GreenBuilding3Back,
  Level1GreenBuilding4Back,
  Level1RedBuilding1Front,
  Level1RedBuilding2Front,
  Level1RedBuilding3Front,
  Level1RedBuilding4Front,
  Level1RedBuilding1Back,
  Level1RedBuilding2Back,
  Level1RedBuilding3Back,
  Level1RedBuilding4Back,
  Level1YellowBuilding1Front,
  Level1YellowBuilding2Front,
  Level1YellowBuilding3Front,
  Level1YellowBuilding4Front,
  Level1YellowBuilding1Back,
  Level1YellowBuilding2Back,
  Level1YellowBuilding3Back,
  Level1YellowBuilding4Back,
  Level1PurpleBuilding1Front,
  Level1PurpleBuilding2Front,
  Level1PurpleBuilding3Front,
  Level1PurpleBuilding4Front,
  Level1PurpleBuilding1Back,
  Level1PurpleBuilding2Back,
  Level1PurpleBuilding3Back,
  Level1PurpleBuilding4Back,
  Level2GreenBuilding1Front,
  Level2GreenBuilding2Front,
  Level2GreenBuilding3Front,
  Level2GreenBuilding1Back,
  Level2GreenBuilding2Back,
  Level2GreenBuilding3Back,
  Level2RedBuilding1Front,
  Level2RedBuilding2Front,
  Level2RedBuilding3Front,
  Level2RedBuilding1Back,
  Level2RedBuilding2Back,
  Level2RedBuilding3Back,
  Level2YellowBuilding1Front,
  Level2YellowBuilding2Front,
  Level2YellowBuilding3Front,
  Level2YellowBuilding1Back,
  Level2YellowBuilding2Back,
  Level2YellowBuilding3Back,
  Level2PurpleBuilding1Front,
  Level2PurpleBuilding2Front,
  Level2PurpleBuilding3Front,
  Level2PurpleBuilding1Back,
  Level2PurpleBuilding2Back,
  Level2PurpleBuilding3Back,
  ArtefactPartOne,
  ArtefactPartTwo,
  ArtefactPartThree,
  ElderPowerBuyBuilding,
  ElderPowerMetalFlowers,
  ElderPowerMoveConstruction,
  ElderPowerMoveDunaia,
  ElderPowerPlaceColor,
  Level3GreenBuilding1Back,
  Level3GreenBuilding2Back,
  Level3YellowBuilding1Back,
  Level3YellowBuilding2Back,
  Level3PurpleBuilding1Back,
  Level3PurpleBuilding2Back,
  Level3RedBuilding1Back,
  Level3RedBuilding2Back,
  Level3MultiBuildingsBack,
  Level3GreenBuilding1Front,
  Level3GreenBuilding2Front,
  Level3YellowBuilding1Front,
  Level3YellowBuilding2Front,
  Level3PurpleBuilding1Front,
  Level3PurpleBuilding2Front,
  Level3RedBuilding1Front,
  Level3RedBuilding2Front,
  Level3MultiBuildingsFront,
  Prophecy1,
  Prophecy2,
  Prophecy3,
  Prophecy4,
  Prophecy5,
  Prophecy6,
  Prophecy7,
  Prophecy8,
  Prophecy9,
  MetalFlower,
  PurpleToken,
  RedToken,
  YellowToken,
  GreenToken,
  YellowDunaia1,
  YellowDunaia2,
  YellowDunaia3,
  YellowDunaia4,
  YellowDunaia5,
  YellowDunaia6,
  YellowDunaiaBase,
  RedDunaia1,
  RedDunaia2,
  RedDunaia3,
  RedDunaia4,
  RedDunaia5,
  RedDunaia6,
  RedDunaiaBase,
  GreenDunaia1,
  GreenDunaia2,
  GreenDunaia3,
  GreenDunaia4,
  GreenDunaia5,
  GreenDunaia6,
  GreenDunaiaBase,
  PurpleDunaia1,
  PurpleDunaia2,
  PurpleDunaia3,
  PurpleDunaia4,
  PurpleDunaia5,
  PurpleDunaia6,
  PurpleDunaiaBase,
  Button,
  RedColorToken,
  GreenColorToken,
  PurpleColorToken,
  YellowColorToken,
  ElderPowerPosition1,
  ElderPowerPosition2,
  ElderPowerPosition3,
  AnyMemoryChipIcon,
  BuildingIcon,
  DunaiaMemoryChipIcon,
  MetalFlowerIcon,
  MoveDunaiaIcon,
  MoveToken,
  Background,
  ModalBackground
};
