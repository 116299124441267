/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { useDrop } from 'react-dnd';
import { DragObjectType } from '../../draggable';
import { DunaiaCoordinate } from '../dunaia/DunaiaPosition';
import { shineEffect } from '../../styles/Style';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import Color from '@gamepark/dunaia/color/Color';
import { selectDunaiaMove } from '../../moves/SelectDunaia';
import { moveDunaiaMove } from '@gamepark/dunaia/moves/MoveDunaia';
import { Player } from '@gamepark/dunaia/player/Player';

type PlayerBoardSpaceProps = {
  index: number;
  selectedDunaia?: number;
  activeColor: Color;
  player: Player;
  coordinates: DunaiaCoordinate;
}

const PlayerBoardSpace: FC<PlayerBoardSpaceProps> = (props) => {
  const { index, selectedDunaia, activeColor, player, coordinates } = props;
  const play = usePlay();
  const playerId = usePlayerId();
  const canMoveDunaia = selectedDunaia !== undefined && !player.dunaias.includes(index) && playerId === activeColor && player.color === playerId;

  const moveDunaia = (index: number) => {
    if (!canMoveDunaia) {
      return;
    }

    play(selectDunaiaMove(), { local: true });
    play(moveDunaiaMove(selectedDunaia, index));
  };

  const [{ isOver }, ref] = useDrop({
    accept: DragObjectType.PlayerDunaia,
    canDrop: () => canMoveDunaia,
    drop: () => moveDunaiaMove(selectedDunaia!, index),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      key={index}
      ref={ref}
      css={[
        space(coordinates),
        canMoveDunaia && highlightSpace,
        canMoveDunaia && isOver && hoverSpace
      ]}
      onClick={() => moveDunaia(index)}
    />
  )
}

const hoverSpace = css`
  background-color: rgba(255, 255, 255, 0.8);
`

const space = (position: DunaiaCoordinate) => css`
  position: absolute;
  height: 5em;
  width: 5em;
  top: ${position.top + 0.9}em;
  left: ${position.left + 0.9}em;
  transform: translateZ(0.1em);
`;

const highlightSpace = css`
  border-radius: 50%;
  cursor: pointer;
  ${shineEffect(4)}
  transform: translateZ(0.1em) scale(1.3);
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }

`;

export {
  PlayerBoardSpace
}
