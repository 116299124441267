import { TutorialDescription } from '@gamepark/react-client';
import GameState from '@gamepark/dunaia/GameState';
import Move from '@gamepark/dunaia/moves/Move';
import Color from '@gamepark/dunaia/color/Color';
import { chooseDiceMove } from '@gamepark/dunaia/moves/ChooseDice';
import { recycleDiceOnBoardMove, recycleDiceOnBuildingMove } from '@gamepark/dunaia/moves/RecycleDice';
import { endTurnMove } from '@gamepark/dunaia/moves/EndTurn';
import { buyBuildingMove } from '@gamepark/dunaia/moves/BuyBuilding';
import { gainMemoryChipsMove } from '@gamepark/dunaia/moves/GainMemoryChips';
import { callElderMove } from '@gamepark/dunaia/moves/CallElder';
import { moveConstructionTokenMove } from '@gamepark/dunaia/moves/MoveConstructionToken';
import { moveDunaiaMove } from '@gamepark/dunaia/moves/MoveDunaia';
import { TutorialInitializer } from './TutorialInitializer';

const Me = Color.Green;
const Opponent = Color.Red;
const state = new TutorialInitializer({ players: [{ id: Me}, { id: Opponent }] }).buildState();
const DunaiaTutorial: TutorialDescription<GameState, Move, Color> = {
  setupTutorial: () => {
    return [state, [Me, Opponent]];
  },

  expectedMoves: () => [
    chooseDiceMove(3),
    recycleDiceOnBoardMove(1),
    endTurnMove,
    chooseDiceMove(1),
    recycleDiceOnBoardMove(1),
    endTurnMove,
    chooseDiceMove(3),
    recycleDiceOnBoardMove(0),
    buyBuildingMove(7, { x: 2, y: 0 }), // 7 : Yellow building with move construction effect token TODO: display it on top
    endTurnMove,
    chooseDiceMove(1),
    recycleDiceOnBoardMove(1),
    endTurnMove,
    chooseDiceMove(1),
    recycleDiceOnBoardMove(3),
    gainMemoryChipsMove([4]),
    callElderMove(1, [6]),
    moveConstructionTokenMove(7), // 7 : Yellow building with move construction effect token TODO: display it on top
    endTurnMove,
    chooseDiceMove(1),
    recycleDiceOnBoardMove(0),
    buyBuildingMove(25, { x: 1, y: 1 }), // TODO: display Red level 2 building on top
    endTurnMove,
    chooseDiceMove(0),
    recycleDiceOnBuildingMove(7),
    moveDunaiaMove(2, 20),
  ],
};

export {
  DunaiaTutorial
}
