import { Player } from '../player/Player';
import { ElderPowerMemoryChips } from '../material/board/ElderPowerMemoryChips';
import { ElderPower } from '../material/power/ElderPower';
/**
 * Compute the list of elder power chips extracted from an elderPower index and a list of memory chips
 * @param elderPower The elder power index (the board index)
 * @param memoryChips The additional memory chips
 * @param baseElderPowerChips The base elder power chips
 */

export const getCallElderMemoryChips = (elderPower: number, memoryChips: number[], baseElderPowerChips: number[][]) => {
  return [...baseElderPowerChips[elderPower], ...memoryChips];
};

/**
 * Can the player call the elder
 * @param player The player that we want to control
 * @param elderPowerIndex The elder power position on the board
 * @param elderPower The power
 */
export const canCallElder = (player: Player, elderPowerIndex: number, elderPower: ElderPower) => {
  if (player.pending.length) {
    return false;
  }
  const additionalMemoryChips = elderPower.memoryChips;
  const defaultMemoryChips = ElderPowerMemoryChips[elderPowerIndex];

  if (defaultMemoryChips.some((m) => !player.memoryChips.includes(m))) {
    return false;
  }

  return (
    !additionalMemoryChips ||
    player.memoryChips.filter((m) => !defaultMemoryChips.includes(m)).length >= additionalMemoryChips
  );
};

/**
 * Can the player win the artefact part
 */
export const canGetArtefactPart = (player: Player, elderPowerIndex: number) => {
  const currentArtefact = player.artefacts[player.artefacts.length - 1];

  return !currentArtefact || currentArtefact.length === 3 || !currentArtefact.includes(elderPowerIndex);
};
