import MoveType from './MoveType';
import GameState from '../GameState';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';
import { Building } from '../material/building/Building';

export type MoveConstructionToken = {
  type: MoveType.MoveConstructionToken;
  building: number;
};

export const moveConstructionTokenMove = (building: number): MoveConstructionToken => ({
  type: MoveType.MoveConstructionToken,
  building,
});

export const moveConstructionToken = (state: GameState, move: MoveConstructionToken, buildings: Building[]) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  const playerBuilding = player.buildings.flat().find((b) => b && b.building === move.building);
  if (!playerBuilding || !playerBuilding.constructionToken) {
    throw new Error("You are trying to move a construction token on a building you don't possess.");
  }

  playerBuilding.constructionToken++;
  if (playerBuilding.constructionToken === buildings[move.building].constructionSteps) {
    delete playerBuilding.constructionToken;
    player.constructionTokens++;
  }

  shiftFirstEffectOfType(player.pending, GameEffectType.MoveConstructionToken);
};
