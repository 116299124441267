/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BuildingTile } from './BuildingTile';
import { usePlay } from '@gamepark/react-client';
import { selectBuildingMove } from '../../moves/SelectBuilding';
import { modalOverlay } from '../../styles/Style';

export type BuildingViewerProps = {
  buildings: number[];
  onClose: () => void;
  oldTokensCount?: boolean;
};

const BuildingsViewer: FC<BuildingViewerProps> = (props) => {
  const { onClose, buildings } = props;
  const play = usePlay();

  const onBuildingPress = (e: any, building: number) => {
    play(selectBuildingMove(building), { local: true });
    e.preventDefault();
    e.stopPropagation();
    return;
  };

  const catalog = (
    <>
      <div
        key='overlay-viewer'
        css={[
          modalOverlay,
          css`
            background: rgba(0, 0, 0, 0.8);
          `
        ]}
        onClick={onClose}
      />
      <div css={buildingsContainer}>
        {buildings.map((building) => (
          <BuildingTile key={building} css={floatingLeft} scale={1.3} building={building} onClick={(e) => onBuildingPress(e, building)} />
        ))}
      </div>
      <div css={closeStyle} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(catalog, letterBox);
  }

  return null;
};

const buildingsContainer = css`
  position: absolute;
  top: 50em;
  width: 150em;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(50em);
`

const floatingLeft = css`
  float: left;
  margin: 1.5em;
`;

const closeStyle = css`
  border-radius: 50%;
  background-color: white;
  padding: 0.5em;
  height: 7em;
  width: 7em;
  position: absolute;
  top: 5em;
  right: 5em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateZ(50em);

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 4em;
  }
`;

export { BuildingsViewer };
