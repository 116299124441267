/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { artefactPartHeight, artefactPartWidth } from '../../styles/Style';
import { Images } from '../../images/Images';

export type ArtefactPartProps = {
  part: number;
} & HTMLAttributes<HTMLDivElement>;

export const ArtefactPart: FC<ArtefactPartProps> = ({ part, ...props }) => {

  return (
    <div css={artefactPartStyle(part)} { ...props } />
  )
}

const ArtefactPartImages = [
  Images.ArtefactPartOne,
  Images.ArtefactPartTwo,
  Images.ArtefactPartThree
]

const artefactPartStyle = (part: number) => css`
  height: ${artefactPartHeight(part)}em;
  width: ${artefactPartWidth(part)}em;
  background-image: url(${ArtefactPartImages[part]});
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
`
