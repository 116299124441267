import GameView from '@gamepark/dunaia/GameView'
import MoveType from '@gamepark/dunaia/moves/MoveType'
import MoveView from '@gamepark/dunaia/moves/MoveView'
import {Action, Rules, Undo} from '@gamepark/rules-api'
import {chooseDice} from '@gamepark/dunaia/moves/ChooseDice'
import {moveDunaia} from '@gamepark/dunaia/moves/MoveDunaia'
import {recycleDice} from '@gamepark/dunaia/moves/RecycleDice'
import {retrieveProphecy} from '@gamepark/dunaia/moves/RetrieveProphecy'
import {gainMetalFlowers} from '@gamepark/dunaia/moves/GainMetalFlowers'
import {gainMemoryChips} from '@gamepark/dunaia/moves/GainMemoryChips'
import {buyBuilding} from '@gamepark/dunaia/moves/BuyBuilding'
import {moveConstructionToken} from '@gamepark/dunaia/moves/MoveConstructionToken'
import {placeColorToken} from '@gamepark/dunaia/moves/PlaceColorToken'
import {callElder} from '@gamepark/dunaia/moves/CallElder'
import {gainArtefactPart} from '@gamepark/dunaia/moves/GainArtefactPart'
import {getPredictableAutomaticMoves} from '@gamepark/dunaia/utils/automatic-moves.utils'
import {endTurn} from '@gamepark/dunaia/moves/EndTurn'
import {rollDice} from '@gamepark/dunaia/moves/RollDice'
import Move from '@gamepark/dunaia/moves/Move'
import {displayPlayer, DisplayPlayer} from './moves/DisplayPlayer'
import Color from '@gamepark/dunaia/color/Color'
import {Prophecies} from '@gamepark/dunaia/material/prophecy/Prophecies'
import {Buildings} from '@gamepark/dunaia/material/building/Buildings'
import {BoardActions} from '@gamepark/dunaia/material/board/BoardActions'
import {isPlayerWinProphecy} from '@gamepark/dunaia/utils/prophecy.utils'
import {undoUtils} from '@gamepark/dunaia/utils/undo.utils'
import {selectBuilding, SelectBuilding} from './moves/SelectBuilding'
import {selectDunaia, SelectDunaia} from './moves/SelectDunaia'
import {selectElderPower, SelectElderPower} from './moves/SelectElderPower'
import {selectToBuyBuilding, SelectToBuyBuilding} from './moves/SelectForBuyingBuilding'
import {awakeDunaia} from '@gamepark/dunaia/moves/AwakeDunaia'
import {DefaultBoardEffects} from '@gamepark/dunaia/default/DefaultBoardEffects'
import {selectProphecy, SelectProphecy} from './moves/SelectProphecy'

declare type LocalMove =
  | MoveView
  | DisplayPlayer
  | SelectBuilding
  | SelectDunaia
  | SelectElderPower
  | SelectToBuyBuilding
  | SelectProphecy;

export default class DunaiaView extends Rules<GameView, MoveView, Color> implements Undo<GameView, MoveView, Color> {
  getActivePlayer(): Color | undefined {
    return this.state.activePlayer
  }

  getAutomaticMoves(): Move[] {
    const activePlayer = this.state.players.find((p) => p.color === this.state.activePlayer)!

    const wonProphecies = this.state.prophecies.filter((p) =>
      isPlayerWinProphecy(activePlayer, Prophecies[p], Buildings)
    )

    if (!wonProphecies.length && this.state.players.every((p) => p.end) && !this.state.dice.length) {
      return []
    }

    return getPredictableAutomaticMoves(this.state, Prophecies, Buildings)
  }

  canUndo(action: Action<Move, Color>): boolean {
    return undoUtils(action, this.state.activePlayer)
  }

  play(move: LocalMove): MoveView[] {
    switch (move.type) {
      case MoveType.ChooseDice:
        chooseDice(this.state, move)
        break
      case MoveType.MoveDunaia:
        moveDunaia(this.state, move)
        break
      case MoveType.RecycleDice:
        recycleDice(this.state, move, BoardActions, Buildings)
        break
      case MoveType.RetrieveProphecy:
        retrieveProphecy(this.state, move)
        break
      case MoveType.GainMetalFlowers:
        gainMetalFlowers(this.state, move)
        break
      case MoveType.GainMemoryChips:
        gainMemoryChips(this.state, move)
        break
      case MoveType.BuyBuilding:
        buyBuilding(this.state, move)
        break
      case MoveType.MoveConstructionToken:
        moveConstructionToken(this.state, move, Buildings)
        break
      case MoveType.PlaceColorToken:
        placeColorToken(this.state, move)
        break
      case MoveType.CallElder:
        callElder(this.state, move)
        break
      case MoveType.GainArtefactPart:
        gainArtefactPart(this.state, move)
        break
      case MoveType.RollDice:
        rollDice(this.state, move)
        break
      case MoveType.EndTurn:
        endTurn(this.state)
        break
      case MoveType.AwakeDunaia:
        awakeDunaia(this.state, move, Buildings, DefaultBoardEffects)
        break
      case 'DisplayPlayer':
        displayPlayer(this.state, move)
        break
      case 'SelectBuilding':
        selectBuilding(this.state, move)
        break
      case 'SelectDunaia':
        selectDunaia(this.state, move)
        break
      case 'SelectElderPower':
        selectElderPower(this.state, move)
        break
      case 'SelectToBuyBuilding':
        selectToBuyBuilding(this.state, move)
        break
      case 'SelectProphecy':
        selectProphecy(this.state, move)
        break
    }
    return []
  }

  restoreLocalMoves(localState: GameView) {
    this.state.displayedPlayer = localState.displayedPlayer
    this.state.selectedElderPower = localState.selectedElderPower
    this.state.selectedBuilding = localState.selectedBuilding
    this.state.selectedDunaia = localState.selectedDunaia
    this.state.toBuyBuilding = localState.toBuyBuilding
    this.state.selectedProphecy = localState.selectedProphecy
  }
}
