/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '@gamepark/dunaia/color/Color';
import { FC, HTMLAttributes } from 'react';
import { dunaiaBaseHeight, dunaiaBaseWidth } from '../../styles/Style';
import { Images } from '../../images/Images';

type DunaiaBaseProps = {
  highlight?: boolean;
  selected?: boolean;
  color: Color;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>

const DunaiaBase: FC<DunaiaBaseProps> = (props) => {
  const { color, highlight, selected, ...rest } = props;
  return (
    <div css={[dunaiaBaseStyle(color), highlight && selectableDunaiaBase, selected && selectedDunaiaBase]} {...rest} />
  )
}

const dunaiaBaseStyle = (color: Color) => css`
  position: absolute;
  bottom: ${dunaiaBaseHeight / 2 + 2}em;
  left: 1em;
  height: ${dunaiaBaseHeight}em;
  width: ${dunaiaBaseWidth}em;
  background: url(${DunaiaBaseImages.get(color)}) no-repeat;
  background-size: contain;
  transform-style: preserve-3d;
  transform-origin: center bottom;
  transform: rotateX(-90deg) rotateY(90deg);
  box-shadow: 0 0 0.4em 0.2em black;
  cursor: pointer;
`;

const selectedDunaiaBase = css`
  box-shadow: 0 0 0.4em 0.2em green;
`;

const selectableDunaiaBase = css`
  box-shadow: 0 0 0.4em 0.2em gold;
  &:hover {
    ${selectedDunaiaBase}
  }
`;

const DunaiaBaseImages = new Map<Color, any>();
DunaiaBaseImages.set(Color.Yellow, Images.YellowDunaiaBase);
DunaiaBaseImages.set(Color.Red, Images.RedDunaiaBase);
DunaiaBaseImages.set(Color.Green, Images.GreenDunaiaBase);
DunaiaBaseImages.set(Color.Purple, Images.PurpleDunaiaBase);

export {
  DunaiaBase
};
