import Color from '@gamepark/dunaia/color/Color';
import GameView from '@gamepark/dunaia/GameView';

export type SelectBuilding = {
  type: 'SelectBuilding';
  building?: number;
};

export const selectBuildingMove = (building?: Color): SelectBuilding => ({
  type: 'SelectBuilding',
  building,
});

export function selectBuilding(state: GameView, move: SelectBuilding) {
  state.selectedBuilding = move.building;
}
