import { GameEffects } from '../../effect/GameEffect';
import { BuyBuildingEffect } from '../../effect/BuyBuildingEffect';
import { GainMetalFlowerEffect } from '../../effect/GainMetalFlowerEffect';
import { MoveDunaiaEffect } from '../../effect/MoveDunaiaEffect';
import { GainMemoryChipsEffect } from '../../effect/GainMemoryChipsEffect';

export const BoardActions: GameEffects[] = [
  new BuyBuildingEffect(),
  new GainMetalFlowerEffect(1),
  new MoveDunaiaEffect(),
  new GainMemoryChipsEffect(false),
];
