/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { mainBoardHeight, mainBoardLeft, mainBoardTop, mainBoardWidth } from '../../styles/Style';
import { Images } from '../../images/Images';
import { MainBoardBuildings } from './MainBoardBuildings';
import { MainBoardArtefacts } from './MainBoardArtefacts';
import { MainBoardElderPowers } from './MainBoardElderPowers';
import { MainBoardDices } from './MainBoardDices';
import { Dice } from '@gamepark/dunaia/common/Dice';
import { Player } from '@gamepark/dunaia/player/Player';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { MainBoardActions } from './MainBoardActions';
import Color from '@gamepark/dunaia/color/Color';

export type MainBoardProps = {
  buildings: number[][][];
  elderPowers: number[];
  dice: Dice[];
  diceRecycledOnBoard: PlayerDice[];
  activePlayer: Player;
  displayedColor?: Color;
  toBuyBuilding?: number;
  activePlayerPanelIndex: number;
} & HTMLAttributes<HTMLDivElement>;

const MainBoard: FC<MainBoardProps> = (props) => {
  const {
    buildings,
    elderPowers,
    dice,
    diceRecycledOnBoard,
    activePlayer,
    toBuyBuilding,
    activePlayerPanelIndex,
    displayedColor,
  } = props;

  return (
    <>
      <div css={mainBoard}>
        <MainBoardActions dice={diceRecycledOnBoard} player={activePlayer} buildings={buildings} />
        <MainBoardBuildings
          buildings={buildings}
          activePlayer={activePlayer}
          displayedColor={displayedColor}
          toBuyBuilding={toBuyBuilding}
          activePlayerPanelIndex={activePlayerPanelIndex}
        />
        <MainBoardArtefacts activePlayerPanelIndex={activePlayerPanelIndex} activePlayer={activePlayer} />
        <MainBoardElderPowers elderPowers={elderPowers} activePlayer={activePlayer} />
        <MainBoardDices dice={dice} activePlayer={activePlayer} activePlayerPanelIndex={activePlayerPanelIndex} />
      </div>
    </>
  );
};

const mainBoard = css`
  position: absolute;
  top: ${mainBoardTop}em;
  left: ${mainBoardLeft}em;
  height: ${mainBoardHeight}em;
  width: ${mainBoardWidth}em;
  background-image: url(${Images.MainBoard});
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
  box-shadow: 0 0 0.6em 0.1em black;
  transform-style: preserve-3d;
`;

export { MainBoard };
