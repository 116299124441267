/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { FC, useEffect } from 'react';
import { playerBuildingLeft, playerBuildingTop } from '../../styles/Style';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { BuyBuilding } from '@gamepark/dunaia/moves/BuyBuilding';
import { selectBuildingMove } from '../../moves/SelectBuilding';
import { PlayerBuildingTile } from './PlayerBuilding';
import { selectToBuyBuildingMove } from '../../moves/SelectForBuyingBuilding';
import { canBuyBuilding } from '@gamepark/dunaia/utils/building.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import { isBuyBuilding } from '@gamepark/dunaia/utils/is-move.utils';
import { PlayerAvailableBuildingPosition } from './PlayerAvailableBuildingPosition';

export type PlayerBuildingsProps = {
  player: Player;
  dice: PlayerDice[];
  selectedBuilding?: number;
  toBuyBuilding?: number;
  canRecycleDie: boolean;
  gameOver: boolean;
};

const PlayerBuildings: FC<PlayerBuildingsProps> = (props) => {
  const { selectedBuilding, toBuyBuilding, dice, player, canRecycleDie, gameOver } = props;
  const buildings = player.buildings;
  const play = usePlay();
  const playerId = usePlayerId();
  const animation = useAnimation<BuyBuilding>((a) => isBuyBuilding(a.move));
  const iCanBuy = canBuyBuilding(player);
  const canDropBuilding = iCanBuy && player.color === playerId && selectedBuilding !== undefined && !animation;

  useEffect(() => {
    if (!iCanBuy && (selectedBuilding || toBuyBuilding)) {
      play(selectToBuyBuildingMove(), { local: true });
      play(selectBuildingMove(), { local: true });
    }
  }, [play, iCanBuy, selectedBuilding, toBuyBuilding]);

  return (
    <>
      {buildings.flatMap((col, x) =>
        col.map((building, y) => {
          return !!building ? (
            <PlayerBuildingTile
              key={building.building}
              building={building}
              dice={dice}
              player={player}
              css={[buildingPosition(x, y)]}
              position={{ x, y }}
              gameOver={gameOver}
              canRecycleDie={canRecycleDie}
            />
          ) : (
            canDropBuilding && <PlayerAvailableBuildingPosition key={`${x}-${y}`} player={player} x={x} y={y} selectedBuilding={selectedBuilding} />
          );
        })
      )}
    </>
  );
};

const buildingPosition = (x: number, y: number) => css`
  position: absolute;
  left: ${playerBuildingLeft(x)}em;
  top: ${playerBuildingTop(y)}em;
  transform: translateZ(0.1em);
  cursor: pointer;
  transform-style: preserve-3d;
`;

export { PlayerBuildings };
