/** @jsxImportSource @emotion/react */
import GameView from '@gamepark/dunaia/GameView';
import { FailuresDialog, FullscreenDialog, Menu, useGame } from '@gamepark/react-client';
import { Header, ImagesLoader, LoadingScreen } from '@gamepark/react-components';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5ToTouch from 'react-dnd-multi-backend/dist/cjs/HTML5toTouch';
import GameDisplay from './GameDisplay';
import HeaderText from './header/HeaderText';
import { Images } from './images/Images';


export default function App() {
  const game = useGame<GameView>();
  const [isJustDisplayed, setJustDisplayed] = useState(true);
  const [isImagesLoading, setImagesLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setJustDisplayed(false), 2000);
  }, []);
  const loading = !game || isJustDisplayed || isImagesLoading;
  return (
    <DndProvider options={HTML5ToTouch}>

      {!loading && game && <GameDisplay game={game} />}
      <LoadingScreen
        display={loading}
        author="Thomas Dupont"
        artist="Sébastien Caiveau"
        publisher="Blam!"
        developer="Patrick Beaumé"
      />
      <Header>
        <HeaderText loading={loading} game={game} />
      </Header>
      <ImagesLoader images={Object.values(Images)} onImagesLoad={() => setImagesLoading(false)} />
      <Menu />
      <FailuresDialog />
      <FullscreenDialog />
    </DndProvider>
  );
}
