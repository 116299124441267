/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, Fragment, useMemo, useState } from 'react';
import { Player } from '@gamepark/dunaia/player/Player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import { selectBuildingMove } from '../../moves/SelectBuilding';
import { PlayerBuildingTile } from '../player/PlayerBuilding';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { BuildingTile } from './BuildingTile';
import { canBuyBuilding, canPlaceColorToken, hasColor } from '@gamepark/dunaia/utils/building.utils';
import { selectToBuyBuildingMove } from '../../moves/SelectForBuyingBuilding';
import { DunaiaButton } from '../../button/DunaiaButton';
import Color, { colors } from '@gamepark/dunaia/color/Color';
import { displayPlayerMove } from '../../moves/DisplayPlayer';
import {
  Buildings,
  Level1GreenBuilding1,
  Level1GreenBuilding2,
  Level1GreenBuilding3,
  Level1GreenBuilding4,
  Level1PurpleBuilding1,
  Level1PurpleBuilding2,
  Level1PurpleBuilding3,
  Level1PurpleBuilding4,
  Level1RedBuilding1,
  Level1RedBuilding2,
  Level1RedBuilding3,
  Level1RedBuilding4,
  Level1YellowBuilding1,
  Level1YellowBuilding2,
  Level1YellowBuilding3,
  Level1YellowBuilding4
} from '@gamepark/dunaia/material/building/Buildings';
import { ColorToken } from '../colorToken/ColorToken';
import { colorTokenChooserLeft, colorTokenChooserTop, colorTokenSize } from '../../styles/Style';
import { placeColorTokenMove } from '@gamepark/dunaia/moves/PlaceColorToken';
import { createPortal } from 'react-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Building } from '@gamepark/dunaia/material/building/Building';
import { HelpBackground } from '../../help/HelpBackground';
import { BuildingHelpContent } from '../../help/BuildingHelpContent';
import { Position } from '@gamepark/dunaia/common/Position';

type BuildingViewerProps = {
  building: number;
  activePlayer: Player;
  recycledDice: PlayerDice[];
  players: Player[];
  price?: number;
  displayedColor?: Color;
  gameOver: boolean;
  position?: Position;
};

export const getTranslateKey = (building: Building) => {
  if (!building.color) {
    return 'buildings.description.multicolor'
  }
  if (building.level === 1) {
    return BuildingDescriptions.get(building);
  }

  if (building.level === 2) {
    return 'buildings.description.level2';
  }

  return 'buildings.description.level3';

}

const buildingScale = 2;
const BuildingViewer: FC<BuildingViewerProps> = (props) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState<boolean>();
  const { building, players, recycledDice, activePlayer, price, displayedColor, gameOver, position } = props;
  const iCanBuy =
    canBuyBuilding(activePlayer) &&
    activePlayer.color === playerId &&
    price !== undefined &&
    activePlayer.metalFlowers >= price;

  const closeChooser = () => play(selectBuildingMove(), { local: true });
  const playerBuilding = useMemo(
    () =>
      players
        .flatMap((p) => ({
          building: p.buildings.flat().find((b) => b?.building === building),
          color: p.color
        }))
        .find((v) => v.building !== undefined),
    [players, building]
  );
  const player = playerBuilding && players.find(p => p.color === playerBuilding.color);

  const chooseToBuyBuilding = () => {
    if (displayedColor !== undefined && displayedColor !== playerId) {
      play(displayPlayerMove(playerId), { local: true });
    }
    play(selectToBuyBuildingMove(building), { local: true });
    closeChooser();
  };

  const canPlaceColor: boolean = !!playerBuilding?.building && !!player && player.color === playerId && canPlaceColorToken(player, playerBuilding.building!, Buildings);
  const possibleColorTokens = canPlaceColor? colors.filter((c) => !hasColor(playerBuilding!.building!, Buildings, c)): [];

  const placeColorToken = (color: Color) => {
    if (possibleColorTokens.includes(color)) {
      play(placeColorTokenMove(color, building));
    }
  };

  const toggleHelp = () => {
    setShowHelp(show => !show);
  }

  const viewver = (
    <>
      <div css={overlay} onClick={closeChooser} />
      <div css={actionStyle} onClick={closeChooser}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div css={[actionStyle, questionStyle]} onClick={toggleHelp}>
        <FontAwesomeIcon icon={faQuestion} />
      </div>
      {possibleColorTokens.map((c, index) => (
        <Fragment key={c}>
          <div css={arrow(index, buildingScale)}></div>
          <ColorToken
            color={c}
            outerCircleStyle={outerCircle(buildingScale)}
            css={colorPosition(index, buildingScale)}
            onClick={() => placeColorToken(c)}
            scale={buildingScale} />
        </Fragment>
      ))
      }
      <div css={buildingDescription}><span><Trans defaults={getTranslateKey(Buildings[building])} components={[<strong />]} /></span></div>
      {iCanBuy && (
        <DunaiaButton labelKey={t('button.choose-building')} highlighted css={buyBuildingButton} onClick={chooseToBuyBuilding} />
      )}
      { showHelp && <HelpBackground onClose={() => setShowHelp(false)} />}
      {(!playerBuilding || !playerBuilding.building!.constructionToken) && (
        <BuildingTile scale={buildingScale} building={building} css={[constructionFace, showHelp && helpPosition]} constructed={false} preTransform="translateZ(50em)"  />
      )}
      {(!playerBuilding || playerBuilding.building!.constructionToken) && (
        <BuildingTile scale={buildingScale} building={building} css={[builtFace, showHelp && helpPosition]} preTransform="translateZ(50em)" constructed />
      )}
      {!!playerBuilding && (
        <PlayerBuildingTile
          scale={buildingScale}
          building={playerBuilding.building!}
          dice={recycledDice}
          player={player!}
          gameOver={gameOver}
          position={position!}
          css={[!playerBuilding.building!.constructionToken ? builtFace : constructionFace, showHelp && helpPosition]}
          notTargetable
        />
      )}
      { showHelp && <BuildingHelpContent building={building} onClose={() => setShowHelp(false)} />}
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(viewver, letterBox);
  }

  return null;
};

const arrow = (position: number, scale: number = 1) => css`
  position: absolute;
  top: ${colorTokenChooserTop(position, scale) + 1.5}em;
  left: ${colorTokenChooserLeft - 4}em;

  width: 3em;
  height: 3em;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: calc(3em / 1.41);
    height: calc(3em / 1.41);
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    transform: rotate(-45deg);
    transform-origin: 100% 0;
  }
`;

const overlay = css`
  position: absolute;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: rgba(0, 0, 0, 0.77);
  cursor: pointer;
  transform: translateZ(50em);
`;

const actionStyle = css`
  border-radius: 50%;
  background-color: white;
  padding: 0.5em;
  height: 7em;
  width: 7em;
  position: absolute;
  top: 15em;
  right: 20em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateZ(50em);

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 4em;
  }
`;

const questionStyle = css`
  top: 23em;
  transform: translateZ(50em);
`

const constructionFace = css`
  transition: top 0.5s;
  position: absolute;
  top: 15em;
  left: 40em;
  transform-style: preserve-3d;
`;

const builtFace = css`
  transition: top 0.5s;
  position: absolute;
  top: 15em;
  left: 100em;
  transform-style: preserve-3d;
`;

const helpPosition = css`
  top: 35em;
  transform: translateZ(50em);
`;

const buyBuildingButton = css`
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translateX(-50%) translateZ(50em);
`;


const buildingDescription = css`
  position: absolute;
  top: 58%;
  padding: 2em;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 50%;
  border-radius: 2em;
  transform: translate(-50%, -50%) translateZ(50em);  
  > span {
    font-size: 4em;
    word-break: break-word;
  }
`;

const colorPosition = (position: number, scale: number = 1) => css`position: absolute;
  top: ${colorTokenChooserTop(position, scale)}em;
  left: ${colorTokenChooserLeft}em;
  cursor: pointer;
  transform: translateZ(50em);
`;

const outerCircle = (scale: number = 1) => css`
  &:hover::after {
    content: '';
    height: ${colorTokenSize * scale}em;
    width: ${colorTokenSize * scale}em;
    border-radius: 50%;
    border: 0.3em solid white;
    position: absolute;
    top: -0.3em;
    left: -0.3em;
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const BuildingDescriptions = new Map<Building, string>();
BuildingDescriptions.set(Level1GreenBuilding1, 'buildings.description.level1.memory-chip');
BuildingDescriptions.set(Level1GreenBuilding2, 'buildings.description.level1.metal-flower');
BuildingDescriptions.set(Level1GreenBuilding3, 'buildings.description.level1.construction-token');
BuildingDescriptions.set(Level1GreenBuilding4, 'buildings.description.level1.move-dunaia');
BuildingDescriptions.set(Level1RedBuilding1, 'buildings.description.level1.memory-chip');
BuildingDescriptions.set(Level1RedBuilding2, 'buildings.description.level1.metal-flower');
BuildingDescriptions.set(Level1RedBuilding3, 'buildings.description.level1.construction-token');
BuildingDescriptions.set(Level1RedBuilding4, 'buildings.description.level1.move-dunaia');
BuildingDescriptions.set(Level1YellowBuilding1, 'buildings.description.level1.memory-chip');
BuildingDescriptions.set(Level1YellowBuilding2, 'buildings.description.level1.metal-flower');
BuildingDescriptions.set(Level1YellowBuilding3, 'buildings.description.level1.construction-token');
BuildingDescriptions.set(Level1YellowBuilding4, 'buildings.description.level1.move-dunaia');
BuildingDescriptions.set(Level1PurpleBuilding1, 'buildings.description.level1.memory-chip');
BuildingDescriptions.set(Level1PurpleBuilding2, 'buildings.description.level1.metal-flower');
BuildingDescriptions.set(Level1PurpleBuilding3, 'buildings.description.level1.construction-token');
BuildingDescriptions.set(Level1PurpleBuilding4, 'buildings.description.level1.move-dunaia');


export { BuildingViewer };


