/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { ArtefactPart } from '../artefact/ArtefactPart';
import { Animation, useAnimation } from '@gamepark/react-client';
import { isGainArtefactPart } from '@gamepark/dunaia/utils/is-move.utils';
import { GainArtefactPart } from '@gamepark/dunaia/moves/GainArtefactPart';
import {
  gainArtefactPartTranslateX,
  gainArtefactPartTranslateY,
  mainBoardArtefactPartLeft,
  mainBoardArtefactPartRotateZ,
  mainBoardArtefactPartTop,
  playerArtefactPartHeight,
  playerArtefactPartWidth,
} from '../../styles/Style';
import { Player } from '@gamepark/dunaia/player/Player';
import last from 'lodash/last';

export type MainBoardArtefactsProps = {
  activePlayer: Player;
  activePlayerPanelIndex: number;
} & HTMLAttributes<HTMLDivElement>;

const MainBoardArtefacts: FC<MainBoardArtefactsProps> = (props) => {
  const { activePlayerPanelIndex, activePlayer } = props;
  const animation = useAnimation<GainArtefactPart>((a) => isGainArtefactPart(a.move));
  const lastArtefact = last(activePlayer.artefacts) || [];
  return (
    <>
      <ArtefactPart part={0} css={[artefactPart, firstArtefactPart(0)]} />
      <ArtefactPart part={0} css={[artefactPart, firstArtefactPart(0.5)]} />
      <ArtefactPart part={0} css={[artefactPart, firstArtefactPart(1)]} />
      <ArtefactPart part={0} css={[artefactPart, firstArtefactPart(1.5)]} />
      <ArtefactPart part={0} css={[artefactPart, firstArtefactPart(2)]} />
      <ArtefactPart part={0} css={[artefactPart, firstArtefactPart(2.5)]} />
      {animation && animation.move.elderPower === 0 && (
        <ArtefactPart
          part={0}
          css={[
            artefactPart,
            firstArtefactPart(2.5),
            !!animation &&
              animation.move.elderPower === 0 &&
              animatePartToPlayerPanel(animation, lastArtefact, activePlayerPanelIndex),
          ]}
        />
      )}
      <ArtefactPart part={1} css={[artefactPart, secondArtefactPart(0)]} />
      <ArtefactPart part={1} css={[artefactPart, secondArtefactPart(0.5)]} />
      <ArtefactPart part={1} css={[artefactPart, secondArtefactPart(1)]} />
      <ArtefactPart part={1} css={[artefactPart, secondArtefactPart(1.5)]} />
      <ArtefactPart part={1} css={[artefactPart, secondArtefactPart(2)]} />
      <ArtefactPart part={1} css={[artefactPart, secondArtefactPart(2.5)]} />
      {animation && animation.move.elderPower === 1 && (
        <ArtefactPart
          part={1}
          css={[
            artefactPart,
            secondArtefactPart(2.5),
            !!animation &&
              animation.move.elderPower === 1 &&
              animatePartToPlayerPanel(animation, lastArtefact, activePlayerPanelIndex),
          ]}
        />
      )}
      <ArtefactPart part={2} css={[artefactPart, thirdArtefactPart(0)]} />
      <ArtefactPart part={2} css={[artefactPart, thirdArtefactPart(0.5)]} />
      <ArtefactPart part={2} css={[artefactPart, thirdArtefactPart(1)]} />
      <ArtefactPart part={2} css={[artefactPart, thirdArtefactPart(1.5)]} />
      <ArtefactPart part={2} css={[artefactPart, thirdArtefactPart(2)]} />
      <ArtefactPart part={2} css={[artefactPart, thirdArtefactPart(2.5)]} />
      {animation && animation.move.elderPower === 2 && (
        <ArtefactPart
          part={2}
          css={[
            artefactPart,
            thirdArtefactPart(2.5),
            !!animation &&
              animation.move.elderPower === 2 &&
              animatePartToPlayerPanel(animation, lastArtefact, activePlayerPanelIndex),
          ]}
        />
      )}
    </>
  );
};

const artefactPart = css`
  filter: drop-shadow(0 0 0 black);
`;

const firstArtefactPart = (zIndex: number) => css`
  position: absolute;
  top: ${mainBoardArtefactPartTop(0)}em;
  left: ${mainBoardArtefactPartLeft}em;
  transform: rotateZ(${mainBoardArtefactPartRotateZ(0)}deg) translateZ(${zIndex}em);
`;

const secondArtefactPart = (zIndex: number) => css`
  position: absolute;
  top: ${mainBoardArtefactPartTop(1)}em;
  left: ${mainBoardArtefactPartLeft}em;
  transform: rotateZ(${mainBoardArtefactPartRotateZ(1)}deg) translateZ(${zIndex}em);
`;

const thirdArtefactPart = (zIndex: number) => css`
  position: absolute;
  top: ${mainBoardArtefactPartTop(2)}em;
  left: ${mainBoardArtefactPartLeft}em;
  transform: rotateZ(${mainBoardArtefactPartRotateZ(2)}deg) translateZ(${zIndex}em);
`;

const artefactPartToPlayerPanelAnimation = (move: GainArtefactPart, activePlayerPanelIndex: number) => {
  const translateX = gainArtefactPartTranslateX(activePlayerPanelIndex, move.elderPower);
  const translateY = gainArtefactPartTranslateY(activePlayerPanelIndex, move.elderPower);
  return keyframes`
    50% {
      transform:  translate(${translateX / 2}em, ${translateY / 2}em) translateZ(9em) rotateZ(${
    mainBoardArtefactPartRotateZ(move.elderPower) / 2
  }deg);
    }
    90% {
      height: ${playerArtefactPartHeight(move.elderPower)}em;
      width: ${playerArtefactPartWidth(move.elderPower)}em;
      transform: translate(${translateX * 0.9}em, ${translateY * 0.9}em) translateZ(4em);
    }
    to {
      height: ${playerArtefactPartHeight(move.elderPower)}em;
      width: ${playerArtefactPartWidth(move.elderPower)}em;
      transform: translate(${translateX}em, ${translateY}em);
    }
  `;
};

const animatePartToPlayerPanel = (animation: Animation<GainArtefactPart>, lastArtefact: number[], activePlayerPanelIndex: number) => {
  const duration = lastArtefact?.length === 2? animation.duration * (2 / 3): animation.duration
  return css`
    animation: ${ artefactPartToPlayerPanelAnimation(animation.move, activePlayerPanelIndex) } ${ duration }s linear;
      ${lastArtefact?.length < 2? 'both': ''};
  `;
};

export { MainBoardArtefacts };
