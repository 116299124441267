import MoveType from './MoveType';
import { getBuildingEffectsOrDefaults, getBuildingForPosition, sanitizeEffects } from '../utils/building.utils';
import { DunaiaPositionBuildings } from '../default/DunaiaPositionBuildings';
import GameState from '../GameState';
import GameView from '../GameView';
import { Building } from '../material/building/Building';
import { GameEffects } from '../effect/GameEffect';
import { Phase } from '../common/Phase';
import { addAndReorganizeEffects } from '../utils/pending-effect.utils';

export type AwakeDunaia = {
  type: MoveType.AwakeDunaia;
  dunaia: number;
  built: number[];
};

export const awakeDunaiaMove = (dunaia: number, built: number[] = []): AwakeDunaia => ({
  type: MoveType.AwakeDunaia,
  dunaia,
  built,
});

export const awakeDunaia = (
  state: GameState | GameView,
  move: AwakeDunaia,
  buildings: Building[],
  defaultBoardEffects: GameEffects[][]
) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  const dunaiaPositionBuildings = DunaiaPositionBuildings[player.dunaias[move.dunaia]];
  const playerBuildings = dunaiaPositionBuildings
    .map((position) => ({
      position: position,
      building: getBuildingForPosition(player, position),
    }))
    .filter((b) => !b.building || !move.built?.includes(b.building.building));

  const effects = sanitizeEffects(
    playerBuildings.flatMap(({ position, building }) =>
      getBuildingEffectsOrDefaults(player, position, buildings, defaultBoardEffects, building)
    ),
    player
  );

  player.phase = Phase.RecyclingDie;
  addAndReorganizeEffects(player, effects);
};
