/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getArtefactScoring, getCompletedArtefacts } from '@gamepark/dunaia/utils/is-over.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import {
  artefactPartLeft,
  artefactPartTop,
  playerArtefactPartHeight,
  playerArtefactPartWidth,
} from '../../../../styles/Style';
import { ArtefactPart } from '../../../artefact/ArtefactPart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { ArtefactHelpModal } from '../../../../help/ArtefactHelpModal';

type ArtefactScoreProps = {
  player: Player;
};

const ArtefactScore: FC<ArtefactScoreProps> = (props) => {
  const { player } = props;
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState(false);

  const displayHelp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHelp(true)
  }


  const artefactScoring = getArtefactScoring(player.artefacts)
  return (
    <div css={subtitle}>
      <span>{t('scoring.artefact')}
        <FontAwesomeIcon css={helpIcon} icon={faQuestionCircle} onClick={displayHelp} />
      </span>
      <div css={visuals}>
        <div css={artefactContainer}>
          <ArtefactPart css={artefactPart(0)} part={0} />
          <ArtefactPart css={artefactPart(1)} part={1} />
          <ArtefactPart css={artefactPart(2)} part={2} />
        </div>
        <span> x { getCompletedArtefacts(player.artefacts).length }</span>
      </div>
      <div css={score}>
        <span>&nbsp; { artefactScoring }</span>
      </div>
      { showHelp && <ArtefactHelpModal onClose={() => setShowHelp(false)} /> }
    </div>
  );
};

const helpIcon = css`
  margin-left: 0.5em;
  cursor: help;  
`;

const subtitle = css`
  grid-column: span 24;
  padding: 1em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border: 0.06em solid gray;
  height: 10em;

  > span {
    font-size: 4em;
    text-align: left;
    flex: 2;
  }
`;
const visuals = css`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 3em;
    margin-left: 0.5em;
  }
`

const score = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    left: 1em;
    font-size: 4em;
  }
`

const artefactContainer = css`
  position: relative;
  top: 0;
  height: 8.5em;
  width: 8.5em;
`

const artefactPart = (part: number) => css`
  position: absolute;
  top: ${artefactPartTop(part)}em;
  left: ${artefactPartLeft(part)}em;
  height: ${playerArtefactPartHeight(part)}em;
  width: ${playerArtefactPartWidth(part)}em;
`;

export { ArtefactScore };
