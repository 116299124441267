import MoveType from './MoveType';
import GameState from '../GameState';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';

export type MoveDunaia = {
  type: MoveType.MoveDunaia;
  dunaia: number;
  position: number;
};

export const moveDunaiaMove = (dunaia: number, position: number): MoveDunaia => ({
  type: MoveType.MoveDunaia,
  dunaia,
  position
});

export const moveDunaia = (state: GameState, move: MoveDunaia) => {
  const player = state.players.find(p => p.color === state.activePlayer)!;
  player.dunaias[move.dunaia] = move.position;

  shiftFirstEffectOfType(player.pending, GameEffectType.MoveDunaia);
};

