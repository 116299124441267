enum Direction {
  Top = 1,
  Right,
  Bottom,
  Left
}

export {
  Direction
};
