/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player } from '@gamepark/dunaia/player/Player';
import { FC, MouseEvent } from 'react';
import { ProphecyTile } from '../prophecy/ProphecyTile';
import {
  playerProphecyLeft,
  playerProphecyReducerHeight,
  playerProphecyReducerWidth, prophecyTileReducedLeft,
  playerProphecyTop
} from '../../styles/Style';
import { usePlay } from '@gamepark/react-client';
import { selectProphecyMove } from '../../moves/SelectProphecy';

type PlayerPropheciesProps = {
  player: Player;
}

const PlayerProphecies: FC<PlayerPropheciesProps> = (props) => {
  const play = usePlay();
  const { player } = props;
  const { prophecies } = player;

  const selectProphecy = (e: MouseEvent<HTMLDivElement>, prophecy: number) => {
    e.preventDefault();
    e.stopPropagation();
    play(selectProphecyMove(prophecy), { local: true });
  }

  return (
    <>
      {prophecies.map((p, index) => (
        <div key={p} css={prophecyReducer(index)}>
          <ProphecyTile prophecy={p} css={prophecyStyle} onClick={(e) => selectProphecy(e, p)}/>
        </div>
      ))}
    </>
  )
}

const prophecyStyle = css`
  position: absolute;
  left: ${prophecyTileReducedLeft}em;
  top: 0; 
`;

const prophecyReducer = (index: number) => css`
  position: absolute;
  overflow: hidden;

  border-radius: 0.5em;
  box-shadow: 0 0 0.5em 0 black;
  transform: scale(1.3);
  height: ${playerProphecyReducerHeight}em;
  width: ${playerProphecyReducerWidth}em;
  left: ${playerProphecyLeft}em;
  top: ${playerProphecyTop(index)}em;
`;

export {
  PlayerProphecies
}
