import Move from '../../moves/Move';
import { Player } from '../../player/Player';
import GameState from '../../GameState';
import Color, { colors } from '../../color/Color';
import { placeColorTokenMove } from '../../moves/PlaceColorToken';
import { canHaveNewColor, getBuiltBuildings, hasColor } from '../../utils/building.utils';
import { GameEffectRules } from './GameEffectRules';
import { PlaceColorTokenEffect } from '../PlaceColorTokenEffect';
import { Buildings } from '../../material/building/Buildings';

class PlaceColorTokenEffectRules extends GameEffectRules<PlaceColorTokenEffect> {
  constructor(effect: PlaceColorTokenEffect) {
    super(effect);

  }

  legalMoves = (_state: GameState, player: Player): Move[] => {
    return getBuiltBuildings(player).flatMap((b: any) => {
      const buildingColor = Buildings[b!.building].color;

      if (!buildingColor) {
        return []
      }

      const possibleColors = colors.filter(c => !hasColor(b, Buildings, c))
      return possibleColors.map((c: Color) => placeColorTokenMove(c, b!.building));
    });
  };

  isIgnored = (player: Player): boolean => !getBuiltBuildings(player).some((b) => canHaveNewColor(b, Buildings));
}

export { PlaceColorTokenEffectRules };
