/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '@gamepark/dunaia/color/Color';
import { FC, HTMLAttributes } from 'react';
import { Images } from '../../images/Images';
import { colorTokenSize } from '../../styles/Style';

type ColorTokenProps = {
  color: Color;
  outerCircleStyle?: any;
  scale?: number;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

const ColorToken: FC<ColorTokenProps> = (props) => {
  const { color, scale = 1, outerCircleStyle, ...rest } = props;
  return (
    <>
      <div css={[colorTokenStyle(color, scale), outerCircleStyle]} {...rest} />
      <div css={innerCircle(scale)} {...rest} />
    </>
  )
};

const colorTokenStyle = (color: any, scale: number = 1) => css`
  position: absolute;
  height: ${colorTokenSize * scale}em;
  width: ${colorTokenSize * scale}em;
  background-size: cover;
  background-image: url(${ColorTokenImage.get(color)});
  border-radius: 50%;
  border: 0.3em solid white;
`;


const innerCircle = (scale: number = 1) => css`
  height: ${colorTokenSize * 0.5 * scale}em;
  width: ${colorTokenSize * 0.5 * scale}em;
  border: 0.3em solid white;
  border-radius: 50%;
  transform: translate(50%, 50%);
  pointer-events: none;
`

export {
  ColorToken
}

const ColorTokenImage = new Map<Color, any>();
ColorTokenImage.set(Color.Yellow, Images.YellowColorToken);
ColorTokenImage.set(Color.Red, Images.RedColorToken);
ColorTokenImage.set(Color.Purple, Images.PurpleColorToken);
ColorTokenImage.set(Color.Green, Images.GreenColorToken);


