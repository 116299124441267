import MoveType from './MoveType';
import GameState from '../GameState';
import { ElderPowers } from '../material/power/ElderPowers';
import { ElderPowerMemoryChips } from '../material/board/ElderPowerMemoryChips';
import { canGetArtefactPart, getCallElderMemoryChips } from '../utils/elder-power.utils';
import { GainArtefactPartEffect } from '../effect/GainArtefactPartEffect';
import { addAndReorganizeEffects, getEffectRule } from '../utils/pending-effect.utils';

export type CallElder = {
  type: MoveType.CallElder;
  elderPowerIndex: number;
  memoryChips: number[];
};

export const callElderMove = (elderPowerIndex: number, memoryChips: number[]): CallElder => ({
  type: MoveType.CallElder,
  elderPowerIndex,
  memoryChips,
});

export const callElder = (state: GameState, move: CallElder) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  if (state.elderPowers[move.elderPowerIndex] === undefined) {
    throw new Error('You are trying to activate an elder power not active in this game.');
  }

  const memoryChips = getCallElderMemoryChips(move.elderPowerIndex, move.memoryChips, ElderPowerMemoryChips);
  if (memoryChips.some((c) => !player.memoryChips.includes(c))) {
    throw new Error('Some needed memory chips are missing to activate elder power ');
  }

  player.memoryChips = player.memoryChips.filter((m) => !memoryChips.includes(m));

  if (canGetArtefactPart(player, move.elderPowerIndex)) {
    addAndReorganizeEffects(player, [new GainArtefactPartEffect(move.elderPowerIndex)]);
  }

  const effect = ElderPowers[state.elderPowers[move.elderPowerIndex]].effect;
  const rule = getEffectRule(effect);
  if (!rule.isIgnored?.(player, state)) {
    addAndReorganizeEffects(player, [effect]);
  }
};
