/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { usePlay } from '@gamepark/react-client';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Prophecy } from '@gamepark/dunaia/material/prophecy/Prophecy';
import { ProphecyTile } from './ProphecyTile';
import {
  Prophecies,
  Prophecy1,
  Prophecy2,
  Prophecy3,
  Prophecy4,
  Prophecy5,
  Prophecy6,
  Prophecy7,
  Prophecy8,
  Prophecy9
} from '@gamepark/dunaia/material/prophecy/Prophecies';
import { selectProphecyMove } from '../../moves/SelectProphecy';
import { TFunction } from 'i18next';

type ProphecyViewerProps = {
  prophecy: number;
};

const getProphecyDescription = (prophecy: Prophecy, t: TFunction) => {
  switch (prophecy) {
    case Prophecy1:
      return t(ProphecyDescriptions.get(Prophecy1)!, { points: Prophecy1.score, buildings: 6 });
    case Prophecy2:
      return t(ProphecyDescriptions.get(Prophecy2)!, { points: Prophecy2.score, buildings: 7 });
    case Prophecy3:
      return t(ProphecyDescriptions.get(Prophecy3)!, { points: Prophecy3.score, buildings: 8 });
    default:
      return t(ProphecyDescriptions.get(prophecy)!, { points: prophecy.score })
  }
}

const prophecyScale = 2;
const ProphecyViewer: FC<ProphecyViewerProps> = (props) => {
  const play = usePlay();
  const { t } = useTranslation();
  const { prophecy: prophecyNumber } = props;
  const prophecy = Prophecies[prophecyNumber];
  const closeChooser = () => play(selectProphecyMove(), { local: true });


  const viewver = (
    <>
      <div css={overlay} onClick={closeChooser} />
      <div css={actionStyle} onClick={closeChooser}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div css={prophecyDescription}><span>{getProphecyDescription(prophecy, t)}</span></div>
      <ProphecyTile prophecy={prophecyNumber} css={prophecyPosition} scale={prophecyScale} />
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(viewver, letterBox);
  }

  return null;
};

const actionStyle = css`
  border-radius: 50%;
  background-color: white;
  padding: 0.5em;
  height: 7em;
  width: 7em;
  position: absolute;
  top: 15em;
  right: 20em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 4em;
  }
`;

const overlay = css`
  position: absolute;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: rgba(0, 0, 0, 0.77);
  cursor: pointer;
  transform: translateZ(50em);
`;

const prophecyPosition = css`
  transition: top 0.5s;
  position: absolute;
  top: 15em;
  left: 50%;
  transform: translateX(-50%) translateZ(50em);
  transform-style: preserve-3d;
`;

const prophecyDescription = css`
  position: absolute;
  top: 58%;
  padding: 2em;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 50%;
  border-radius: 2em;
  transform: translate(-50%, -50%) translateZ(50em);  
  > span {
    font-size: 4em;
    word-break: break-word;
  }
`;

const ProphecyDescriptions = new Map<Prophecy, string>();
ProphecyDescriptions.set(Prophecy1, 'prophecy.description.buildings');
ProphecyDescriptions.set(Prophecy2, 'prophecy.description.buildings');
ProphecyDescriptions.set(Prophecy3, 'prophecy.description.buildings');
ProphecyDescriptions.set(Prophecy4, 'prophecy.description.metal-flower');
ProphecyDescriptions.set(Prophecy5, 'prophecy.description.two-artefacts');
ProphecyDescriptions.set(Prophecy6, 'prophecy.description.metal-flower-and-artifact');
ProphecyDescriptions.set(Prophecy7, 'prophecy.description.buildings-corner');
ProphecyDescriptions.set(Prophecy8, 'prophecy.description.buildings-aligned');
ProphecyDescriptions.set(Prophecy9, 'prophecy.description.buildings-level');


export { ProphecyViewer };


