/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { MetalFlowerScore } from './components/MetalFlowerScore';
import { TotalScore } from './components/TotalScore';
import { usePlayer } from '@gamepark/react-client';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Player } from '@gamepark/dunaia/player/Player';
import { getPlayerName } from '@gamepark/dunaia/DunaiaOptions';
import { modalOverlay } from '../../../styles/Style';
import { createPortal } from 'react-dom';
import { ArtefactScore } from './components/ArtefactScore';
import { BuildingScore } from './components/BuildingScore';
import { ProphecyScore } from './components/ProphecyScore';
import { PlayerAvatar } from '../PlayerAvatar';

type ScoreDetailProps = {
  player?: Player;
  onClose: () => void;
};

const ScoreDetail: FC<ScoreDetailProps> = (props) => {
  const { onClose, player } = props;
  const { t } = useTranslation();
  const playerInfos = usePlayer(player?.color);

  if (!player) {
    return null;
  }

  const noop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const portal = (
    <>
      <div
        key='overlay-viewer'
        css={[
          modalOverlay
        ]}
        onClick={onClose}
      />
      <div
        key="score-detail-modal"
        css={modalStyle}
        onClick={noop}
      >
        <div css={header}>
          <PlayerAvatar player={player} css={avatar} />
          <h1>{t('scoring.header', { player: playerInfos?.name || getPlayerName(player.color, t) })}</h1>
        </div>
        <div css={closeModal} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
          <div css={scoreModalDetailed}>
            <MetalFlowerScore player={player} />
            <ArtefactScore player={player} />
            <ProphecyScore player={player} />
            <BuildingScore player={player} />
            <TotalScore player={player} />
          </div>
      </div>
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  return createPortal(portal, letterBox);
};

const avatar = css`
  position: relative;
`


const modalStyle = css`
  transition: opacity 0.5s;
  position: absolute;
  width: 100em;
  height: 65em;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  margin-left: 0;
  background: white;
  border: 0.2em solid black;
  box-shadow: 0 0 1em white;
  border-radius: 3em;
  padding: 2em;
  color: black;
`;

const closeModal = css`
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  padding: 0.3em 0.5em;
  font-size: 7em;
  color: black !important;
  cursor: pointer;

  &:hover {
    color: grey !important;
  }
`;

const scoreModalDetailed = css`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(24, 1fr);
  row-gap: 2em;
  max-height: 90%;
`;

const header = css`
  > h1 {
    margin: 0 0 0 0.4em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    font-size: 4em;
  }    
  
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;
`;

export { ScoreDetail };
