import { Animations } from '@gamepark/react-client';
import GameView from '@gamepark/dunaia/GameView';
import MoveView from '@gamepark/dunaia/moves/MoveView';
import Color from '@gamepark/dunaia/color/Color';
import AnimationContext from '@gamepark/react-client/dist/animations/AnimationContext';
import MoveType from '@gamepark/dunaia/moves/MoveType';
import { AddConstructionTokenDuration, BuildBuildingDuration, BuyBuildingDuration, BuyBuildingRefillDuration, RemoveConstructionTokenDuration } from './AnimationConstants';
import { Buildings } from '@gamepark/dunaia/material/building/Buildings';
import { isPlayedDisplayed } from '@gamepark/dunaia/utils/player.utils';
import { getBuildingColumn } from '@gamepark/dunaia/utils/building.utils';
import last from 'lodash/last';

export default class DunaiaAnimation extends Animations<GameView, MoveView, Color> {
  getPreDuration(move: MoveView, context: AnimationContext<GameView, MoveView, Color>) {
    switch (move.type) {
      case MoveType.ChooseDice:
        return 2;
      case MoveType.MoveDunaia:
        return 1;
      case MoveType.RecycleDice:
        if (move.building !== undefined) {
          const playerColor = context.state.players.find((p) =>
            p.buildings.flat().some((b) => b?.building === move.building)
          )!.color;

          if (
            !isPlayedDisplayed(context.state.displayedPlayer, context.state.activePlayer, context.playerId, playerColor)
          ) {
            return 0;
          }

          return 2;
        }
        return 2;
      case MoveType.RetrieveProphecy:
        return 2;
      case MoveType.GainMetalFlowers:
        break;
      case MoveType.GainMemoryChips:
        return 2;
      case MoveType.BuyBuilding:
        if (getBuildingColumn(context.state.buildings, move.building) === 0) {
          return BuyBuildingDuration + AddConstructionTokenDuration;
        }
        return BuyBuildingDuration + BuyBuildingRefillDuration + AddConstructionTokenDuration;
      case MoveType.MoveConstructionToken:
        if (!isPlayedDisplayed(context.state.displayedPlayer, context.state.activePlayer, context.playerId)) {
          return 0;
        }

        const building = context.state.players
          .flatMap((p) => p.buildings.flat())
          .find((b) => b?.building === move.building);
        return building!.constructionToken! + 1 === Buildings[move.building].constructionSteps
          ? BuildBuildingDuration + RemoveConstructionTokenDuration
          : 0;
      case MoveType.PlaceColorToken:
        break;
      case MoveType.GainArtefactPart:
        const parts = context.state.players.find(p => context.state.activePlayer === p.color)?.artefacts!;
        return (last(parts) || []).length === 2? 3: 2;
      case MoveType.AwakeDunaia:
        break;
      case MoveType.RollDice:
        return 1;
    }

    return 0;
  }
};

export { DunaiaAnimation };
