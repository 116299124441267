/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Color from '@gamepark/dunaia/color/Color';
import { FC, HTMLAttributes, useState } from 'react';
import { Dunaia } from '../dunaia/Dunaia';
import { Player } from '@gamepark/dunaia/player/Player';
import { DunaiaHelpModal } from '../../help/DunaiaHelpModal';
import { GameEffectType } from '@gamepark/dunaia/effect/GameEffect';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import { DunaiaButton } from '../../button/DunaiaButton';
import { selectDunaiaMove } from '../../moves/SelectDunaia';

export type PlayerDunaiasProps = {
  dunaias: number[];
  player: Player;
  activeColor: Color;
  selectedDunaia?: number;
  zoomed: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

const PlayerDunaias: FC<PlayerDunaiasProps> = (props) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { zoomed, dunaias, player, activeColor, selectedDunaia } = props;
  const [dunaiaHelp, setDunaiaHelp] = useState<number>();
  const moveEffect = player.pending.find((p) => p.type === GameEffectType.MoveDunaia);
  const canMoveDunaia = activeColor === playerId && activeColor === player.color && !!moveEffect;

  const chooseDunaia = (dunaia: number) => {
    if (!canMoveDunaia) {
      return;
    }

    play(selectDunaiaMove(selectedDunaia === dunaia? undefined: dunaia), { local: true });
    setDunaiaHelp(undefined);
  };


  const chooseDunaiaButton = canMoveDunaia && dunaiaHelp !== undefined && (
    <DunaiaButton css={chooseDunaiaStyle} labelKey='button.move-dunaia' height={7} highlighted onClick={() => chooseDunaia(dunaiaHelp)} />
  );
  return (
    <>
      {dunaias.map((position, index) => (
        <Dunaia
          key={index}
          dunaia={index}
          position={position}
          activeColor={activeColor}
          selectedDunaia={selectedDunaia}
          player={player}
          zoomed={zoomed}
          onClick={() => setDunaiaHelp(index)}
          canBeMoved={canMoveDunaia}
        />
      ))}
      {dunaiaHelp !== undefined && (
        <DunaiaHelpModal
          onClose={() => setDunaiaHelp(undefined)}
          color={player.color}
          dunaia={dunaiaHelp}
          button={chooseDunaiaButton}
        />
      )}
    </>
  );
};

const chooseDunaiaStyle = css`
  margin-top: 3em;
  
  &:hover {
    
  }
`

export { PlayerDunaias };
