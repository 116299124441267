import GameState from '../GameState';
import Move from '../moves/Move';
import { ElderPowerMemoryChips } from '../material/board/ElderPowerMemoryChips';
import { canCallElder } from './elder-power.utils';
import { callElderMove } from '../moves/CallElder';
import { getCombinations, getRecyclingBuildings } from './building.utils';
import { ElderPower } from '../material/power/ElderPower';
import { chooseDiceMove } from '../moves/ChooseDice';
import { recycleDiceOnBoardMove, recycleDiceOnBuildingMove } from '../moves/RecycleDice';
import { Building } from '../material/building/Building';
import { endTurnMove } from '../moves/EndTurn';
import { getNonRecycledDie, getPlayableDice } from './dice.utils';
import { getBoardActions } from './board.utils';
import { getPlayerPendingEffectRules } from './pending-effect.utils';
import { Phase } from '../common/Phase';

/**
 * Get all moves allowed for the user at the moment of the game
 * @param state The game state
 * @param elderPowers The elder powers memory chips
 */
class LegalMovesUtils {
  private readonly player;

  constructor(
    private readonly state: GameState,
    private readonly elderPowers: ElderPower[],
    private readonly buildings: Building[]
  ) {
    this.player = state.players.find((p) => p.color === state.activePlayer)!;
  }

  build = (): Move[] => {
    const moves: Move[] = [];

    if (this.player.pending.length) {
      getPlayerPendingEffectRules(this.player)
        .flatMap((effect) => (effect.legalMoves ? effect.legalMoves(this.state, this.player) : []))
        .forEach((move) => moves.push(move));

      return moves;
    } else {
      Array.from(this.state.elderPowers.entries())
        .filter((entry) => canCallElder(this.player, entry[0], this.elderPowers[entry[1]]))
        .forEach((entry) => {
          if (!this.elderPowers[entry[1]].memoryChips) {
            moves.push(callElderMove(entry[0], []));
          } else {
            getCombinations(
              this.player.memoryChips.filter((m) => !ElderPowerMemoryChips[entry[0]].includes(m)),
              this.elderPowers[entry[1]].memoryChips || 0
            ).forEach((memoryChips) => moves.push(callElderMove(entry[0], memoryChips)));
          }
        });
    }

    if (this.player.phase === Phase.ChoosingDie) {
      moves.push(...this.atChoosingDiePhase());
      return moves;
    } else if (!!getNonRecycledDie(this.player.dice)) {
      moves.push(...this.atDiceRecyclingPhase());
      return moves;
    }

    return [endTurnMove, ...moves];
  };

  /**
   * Compute the list of allowed moves at the choosing die phase
   * @return moves allowed moves
   */
  atChoosingDiePhase = (): Move[] => {
    const activePlayer = this.state.players.find((p) => p.color === this.state.activePlayer)!;
    return getPlayableDice(this.state.dice, this.player.color, activePlayer).map((playerDice) =>
      chooseDiceMove(playerDice)
    );
  };

  /**
   * Compute the list of allowed moves at the dice recycling phase
   */
  atDiceRecyclingPhase = (): Move[] => {
    const moves: Move[] = [];

    getBoardActions(this.state, this.player).forEach((action) => moves.push(recycleDiceOnBoardMove(action)));

    // Player must be filtered if the current player has no flowers (you must pay one flower if you use other player building)
    const players = this.player.metalFlowers > 0 ? this.state.players: this.state.players.filter((p) => p.color === this.state.activePlayer);
    getRecyclingBuildings(players, this.buildings).map((b) => moves.push(recycleDiceOnBuildingMove(b)));

    return moves;
  };
}

export { LegalMovesUtils };
