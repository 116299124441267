import { ElderPower } from './ElderPower';
import { GainMetalFlowerEffect } from '../../effect/GainMetalFlowerEffect';
import { MoveDunaiaEffect } from '../../effect/MoveDunaiaEffect';
import { MoveConstructionTokenEffect } from '../../effect/MoveConstructionTokenEffect';
import { BuyBuildingEffect } from '../../effect/BuyBuildingEffect';
import { PlaceColorTokenEffect } from '../../effect/PlaceColorTokenEffect';

export const MetalFlowers: ElderPower = {
  effect: new GainMetalFlowerEffect(2),
};

export const MoveDunaia: ElderPower = {
  effect: new MoveDunaiaEffect(2)
};

export const MoveConstructionToken: ElderPower = {
  effect: new MoveConstructionTokenEffect(),
  memoryChips: 1
};

export const BuyBuilding: ElderPower = {
  effect: new BuyBuildingEffect(true),
  memoryChips: 2
};

export const PlaceColorToken: ElderPower = {
  effect: new PlaceColorTokenEffect(),
  memoryChips: 1
}

export const ElderPowers: ElderPower[] = [
  MetalFlowers,
  MoveDunaia,
  MoveConstructionToken,
  BuyBuilding,
  PlaceColorToken
];
