import Color from '../color/Color';

export const isPlayedDisplayed = (
  displayedColor: Color | undefined,
  activeColor: Color,
  playerId?: Color,
  comparedColor?: Color
) => {
  return (comparedColor || activeColor) === (displayedColor || playerId || activeColor);
};
