import { DunaiaOptions, DunaiaPlayerOptions } from '../DunaiaOptions';
import GameState from '../GameState';
import { Player } from '../player/Player';
import shuffle from 'lodash/shuffle';
import { ElderPowers } from '../material/power/ElderPowers';
import sample from 'lodash/sample';
import { Prophecies } from '../material/prophecy/Prophecies';
import { ProphecyColor } from '../material/prophecy/Prophecy';
import { rollGameDices } from '../utils/dice.utils';
import { Buildings } from '../material/building/Buildings';
import { Phase } from '../common/Phase';

/**
 * Game stat initializer
 */
class GameInitializer {
  private opts: DunaiaOptions;

  constructor(opts: DunaiaOptions) {
    this.opts = opts;
  }

  buildState (): GameState {
    const players = this.buildPlayers(this.opts.players);
    const activePlayer = this.opts.players[0].id;
    players.find((p) => p.color === activePlayer)!.phase = Phase.ChoosingDie;
    return {
      activePlayer: activePlayer,
      buildings: this.drawBuildings(),
      dice: this.drawDice(),
      elderPowers: this.drawElderPowers(),
      prophecies: this.drawProphecies(),
      players: players,
      round: 0,
    };
  };

  buildPlayers (players: DunaiaPlayerOptions[]): Player[] {
    return players.map((p) => ({
      color: p.id,
      artefacts: [],
      metalFlowers: 0,
      memoryChips: [],
      prophecies: [],
      constructionTokens: 3,
      dunaias: [1, 4, 7, 10, 13, 16],
      buildings: Array(3).fill(Array(3)),
      dice: [],
      pending: [],
    }));
  };

  drawElderPowers() {
    return shuffle(Array.from(ElderPowers.entries()).map((e) => e[0])).slice(0, 3);
  };

  drawProphecies(): number[] {
    const prophecies = Array.from(Prophecies.entries());
    return [
      sample(prophecies.filter((p) => p[1].color === ProphecyColor.GREEN))![0],
      sample(prophecies.filter((p) => p[1].color === ProphecyColor.PURPLE))![0],
      sample(prophecies.filter((p) => p[1].color === ProphecyColor.ORANGE))![0],
    ];
  };

  drawDice() {
    return rollGameDices(this.opts.players.map((p) => p.id));
  }

  shuffleBuildings() {
    const buildings = Array.from(Buildings.entries());
    const level1Buildings = shuffle(buildings.filter((b) => b[1].level === 1)).map((b) => b[0]);
    const level2Buildings = shuffle(buildings.filter((b) => b[1].level === 2)).map((b) => b[0]);
    const level3Buildings = shuffle(buildings.filter((b) => b[1].level === 3)).map((b) => b[0]);

    return [
      level1Buildings,
      level2Buildings,
      level3Buildings,
    ]
  }

  drawBuildings() {
    const [level1Buildings, level2Buildings, level3Buildings] = this.shuffleBuildings();

    return [
      [level3Buildings.slice(1), level2Buildings.slice(1), level1Buildings.slice(1)],
      [[level3Buildings.shift()!], [level2Buildings.shift()!], [level1Buildings.shift()!]],
    ];
  };
}

export { GameInitializer };
