/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, useEffect, useState } from 'react';
import {
  playerMetalFlowerHeight,
  playerMetalFlowerLeft,
  playerMetalFlowerTop,
  playerMetalFlowerWidth,
} from "../../styles/Style";
import { MetalFlower } from "../metalflower/MetalFlower";
import { MetalFlowerHelpModal } from '../../help/MetalFlowerHelpModal';

export type PlayerMetalFlowerProps = {
  metalFlowers: number;
};

const PlayerMetalFlower: FC<PlayerMetalFlowerProps> = ({ metalFlowers }) => {
  const [displayedMetalFlowers, setDisplayedMetalFlowers] = useState(metalFlowers);
  const [scoreInterval, setScoreInterval] = useState<NodeJS.Timeout>();
  const [timingForEachTick, setTimingForEachTick] = useState(0);
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (metalFlowers - displayedMetalFlowers !== 0) {
      setTimingForEachTick(Math.min(2000, 150 * Math.abs(metalFlowers - displayedMetalFlowers)) / Math.abs(metalFlowers - displayedMetalFlowers));
    }

    // eslint-disable-next-line
  }, [metalFlowers]);

  useEffect(() => {
    if (scoreInterval || displayedMetalFlowers === metalFlowers) {
      return;
    }

    const interval = setInterval(() => {
      setDisplayedMetalFlowers((currentScore) => {
        if (currentScore === metalFlowers) {
          clearInterval(interval);
          setScoreInterval(undefined);
          setTimingForEachTick(0);
          return currentScore;
        }
        return metalFlowers - displayedMetalFlowers > 0 ? currentScore + 1 : currentScore - 1;
      });
    }, timingForEachTick);

    setScoreInterval(interval);

    // eslint-disable-next-line
  }, [timingForEachTick]);

  const displayHelp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHelp(true)
  }

  return (
    <>
      <div key="metal-flower" css={metalFlowerContainer} onClick={displayHelp}>
        <MetalFlower css={metalFlower} />
        <div css={metalFlowerCounter}> x {displayedMetalFlowers }</div>
      </div>
      {showHelp && <MetalFlowerHelpModal onClose={() => setShowHelp(false)} /> }
    </>
  );
};

const metalFlowerContainer = css`
  float: left;
  position: absolute;
  top: ${playerMetalFlowerTop}em;
  left: ${playerMetalFlowerLeft}em;
  cursor: help;
`;

const metalFlower = css`
  height: ${playerMetalFlowerHeight}em;
  width: ${playerMetalFlowerWidth}em;

  &:hover {
    filter: brightness(110%);
  }
`;

const metalFlowerCounter = css`
  position: absolute;
  white-space: nowrap;
  left: 1.6em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3em;
  color: black;
`;

export { PlayerMetalFlower };
