import MoveType from './MoveType';
import GameState from '../GameState';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';

export type GainArtefactPart = {
  type: MoveType.GainArtefactPart;
  elderPower: number;
};

export const gainArtefactPartMove = (elderPower: number): GainArtefactPart => ({
  type: MoveType.GainArtefactPart,
  elderPower,
});

export const gainArtefactPart = (state: GameState, move: GainArtefactPart) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;
  const lastArtefact = player.artefacts[player.artefacts.length - 1];

  if (lastArtefact && lastArtefact.length < 3 && lastArtefact.includes(move.elderPower)) {
    throw new Error('You are trying to get an artefact part but the current artefact has already this part.');
  }

  if (lastArtefact && lastArtefact.length < 3) {
    player.artefacts[player.artefacts.length - 1].push(move.elderPower);
  } else {
    player.artefacts.push([move.elderPower]);
  }

  shiftFirstEffectOfType(player.pending, GameEffectType.GainArtefactPart);
};
