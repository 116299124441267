import { Position } from '../common/Position';

export const DunaiaPositionBuildings: Position[][] = [
  [ { x: 0, y: 0 }, { x: 0, y: 1} ], // 0
  [ { x: 0, y: 0 }, { x: 0, y: 1} ], // 1
  [ { x: 0, y: 0 }, { x: 0, y: 1} ], // 2

  [ { x: 1, y: 0 }, { x: 1, y: 1} ], // 3
  [ { x: 1, y: 0 }, { x: 1, y: 1} ], // 4
  [ { x: 1, y: 0 }, { x: 1, y: 1} ], // 5

  [ { x: 2, y: 0 }, { x: 2, y: 1} ], // 6
  [ { x: 2, y: 0 }, { x: 2, y: 1} ], // 7
  [ { x: 2, y: 0 }, { x: 2, y: 1} ], // 8


  [ { x: 0, y: 1 }, { x: 0, y: 2} ], // 9
  [ { x: 0, y: 1 }, { x: 0, y: 2} ], // 10
  [ { x: 0, y: 1 }, { x: 0, y: 2} ], // 11

  [ { x: 1, y: 1 }, { x: 1, y: 2} ], // 12
  [ { x: 1, y: 1 }, { x: 1, y: 2} ], // 13
  [ { x: 1, y: 1 }, { x: 1, y: 2} ], // 14

  [ { x: 2, y: 1 }, { x: 2, y: 2} ], // 15
  [ { x: 2, y: 1 }, { x: 2, y: 2} ], // 16
  [ { x: 2, y: 1 }, { x: 2, y: 2} ], // 17


  [ { x: 0, y: 0 }, { x: 1, y: 0} ], // 18
  [ { x: 0, y: 0 }, { x: 1, y: 0} ], // 19
  [ { x: 0, y: 0 }, { x: 1, y: 0} ], // 20

  [ { x: 0, y: 1 }, { x: 1, y: 1} ], // 21
  [ { x: 0, y: 1 }, { x: 1, y: 1} ], // 22
  [ { x: 0, y: 1 }, { x: 1, y: 1} ], // 23

  [ { x: 0, y: 2 }, { x: 1, y: 2} ], // 24
  [ { x: 0, y: 2 }, { x: 1, y: 2} ], // 25
  [ { x: 0, y: 2 }, { x: 1, y: 2} ], // 26


  [ { x: 1, y: 0 }, { x: 2, y: 0} ], // 27
  [ { x: 1, y: 0 }, { x: 2, y: 0} ], // 28
  [ { x: 1, y: 0 }, { x: 2, y: 0} ], // 29

  [ { x: 1, y: 1 }, { x: 2, y: 1} ], // 30
  [ { x: 1, y: 1 }, { x: 2, y: 1} ], // 31
  [ { x: 1, y: 1 }, { x: 2, y: 1} ], // 32

  [ { x: 1, y: 2 }, { x: 2, y: 2} ], // 33
  [ { x: 1, y: 2 }, { x: 2, y: 2} ], // 34
  [ { x: 1, y: 2 }, { x: 2, y: 2} ], // 35
];
