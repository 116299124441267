import { BuyBuildingEffect } from '../BuyBuildingEffect';
import { Player } from '../../player/Player';
import GameState from '../../GameState';
import Move from '../../moves/Move';
import { getBuyableBuildings } from '../../utils/building.utils';
import { buyBuildingMove } from '../../moves/BuyBuilding';
import { GameEffectRules } from './GameEffectRules';

class BuyBuildingEffectRules extends GameEffectRules<BuyBuildingEffect> {

  constructor(effect: BuyBuildingEffect) {
    super(effect);
  }

  legalMoves(state: GameState, player: Player): Move[] {
    const availableBuildings: number[] = getBuyableBuildings(player.metalFlowers, state.buildings, this.effect.any);

    return player.buildings.flatMap((col, x) =>
      col.flatMap((playerBuilding, y) =>
        playerBuilding === null ? availableBuildings.map((b) => buyBuildingMove(b, { x, y })) : []
      )
    );
  }

  isIgnored(player: Player, state: GameState): boolean {
    const buyableBuildings = getBuyableBuildings(player.metalFlowers, state.buildings, this.effect.any);
    return !player.constructionTokens
      || !buyableBuildings.length
      || !player.buildings.flat().filter((b) => b === null || b === undefined).length;
  }

}

export { BuyBuildingEffectRules };
