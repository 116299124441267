/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { TutorialModal } from './TutorialModal';
import { useActions, useAnimations, Tutorial as TutorialClient } from '@gamepark/react-client';
import { Action } from '@gamepark/rules-api';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import GameState from '@gamepark/dunaia/GameState';
import sift from 'sift'
import { createPortal } from 'react-dom';

type Highlight = {
  css?: any;
}


type Step = {
  titleKey: string;
  messageKey: string;
  css?: any;
  highlights: Highlight[];
}

type ActionModal = {
  index: number;
  steps: Step[];
}

type ConditionalModal = {
  condition: any;
  steps: Step[];
}

export type Scenario = {
  actionModals?: ActionModal[];
  conditionalModals?: ConditionalModal[];
}

type TutorialProps = {
  tutorial: TutorialClient;
  scenario: Scenario;
  showTutorialButton: any;
  state: any;
}

const getActionStep = (actions: Action[], subStep: number, stepsByActions: {[key: number]: any}): Step | undefined => {
  return stepsByActions[actions.length]?.[subStep];
}

const getConditionalStep = (subStep: number, conditionedModal?: ConditionalModal) => {
  return (conditionedModal?.steps ?? [])[subStep]
}

const useSteps = (scenario: any) => useMemo(() => {
  return mapValues(groupBy(scenario?.actions ?? [], (s) => s.index), (steps: any) => {
    return steps.flatMap((s: any) => s.steps);
  });
}, [scenario])

const useConditionedModal = (scenario: any, state: GameState) => {
  return (scenario?.conditionals ?? []).find((modal: any) => sift(modal.condition)(state));
}

const getStep = (actions: Action[], subStep: number, stepsByActions: {[key: number]: any}, conditionedModal?: ConditionalModal) => {
  const actionStep = getActionStep(actions, subStep, stepsByActions);
  const conditionalStep = getConditionalStep(subStep, conditionedModal)

  return actionStep ?? conditionalStep;
}

const Tutorial: FC<TutorialProps> = (props) => {
  const { scenario, tutorial, state, showTutorialButton: ShowTutorial } = props;
  const [subStep, setSubStep] = useState(0);
  const stepsByActions = useSteps(scenario);
  const conditionedModal = useConditionedModal(scenario, state);

  const actions = useActions() ?? [];
  const animations = useAnimations();
  useEffect(() => tutorial.setOpponentsPlayAutomatically(true), [tutorial]);

  useEffect(() => {
    setSubStep(0);
  }, [actions.length])

  const onNext = () => {
    setSubStep((subStep) => subStep + 1);
  }

  const onPrevious = () => {
    setSubStep((subStep) => subStep - 1);
  }

  const step = getStep(actions, subStep, stepsByActions, conditionedModal);
  const highlights = step?.highlights ?? []

  if (!step || animations?.length) {
    if (getStep(actions, subStep - 1, stepsByActions, conditionedModal)) {
      const button = <ShowTutorial onClick={() => setSubStep(subStep => subStep - 1)} />
      let buttonContainer = document.getElementById('tutorial-button')!;
      if (buttonContainer) {
        return createPortal(button, buttonContainer);
      }
    }

    return null;
  }

  return (
    <>
      <TutorialModal
        key="modal"
        titleKey={step.titleKey}
        messageKey={step.messageKey}
        modalStyle={step.css}
        onNext={onNext}
        onPrevious={onPrevious}
        hasPrevious={subStep > 0}
      />
      { highlights.map((h, index) => <div key={`highlight-${index}`} css={[absoluteHighlights, h.css]} /> )}
    </>
  )
}

const absoluteHighlights = css`
  position: absolute;
  border: 0.5em solid red;
  pointer-events: none;
  transform: translateZ(40em);
  border-radius: 2em;
`

export {
  Tutorial
}
