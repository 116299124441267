import { DragObjectType } from './'

export type DiceFromMainBoard = {
  type: typeof DragObjectType.DiceFromMainBoard
  dice: number;
}

export const diceFromMainBoard = (dice: number): DiceFromMainBoard => ({
  type: DragObjectType.DiceFromMainBoard,
  dice
})
