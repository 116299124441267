/** @jsxImportSource @emotion/react */
import { Dice } from '@gamepark/dunaia/common/Dice';
import { FC, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { getPlayableDice } from '@gamepark/dunaia/utils/dice.utils';
import { usePlayerId } from '@gamepark/react-client';
import { Player } from '@gamepark/dunaia/player/Player';
import { MainBoardDice } from './MainBoardDice';

export type MainBoardDicesProps = {
  activePlayer: Player;
  activePlayerPanelIndex: number;
  dice: Dice[];
};

const MainBoardDices: FC<MainBoardDicesProps> = (props) => {
  const { dice, activePlayer, activePlayerPanelIndex } = props;
  const playerId = usePlayerId();
  const playableDice = useMemo(() => getPlayableDice(dice, playerId, activePlayer), [dice, playerId, activePlayer]);

  const diceByValue = useMemo(() => groupBy(Array.from(dice.entries()), '[1].value'), [dice]);
  const isPlayableDice = (dice: any) => playableDice.includes(dice[0]);
  return (
    <>
      {Object.keys(diceByValue).flatMap((key) =>
        diceByValue[key].map((d, row) => (
          <MainBoardDice
            key={`${key}-${diceByValue[key].length - row - 1}`}
            index={d[0]}
            row={diceByValue[key].length - row - 1}
            rowCount={diceByValue[key].length}
            column={parseInt(key) - 1}
            playable={isPlayableDice(d)}
            dice={d[1]}
            activePlayerPanelIndex={activePlayerPanelIndex}
            activeColor={activePlayer.color}
          />
        ))
      )}
    </>
  );
};

export { MainBoardDices };
