import GameView from '@gamepark/dunaia/GameView';

export type SelectElderPower = {
  type: 'SelectElderPower';
  elderPowerIndex?: number;
};

export const selectElderPowerMove = (elderPowerIndex?: number): SelectElderPower => ({
  type: 'SelectElderPower',
  elderPowerIndex,
});

export function selectElderPower(state: GameView, move: SelectElderPower) {
  state.selectedElderPower = move.elderPowerIndex;
}
