/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player } from '@gamepark/dunaia/player/Player';
import { FC } from 'react';
import { PlayerPanel } from './PlayerPanel';
import { playerPanelHeight, playerPanelLeft, playerPanelTop, playerPanelWidth } from '../../styles/Style';
import Color from '@gamepark/dunaia/color/Color';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';

export type PlayerPanelsProps = {
  sortedPlayers: Array<Player>;
  activeColor: Color;
  displayedColor: Color;
  diceRecycledOnBoard: PlayerDice[];
  gameOver: boolean
};

const PlayerPanels: FC<PlayerPanelsProps> = (props) => {
  const { sortedPlayers, activeColor, displayedColor, diceRecycledOnBoard, gameOver } = props;
  return (
    <>
      {sortedPlayers.map((p, index) => (
        <PlayerPanel
          key={p.color}
          css={playerPanelPosition(index)}
          player={p}
          activeColor={activeColor}
          displayedColor={displayedColor}
          diceRecycledOnBoard={diceRecycledOnBoard}
          panelIndex={index}
          players={sortedPlayers}
          gameOver={gameOver}
        />
      ))}
    </>
  );
};

const playerPanelPosition = (index: number) => css`
  position: absolute;
  height: ${playerPanelHeight}em;
  width: ${playerPanelWidth}em;
  top: ${playerPanelTop(index)}em;
  left: ${playerPanelLeft(index)}em;
`;

export { PlayerPanels };
