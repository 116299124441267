/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC } from "react";
import Color from '@gamepark/dunaia/color/Color';
import { Animation, useAnimation } from '@gamepark/react-client';
import { GainMemoryChips } from '@gamepark/dunaia/moves/GainMemoryChips';
import { isGainMemoryChip } from '@gamepark/dunaia/utils/is-move.utils';
import { MemoryChip } from '../memorychip/MemoryChip';
import { memoryChipLeft, memoryChipTop } from '../../styles/Style';

export type PlayerMemoryChipsProps = {
  color: Color;
  activeColor: Color;
  chips: number[];
};

const PlayerMemoryChips: FC<PlayerMemoryChipsProps> = (props) => {
  const { color, activeColor, chips } = props;
  const isActualPlayer = activeColor === color;
  const animation = useAnimation<GainMemoryChips>(
    (a) => isActualPlayer && isGainMemoryChip(a.move)
  );

  const computedChips = animation? [...chips, ...animation?.move.memoryChips]: chips;
  const isAnimated = (memoryChip: number) => animation?.move?.memoryChips?.includes(memoryChip);

  return (
    <>
      {computedChips.map((c) => (
        <MemoryChip memoryChip={c} key={c} css={[chip(c), isAnimated(c) && chipArriving(animation!)]} />
      ))}
    </>
  );
};

const chip = (memoryChip: number) => css`
  position: absolute;
  top: ${memoryChipTop(memoryChip - 1)}em;
  left: ${memoryChipLeft}em;
  filter: drop-shadow(0.2em 0 0.4em black);
`;

const animateChip =  keyframes`
  from {
    transform: translateZ(200em);
  }
`

const chipArriving = (animation: Animation<GainMemoryChips>) => css`
  animation: ${animateChip} ${animation.duration}s ease-out both;
`

export { PlayerMemoryChips };
