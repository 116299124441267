/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FC, HTMLAttributes } from "react";
import { Images } from "../../images/Images";

export type MetalFlowerProps = HTMLAttributes<HTMLDivElement>;

const MetalFlower: FC<MetalFlowerProps> = ({ ...props }) => {
  return <div css={metalFlowerStyle} {...props} />;
};

const metalFlowerStyle = css`
  background: url(${Images.MetalFlower});
  background-size: cover;
`;

export { MetalFlower };
