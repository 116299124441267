/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import Color from '@gamepark/dunaia/color/Color';
import { dunaiaHeight, dunaiaWidth } from '../../styles/Style';
import { Images } from '../../images/Images';

type DunaiaFaceProps = {
  color: Color;
  dunaia: number;
  highlight?: boolean;
  selected?: boolean;
  scale?: number;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>

const DunaiaFace: FC<DunaiaFaceProps> = (props) => {
  const { color, dunaia, highlight, selected, scale, ...rest } = props
  return (
    <div
      css={[
        dunaiaStyle(color, dunaia, scale),
        highlight && selectableDunaia,
        selected && selectedDunaia
      ]}
      {...rest}
    />
  )
}

const dunaiaStyle = (color: Color, dunaia: number, scale: number = 1) => css`
  position: absolute;
  bottom: 50%;
  height: ${dunaiaHeight * scale}em;
  width: ${dunaiaWidth * scale}em;
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  transform-style: preserve-3d;
  transform-origin: center bottom;
  transform: rotateX(-82deg);
  box-shadow: 0 0 0.4em 0.2em black;
  background: url(${DunaiaImages.get(color)![dunaia]}) no-repeat center 14%;
  background-size: 120% 120%;
  cursor: pointer;
`;

const selectedDunaia = css`
  box-shadow: 0 0 0 0.5em green;
`;

const selectableDunaia = css`
  box-shadow: 0 0 0 0.5em gold;
  &:hover {
    ${selectedDunaia}
  }
`;

const DunaiaImages = new Map<Color, any[]>();
DunaiaImages.set(Color.Yellow, [
  Images.YellowDunaia1,
  Images.YellowDunaia2,
  Images.YellowDunaia3,
  Images.YellowDunaia4,
  Images.YellowDunaia5,
  Images.YellowDunaia6,
]);
DunaiaImages.set(Color.Red, [
  Images.RedDunaia1,
  Images.RedDunaia2,
  Images.RedDunaia3,
  Images.RedDunaia4,
  Images.RedDunaia5,
  Images.RedDunaia6,
]);
DunaiaImages.set(Color.Green, [
  Images.GreenDunaia1,
  Images.GreenDunaia2,
  Images.GreenDunaia3,
  Images.GreenDunaia4,
  Images.GreenDunaia5,
  Images.GreenDunaia6,
]);
DunaiaImages.set(Color.Purple, [
  Images.PurpleDunaia1,
  Images.PurpleDunaia2,
  Images.PurpleDunaia3,
  Images.PurpleDunaia4,
  Images.PurpleDunaia5,
  Images.PurpleDunaia6,
]);

export {
  DunaiaFace
};
