import MoveType from './MoveType';
import Color from '../color/Color';
import GameState from '../GameState';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';
import { getBuiltBuildings } from '../utils/building.utils';

export type PlaceColorToken = {
  type: MoveType.PlaceColorToken;
  color: Color;
  building: number;
};

export const placeColorTokenMove = (color: Color, building: number): PlaceColorToken => ({
  type: MoveType.PlaceColorToken,
  color,
  building,
});

export const placeColorToken = (state: GameState, move: PlaceColorToken) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  const building = getBuiltBuildings(player).find((b) => b!.building === move.building);
  if (!building) {
    throw new Error('You are trying to add a color token on a building that you not own.');
  }

  building.additionalColors.push(move.color);

  shiftFirstEffectOfType(player.pending, GameEffectType.PlaceColorToken);
};
