import MoveType from './MoveType';
import GameState from '../GameState';
import { Dice } from '../common/Dice';
import { Phase } from '../common/Phase';

export type RollDice = {
  type: MoveType.RollDice;
  dice: Dice[];
};

export const rollDiceMove = (dice: Dice[]): RollDice => ({
  type: MoveType.RollDice,
  dice,
});

export const rollDice = (state: GameState, move: RollDice) => {
  const actualPlayer = state.players.find((p) => p.color === state.activePlayer)!;
  state.dice.push(...move.dice);

  const lastColor = state.players[state.players.length - 1]?.color;

  let nextPlayer;
  if (actualPlayer.color === lastColor) {
    state.players.forEach((p) => { delete p.end })
    nextPlayer = state.players[0];
    state.round++;
  } else {
    const playerIndex = state.players.findIndex((p) => actualPlayer.color === p.color);
    nextPlayer = state.players[playerIndex + 1];
  }

  state.activePlayer = nextPlayer.color;
  nextPlayer.phase = Phase.ChoosingDie;

  state.players.forEach((p) => {
    p.dice = [];
  });
};
