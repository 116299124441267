import GameView from '@gamepark/dunaia/GameView';

export type SelectToBuyBuilding = {
  type: 'SelectToBuyBuilding';
  building?: number;
};

export const selectToBuyBuildingMove = (building?: number): SelectToBuyBuilding => ({
  type: 'SelectToBuyBuilding',
  building,
});

export function selectToBuyBuilding(state: GameView, move: SelectToBuyBuilding) {
  state.toBuyBuilding = move.building;
}
