import { GameEffectRules } from './GameEffectRules';
import { GainArtefactPartEffect } from '../GainArtefactPartEffect';
import { gainArtefactPartMove } from '../../moves/GainArtefactPart';
import Move from '../../moves/Move';

class GainArtefactPartEffectRules extends GameEffectRules<GainArtefactPartEffect> {
  constructor(effect: GainArtefactPartEffect) {
    super(effect);
  }

  automaticMoves (): Move[] {
    return [gainArtefactPartMove(this.effect.artefactPart)]
  }
}

export {
  GainArtefactPartEffectRules
};
