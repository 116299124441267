import { css, Global } from '@emotion/react';
import { DunaiaOptionsSpec } from '@gamepark/dunaia/DunaiaOptions';
import Dunaia from '@gamepark/dunaia/Dunaia';
import { GameProvider, setupTranslation } from '@gamepark/react-client';
import normalize from 'emotion-normalize';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import DunaiaView from './DunaiaView';
import translations from './translations.json';
import { DunaiaAnimation } from './animations/DunaiaAnimation';
import { Images } from './images/Images';
import { DunaiaTutorial } from './Tutorial/TutorialSpecs';

setupTranslation(translations);

const style = css`
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: 'Rambla', sans-serif;
    font-size: 1vh;
    @media (max-aspect-ratio: 185/100) {
      font-size: calc(100vw / 185);
    }
  }

  #root {
    position: absolute;
    height: 100vh;
    width: 100vw;
    user-select: none;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    font-family: 'Rambla', sans-serif;
    color: #eee;
    background-image: url(${Images.Background});

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

ReactDOM.render(
  <StrictMode>
    <GameProvider
      game="dunaia"
      Rules={Dunaia}
      RulesView={DunaiaView}
      optionsSpec={DunaiaOptionsSpec}
      animations={new DunaiaAnimation()}
      tutorial={DunaiaTutorial}
    >
      <App />
    </GameProvider>
    <Global styles={[normalize, style]} />
  </StrictMode>,
  document.getElementById('root')
);
