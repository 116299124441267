import { Player } from '../../player/Player';
import Move from '../../moves/Move';
import GameState from '../../GameState';
import { GameEffects } from '../GameEffect';

export interface IGameEffectRules<T extends GameEffects> {
  effect: T;
  automaticMoves?: (player: Player) => Move[];
  legalMoves?: (state: GameState, player: Player) => Move[];
  isIgnored?: (player: Player, state: GameState) => boolean;
}

export abstract class GameEffectRules<T extends GameEffects> implements IGameEffectRules<T> {
  effect: T;

  protected constructor(effect: T) {
    this.effect = effect;
  }
}
