import MoveType from './MoveType';
import GameState from '../GameState';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';

export type GainMemoryChips = {
  type: MoveType.GainMemoryChips;
  memoryChips: number[];
};

export const gainMemoryChipsMove = (memoryChips: number[]): GainMemoryChips => ({
  type: MoveType.GainMemoryChips,
  memoryChips,
});

export const gainMemoryChips = (state: GameState, move: GainMemoryChips) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  player.memoryChips = [...player.memoryChips, ...move.memoryChips].sort();

  shiftFirstEffectOfType(player.pending, GameEffectType.GainMemoryChips, true);
};
