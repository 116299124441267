/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useState } from 'react';
import { ElderPowerTile } from './ElderPowerTile';
import { elderPowerHeight, elderPowerWidth, memoryChipHeight, memoryChipWidth } from '../../styles/Style';
import { MemoryChip } from '../memorychip/MemoryChip';
import { ElderPowerMemoryChips } from '@gamepark/dunaia/material/board/ElderPowerMemoryChips';
import {
  BuyBuilding,
  ElderPowers,
  MetalFlowers,
  MoveConstructionToken,
  MoveDunaia,
  PlaceColorToken
} from '@gamepark/dunaia/material/power/ElderPowers';
import { Player } from '@gamepark/dunaia/player/Player';
import { DunaiaButton } from '../../button/DunaiaButton';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import { callElderMove } from '@gamepark/dunaia/moves/CallElder';
import { selectElderPowerMove } from '../../moves/SelectElderPower';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faTimes } from '@fortawesome/free-solid-svg-icons';
import { canCallElder } from '@gamepark/dunaia/utils/elder-power.utils';
import { Images } from '../../images/Images';
import { ElderPower } from '@gamepark/dunaia/material/power/ElderPower';
import { Trans, useTranslation } from 'react-i18next';
import { HelpBackground } from '../../help/HelpBackground';
import { ElderPowerHelpContent } from '../../help/ElderPowerHelpContent';
import { createPortal } from 'react-dom';

type ElderPowerMemoryChipChooserProps = {
  elderPowerIndex: number;
  elderPowers: number[];
  player: Player;
};

const ElderPowerViewer: FC<ElderPowerMemoryChipChooserProps> = (props) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { t } = useTranslation();
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const { elderPowerIndex, elderPowers, player } = props;
  const baseMemoryChips = ElderPowerMemoryChips[elderPowerIndex];
  const elderPower = ElderPowers[elderPowers[elderPowerIndex]];
  const [selectedChips, setSelectedChips] = useState<number[]>(baseMemoryChips);
  const complete = selectedChips.length === (elderPower.memoryChips || 0) + baseMemoryChips.length;

  const selectChip = (memoryChip: number) => {
    if (baseMemoryChips.includes(memoryChip) || (!selectedChips.includes(memoryChip) && complete)) {
      return;
    }

    setSelectedChips((selectedChips) => {
      if (selectedChips.includes(memoryChip)) {
        return selectedChips.filter((m) => m !== memoryChip);
      }

      return [...selectedChips, memoryChip].sort();
    });
  };

  const isDisabled = (m: number) => {
    return !player.memoryChips.includes(m) || (complete && !selectedChips.includes(m));
  };
  const canBeCalled = player.color === playerId && canCallElder(player, elderPowerIndex, elderPower);

  const callTheElder = () => {
    if (!complete) {
      return;
    }

    play(
      callElderMove(
        elderPowerIndex,
        selectedChips.filter((c) => !baseMemoryChips.includes(c))
      )
    );
    play(selectElderPowerMove(), { local: true });
  };

  const closeChooser = () => play(selectElderPowerMove(), { local: true });
  const toggleHelp = () => setShowHelp(show => !show);
  const modal = (
    <>
      <div css={overlay} onClick={closeChooser} />
      <div css={actionStyle} onClick={closeChooser}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div css={[actionStyle, questionStyle]} onClick={toggleHelp}>
        <FontAwesomeIcon icon={faQuestion} />
      </div>
      <div css={elderPowerDescription}>
        <span>
          <Trans defaults={ElderPowerDescriptions.get(elderPower)} components={[<strong />]} />
        </span>
        { canBeCalled && !!elderPower.memoryChips && (
          <span>
            {t('elder-power.description.choose-chips', {count: elderPower.memoryChips, chips: baseMemoryChips.join(',')})}
          </span>
        ) }
      </div>
      { showHelp && <HelpBackground onClose={() => setShowHelp(false)} />}
      <ElderPowerTile elderPower={elderPowers[elderPowerIndex]} css={[elderPowerTileStyle, showHelp && helpPosition]} />
      <div css={[baseMemoryChipsStyle(elderPowerIndex), showHelp && baseChipHelpPosition]} />
      {canBeCalled &&
        (
          <>
            { [1, 2, 3, 4, 5, 6].map((m) => (
              <MemoryChip
                key={m}
                memoryChip={m}
                css={[memoryChipsStyle(m), selectedChips.includes(m) && selected, isDisabled(m) && disabledChip]}
                onClick={() => selectChip(m)}
              />
            ))}
            <DunaiaButton
              labelKey={t('button.activate')}
              css={validateButton}
              highlighted={complete}
              disabled={selectedChips.length < (elderPower.memoryChips || 0)}
              onClick={callTheElder}
            />
          </>
        )
      }
      { showHelp && <ElderPowerHelpContent elderPowerIndex={elderPowerIndex} elderPower={elderPowers[elderPowerIndex]} onClose={() => setShowHelp(false)} />}
    </>
  );

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(modal, letterBox);
  }

  return null;
};

const overlay = css`
  position: absolute;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: rgba(0, 0, 0, 0.77);
  cursor: pointer;
  transform: translateZ(50em);
`;


const actionStyle = css`
  border-radius: 50%;
  background-color: white;
  padding: 0.5em;
  height: 7em;
  width: 7em;
  position: absolute;
  top: 15em;
  right: 20em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateZ(50em);

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 4em;
  }
`;

const questionStyle = css`
  top: 23em;
  transform: translateZ(50em);
`

const helpPosition = css`
  top: 40em;
  transform: translateZ(50em);
`

const baseChipHelpPosition = css`
  top: 51.8em;
  transform: translateZ(50em);
`;

const elderPowerTileStyle = css`
  transition: top 0.5s;
  transform-style: preserve-3d;
  position: absolute;
  top: 15em;
  left: 80em;
  height: ${elderPowerHeight * 2}em;
  width: ${elderPowerWidth * 2}em;
  transform: translateZ(50em);
`;

const memoryChipChoiceHeight = memoryChipHeight * 2;
const memoryChipChoiceWidth = memoryChipWidth * 2;
const memoryChipsStyle = (memoryChip: number) => css`
  position: absolute;
  top: 58%;
  left: ${33.5 + memoryChip * (memoryChipChoiceWidth + 4)}em;
  transform: rotateZ(90deg) translateZ(50em);
  height: ${memoryChipChoiceHeight}em;
  width: ${memoryChipChoiceWidth}em;
  cursor: pointer;
  filter: brightness(50%);
`;

const selected = css`
  filter: brightness(100%);
`;

const disabledChip = css`
  opacity: 0.8;
  filter: grayscale(1);
  cursor: not-allowed;
  &:hover {
    filter: grayscale(1);
  }
`;

const validateButton = css`
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translateX(-50%) translateZ(50em);
`;

const elderPowerDescription = css`
  position: absolute;
  top: 48%;
  padding: 2em;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 50%;
  border-radius: 2em;
  transform: translate(-50%, -50%) translateZ(50em);
  > span {
    font-size: 4em;
    word-break: break-word;
    display: block;
  }
`;

const baseMemoryChipsStyle = (index: number) => css`
  position: absolute;
  left: 83.7em;
  top: 26.7em;
  transition: top 0.5s;
  transform-style: preserve-3d;
  background-image: url(${ElderPowerPositions.get(index)});
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
  height: 10.1em;
  border-radius: 3em;
  width: ${11*1.53}em;
  transform: translateZ(50em);
`;

const ElderPowerPositions = new Map<number, any>();
ElderPowerPositions.set(0, Images.ElderPowerPosition1);
ElderPowerPositions.set(1, Images.ElderPowerPosition2);
ElderPowerPositions.set(2, Images.ElderPowerPosition3);

const ElderPowerDescriptions = new Map<ElderPower, string>();
ElderPowerDescriptions.set(MetalFlowers, 'elder-power.description.metal-flowers');
ElderPowerDescriptions.set(MoveDunaia, 'elder-power.description.move-dunaia');
ElderPowerDescriptions.set(MoveConstructionToken, 'elder-power.description.move-construction-token');
ElderPowerDescriptions.set(BuyBuilding, 'elder-power.description.buy-building');
ElderPowerDescriptions.set(PlaceColorToken, 'elder-power.description.place-color-token');

export { ElderPowerViewer };
