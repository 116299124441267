/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { elderPowerHeight, elderPowerWidth } from '../../styles/Style';
import { ElderPower } from '@gamepark/dunaia/material/power/ElderPower';
import {
  BuyBuilding,
  ElderPowers,
  MetalFlowers,
  MoveConstructionToken,
  MoveDunaia,
  PlaceColorToken,
} from '@gamepark/dunaia/material/power/ElderPowers';
import { Images } from '../../images/Images';

export type ElderPowerTileProps = {
  elderPower: number;
} & HTMLAttributes<HTMLDivElement>;

const ElderPowerTile: FC<ElderPowerTileProps> = ({ elderPower, ...props }) => {
  const power = ElderPowers[elderPower];
  return <div css={elderPowerStyle(power)} {...props} />;
};
const ElderPowerImages = new Map<ElderPower, any>();
ElderPowerImages.set(BuyBuilding, Images.ElderPowerBuyBuilding);
ElderPowerImages.set(MoveDunaia, Images.ElderPowerMoveDunaia);
ElderPowerImages.set(MetalFlowers, Images.ElderPowerMetalFlowers);
ElderPowerImages.set(PlaceColorToken, Images.ElderPowerPlaceColor);
ElderPowerImages.set(MoveConstructionToken, Images.ElderPowerMoveConstruction);

const elderPowerStyle = (elderPower: ElderPower) => css`
  height: ${elderPowerHeight}em;
  width: ${elderPowerWidth}em;
  background-image: url(${ElderPowerImages.get(elderPower)});
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
`;

export { ElderPowerTile };
