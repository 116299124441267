import { Player } from '../player/Player';
import { GameEffects, GameEffectType } from '../effect/GameEffect';
import { GainMemoryChipsEffect } from '../effect/GainMemoryChipsEffect';
import partition from 'lodash/partition';
import { IGameEffectRules } from '../effect/rules/GameEffectRules';
import { BuyBuildingEffectRules } from '../effect/rules/BuyBuildingEffectRules';
import { GainMemoryChipsEffectRules } from '../effect/rules/GainMemoryChipsEffectRules';
import { GainMetalFlowerEffectRules } from '../effect/rules/GainMetalFlowerEffectRules';
import { MoveConstructionTokenEffectRules } from '../effect/rules/MoveConstructionTokenEffectRules';
import { MoveDunaiaEffectRules } from '../effect/rules/MoveDunaiaEffectRules';
import { PlaceColorTokenEffectRules } from '../effect/rules/PlaceColorTokenEffectRules';
import { GainArtefactPartEffectRules } from '../effect/rules/GainArtefactPartEffectRules';

/**
 * Get real pending effect from player pending
 * This is mandatory because pending coming from the server are not typed
 * @param player The player typed pending effect
 */
export const getPlayerPendingEffectRules = (player: Player): IGameEffectRules<GameEffects>[] => {
  return player.pending.map((e) => getEffectRule(e));
};

/**
 * Get the type for a pending effect
 * @param effect The pending effect
 * @return effect typed pending effect
 */
export const getEffectRule = (effect: GameEffects): IGameEffectRules<GameEffects> => {
  switch (effect.type) {
    case GameEffectType.BuyBuilding:
      return new BuyBuildingEffectRules(effect);
    case GameEffectType.GainMemoryChips:
      return new GainMemoryChipsEffectRules(effect);
    case GameEffectType.GainMetalFlower:
      return new GainMetalFlowerEffectRules(effect);
    case GameEffectType.MoveConstructionToken:
      return new MoveConstructionTokenEffectRules(effect);
    case GameEffectType.MoveDunaia:
      return new MoveDunaiaEffectRules(effect);
    case GameEffectType.PlaceColorToken:
      return new PlaceColorTokenEffectRules(effect);
    case GameEffectType.GainArtefactPart:
      return new GainArtefactPartEffectRules(effect);
  }
};

/**
 * Does the player has the effect in pending ?
 * @param pending THe player list of pending
 * @param type The type of effect
 */
export const  getEffectOfType = (pending: GameEffects[], type: GameEffectType) => {
  return pending.find((p) => p.type === type);
};

/**
 * Remove the next pending effect corresponding to the given effect
 * IMPORTANT: This function remove the effect from the list
 * @param pending The player list of effect
 * @param type The type of the effect
 * @param every Indicate if the count number must be ignored
 */
export const shiftFirstEffectOfType = (pending: GameEffects[], type: GameEffectType, every?: boolean) => {
  const effect = pending.findIndex((p) => p.type === type);
  if (effect > -1) {
    const theEffect = pending[effect];
    if (theEffect.count && theEffect.count > 1 && !every) {
      theEffect.count--;
    } else {
      pending.splice(effect, 1);
    }
  }

  return pending;
};

/**
 * Get the first memory chip effect for a player
 * @param player the player to fetch
 **/
export const getMemoryChipEffect = (player: Player): GainMemoryChipsEffect | undefined =>
  player.pending.find(isMemoryChipEffect);

// Is effects
export const isMemoryChipEffect = (effect: GameEffects): effect is GainMemoryChipsEffect =>
  effect.type === GameEffectType.GainMemoryChips;

export const addAndReorganizeEffects = (player: Player, effects: GameEffects[]) => {
  const mutableEffect = effects?.map(e => ({ ...e }));
  const [automaticEffects, other] = partition([...player.pending, ...mutableEffect], e => e.automatic)
  player.pending = [
    ...automaticEffects,
    ...other
  ];
}
