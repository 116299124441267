/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { buildingHeight, buildingWidth, playerBuildingLeft, playerBuildingTop, shineEffect } from '../../styles/Style';
import { useDrop } from 'react-dnd';
import { DragObjectType, MainBoardBuilding } from '../../draggable';
import { BuyBuilding, buyBuildingMove } from '@gamepark/dunaia/moves/BuyBuilding';
import { Position } from '@gamepark/dunaia/common/Position';
import { selectBuildingMove } from '../../moves/SelectBuilding';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { isBuyBuilding } from '@gamepark/dunaia/utils/is-move.utils';
import { canBuyBuilding } from '@gamepark/dunaia/utils/building.utils';
import { Player } from '@gamepark/dunaia/player/Player';

type PlayerAvailableBuildingPositionProps = {
  player: Player;
  selectedBuilding?: number;
  x: number;
  y: number;
}

const PlayerAvailableBuildingPosition: FC<PlayerAvailableBuildingPositionProps> = (props) => {
  const { x, y, player, selectedBuilding } = props;
  const playerId = usePlayerId();
  const play = usePlay();
  const iCanBuy = canBuyBuilding(player);
  const animation = useAnimation<BuyBuilding>((a) => isBuyBuilding(a.move));
  const canDropBuilding = iCanBuy && player.color === playerId && selectedBuilding !== undefined && !animation;

  const [{ isOver }, ref] = useDrop({
    accept: DragObjectType.MainBoardBuilding,
    canDrop: (_: MainBoardBuilding) => canDropBuilding,
    drop: (item: MainBoardBuilding) => buyBuildingMove(item.building, { x, y }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const buyBuilding = (position: Position) => {
    if (!canDropBuilding) {
      return;
    }

    play(selectBuildingMove(), { local: true });
    play(buyBuildingMove(selectedBuilding, position));
  };

  return (
    <div ref={ref} key={`${x}-${y}`} css={[availableBuildingPosition(x, y), !isOver && shineEffect(), isOver && hoverBuilding]} onClick={() => buyBuilding({ x, y })} />
  )
}

const hoverBuilding = css`
  background-color: rgba(255, 255, 255, 0.8);
`

const availableBuildingPosition = (x: number, y: number) => css`
  position: absolute;
  left: ${playerBuildingLeft(x)-0.6}em;
  top: ${playerBuildingTop(y)}em;
  height: ${buildingHeight}em;
  width: ${buildingWidth + 1.2}em;
  border-radius: 1em;
  transform-style: preserve-3d;
  cursor: pointer;
  transform: translateZ(0.1em);
  &:hover {
    ${hoverBuilding}
  }
`;

export {
  PlayerAvailableBuildingPosition
}
