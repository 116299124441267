/**
 * IMPORTANT: Ratio must always be Width / Height
 * Positions must be in em
 */
import { Position } from '@gamepark/dunaia/common/Position';
import { DunaiaPosition } from '../material/dunaia/DunaiaPosition';
import { css, keyframes } from '@emotion/react';
import { DragLayerMonitor } from 'react-dnd';

export const screeRatio = 16 / 9;

/**
 * Dices
 */

export const diceSize = 3.5;
export const getDiceSize = (scale?: number) => (scale || 1) * diceSize;
export const diceLeft = (column: number) => 9 + (diceSize + 3) * column;
export const diceTop = (row: number, diceCount: number) => getBaseDiceTop(diceCount) + (diceSize + 0.5) * row;
const getBaseDiceTop = (diceCount: number) => {
  return 82 - ((diceSize + 0.5) * Math.max(diceCount - 5, 0));
}
/**
 * Building styles
 */
export const buildingRatio = 500 / 338;
export const buildingHeight = 14;
export const buildingWidth = buildingHeight * buildingRatio;

/**
 * Color token
 */
export const colorTokenSize = 3
;

/**
 * Color token chooser
 */
export const colorTokenChooserTop = (position: number, scale: number = 1) =>  16.5 + (position * (colorTokenSize + 0.5) * scale)
export const colorTokenChooserLeft = 145

/**
 * Color token on build position
 */
export const playerBuildingColorTokenChooserTop = (position: number, scale: number = 1) => 1 + (position * (colorTokenSize * 0.7) * scale)
export const playerBuildingColorTokenChooserLeft = (scale: number = 1) => 17.2 * scale

/**
 * Main board styles
 */
export const mainBoardRatio = 1186 / 1500;
export const mainBoardTop = 0;
export const mainBoardLeft = 118;
export const mainBoardHeight = 83.2;
export const mainBoardWidth = mainBoardHeight * mainBoardRatio;

/**
 * Main board recycled dice
 */
export const mainBoardRecycledDiceTop = (index: number) => 1 + Math.floor(index / 3) * (diceSize + 0.5);
export const mainBoardRecycledDiceLeft = (index: number) => 0.6 + (diceSize + 0.5) * (index % 3);

export const actionAreaHeight = 15;
export const actionAreaWidth = 12.9;
export const actionAreaTop = 5.5;
export const actionAreaLeft = (index: number) => 7.1 + index * actionAreaWidth;

/**
 * Main board actions
 */

/**
 * Main board building styles
 */
export const mainBoardBuildingLeft = (col: number) => -18 + (buildingWidth + 7.3) * col;
export const mainBoardBuildingTop = (level: number) => 24.2 + (buildingHeight + 3.55) * level;
export const mainBoardBuildingTranslateZ = (zIndex: number) => zIndex * 0.35 + 0.1;

/**
 * Elder powers styles
 */
export const elderPowerRatio = 220 / 125;
export const elderPowerHeight = 7;
export const elderPowerWidth = elderPowerHeight * elderPowerRatio;

/**
 * Metal flowers
 */
export const metalFlowerRatio = 150 / 141;

export const playerMetalFlowerHeight = 4;
export const playerMetalFlowerWidth = playerMetalFlowerHeight * metalFlowerRatio;
export const playerMetalFlowerTop = 1;
export const playerMetalFlowerLeft = 32.5;

/**
 * Player board styles
 */
export const playerBoardRatio = 2050 / 1479;
export const playerBoardHeight = 67;
export const playerBoardTop = 100 - playerBoardHeight;
export const playerBoardLeft = 0;
export const playerBoardWidth = playerBoardHeight * playerBoardRatio;

/**
 * Player board buildings
 */
export const playerBuildingTop = (y: number) => 5 + y * (buildingHeight + 7.6);
export const playerBuildingLeft = (x: number) => 4.9 + x * (buildingWidth + 9.2);
export const playerBuildingZIndex = (x: number, y: number) => 1 + y * 6 + x;

/**
 * Player board building dice
 */
export const diceOnBuildingLeft = 12.1;

export const diceOnBuildingTop = 4.7;

/**
 * Player board building construction token
 */
export const buildingConstructionTokenLeft = (position: number) => playerBuildingConstructionTokenLeft(position);
export const buildingConstructionTokenTop = (position: number) => playerBuildingConstructionTokenTop(position);

/**
 * Construction Tokens
 */
export const constructionTokenRatio = 150 / 205;
export const constructionTokenHeight = 3.2;
export const constructionTokenWidth = constructionTokenHeight * constructionTokenRatio;

export const playerConstructionTokenLeft = playerBoardWidth + 1.7;
export const playerConstructionTokenTop = (index: number) => 52 + index * (constructionTokenHeight + 1);

export const playerBuildingConstructionTokenLeft = (position: number) =>
  1.7 + (constructionTokenWidth + 0.2) * (position - 1);
export const playerBuildingConstructionTokenTop = (position: number) => 8.6 + ((position - 1) % 2) * 0.5;

/**
 * Prophecies styles
 */
export const prophecyRatio = 300 / 178;
export const prophecyHeight = 7.5;
export const prophecyWidth = prophecyHeight * prophecyRatio;

/**
 * Player prophecy style
 */
export const playerProphecyReducerHeight = 3;
export const playerProphecyReducerWidth = 3;
export const playerProphecyLeft = 23;
export const playerProphecyTop = (index: number) => 1 + (index * (playerProphecyReducerHeight + 1.5));
export const prophecyTileReducedLeft = -2.6;

/**
 * DUnaias
 */
export const dunaiaAreaWidth = 7;
export const dunaiaAreaHeight = dunaiaAreaWidth;
export const dunaiaRatio = 150 / 200;
export const dunaiaWidth = dunaiaAreaWidth;
export const dunaiaHeight = dunaiaWidth / dunaiaRatio;

export const dunaiaBaseRatio = 150 / 92;
export const dunaiaBaseWidth = dunaiaAreaWidth - 2;
export const dunaiaBaseHeight = dunaiaBaseWidth / dunaiaBaseRatio;

export const mainBoardProphecyTop = (index: number) => {
  switch (index) {
    case 0:
      return 78.8;
    case 1:
      return 87.8;
    case 2:
      return 96.8;
  }

  return 0;
};

export const mainBoardProphecyLeft = (_index: number) => {
  return 99;
};

/**
 * Player panels
 */
export const playerPanelHeight = 14;
export const playerPanelWidth = (playerBoardWidth - 2) / 2;
export const playerPanelTop = (index: number) => (index / 2 >= 1 ? playerPanelHeight + 2 : 0);
export const playerPanelLeft = (index: number) => (index % 2) * (playerPanelWidth + 2);

export const avatarLeft = 0.7;
export const avatarWidth = 7;
export const avatarHeight = 7;

export const playerPanelDiceTop = 3;
export const playerPanelDiceLeft = avatarLeft + avatarWidth + 1.5;

/**
 * Memory chips ratio
 */
export const memoryChipRatio = 113 / 100;
export const memoryChipHeight = 5;
export const memoryChipWidth = memoryChipHeight * memoryChipRatio;
/**
 * Memory chip on the player board
 */
export const memoryChipTop = (index: number) => 7.7 + index * 6.4;
export const memoryChipLeft = playerBoardWidth - 0.6;

/**
 * Artefact part
 */
export const artefactPart1Ratio = 196 / 240;
export const artefactPart2Ratio = 263 / 215;
export const artefactPart3Ratio = 193 / 223;
export const artefactPartRatio = (part: number) => {
  switch (part) {
    case 0:
      return artefactPart1Ratio;
    case 1:
      return artefactPart2Ratio;
    case 2:
      return artefactPart3Ratio;
  }

  return 0;
};

export const mainBoardArtefactPartTop = (part: number) => {
  switch (part) {
    case 0:
      return 29;
    case 1:
      return 45.8;
    case 2:
      return 62.5;
  }

  return 0;
};

export const mainBoardArtefactPartLeft = 61;

export const mainBoardArtefactPartRotateZ = (part: number) => {
  switch (part) {
    case 0:
      return -30;
    case 1:
      return 90;
    case 2:
      return -150;
  }

  return 0;
};

export const artefactPartHeight = (part: number) => {
  switch (part) {
    case 0:
      return 10 * 1.1;
    case 1:
      return 8.96 * 1.1;
    case 2:
      return 9.29 * 1.1;
  }

  return 0;
};

export const artefactPartTop = (part: number) => {
  switch (part) {
    case 0:
      return 3.1;
    case 1:
      return 0.6;
    case 2:
      return 3.3;
  }

  return 0;
};

export const artefactPartLeft = (part: number) => {
  switch (part) {
    case 0:
      return 4.1;
    case 1:
      return 1.4;
    case 2:
      return 0.4;
  }

  return 0;
};

export const artefactPartWidth = (part: number) => artefactPartHeight(part) * artefactPartRatio(part);
export const completeArtefactLeft = 27.4;
export const playerArtefactPartHeight = (part: number) => artefactPartHeight(part) / 2.3;
export const playerArtefactPartWidth = (part: number) => artefactPartWidth(part) / 2.3;
export const partialArtefactLeft = 36.3;
export const playerArtefactTop = 5.1;

/**
 * Animations
 */

// Choosen dice
export const chosenDiceTranslateX = (col: number, activePlayerPanelIndex: number) => {
  return -diceLeft(col) - mainBoardLeft + playerPanelLeft(activePlayerPanelIndex) + playerPanelDiceLeft;
};

export const chosenDiceTranslateY = (row: number, rowCount: number, activePlayerPanelIndex: number) => {
  return -diceTop(row, rowCount) - mainBoardTop + playerPanelTop(activePlayerPanelIndex) + playerPanelDiceTop;
};

// Recycle dice on board action
export const recycleOnBoardActionTranslateX = (
  playerPanelIndex: number,
  boardAction: number,
  positionOnBoardAction: number
) => {
  return (
    -playerPanelLeft(playerPanelIndex) -
    playerPanelDiceLeft +
    mainBoardLeft +
    actionAreaLeft(boardAction) +
    mainBoardRecycledDiceLeft(positionOnBoardAction)
  );
};

export const recycleOnBoardActionTranslateY = (playerPanelIndex: number, positionOnBoardAction: number) => {
  return (
    -playerPanelTop(playerPanelIndex) -
    playerPanelDiceTop +
    mainBoardTop +
    actionAreaTop +
    mainBoardRecycledDiceTop(positionOnBoardAction)
  );
};

// Gain artefact part
export const gainArtefactPartTranslateX = (playerPanelIndex: number, artefactPart: number) => {
  return (
    -mainBoardArtefactPartLeft -
    mainBoardLeft +
    playerPanelLeft(playerPanelIndex) +
    partialArtefactLeft +
    artefactPartLeft(artefactPart)
  );
};

export const gainArtefactPartTranslateY = (playerPanelIndex: number, artefactPart: number) => {
  return (
    -mainBoardArtefactPartTop(artefactPart) -
    mainBoardTop +
    playerPanelTop(playerPanelIndex) +
    playerArtefactTop +
    artefactPartTop(artefactPart)
  );
};

// Buy building
export const buyBuildingToPlayerBoardTranslateX = (position: Position, column: number) =>
  -mainBoardBuildingLeft(column) - mainBoardLeft + playerBoardLeft + playerBuildingLeft(position.x);
export const buyBuildingToPlayerBoardTranslateY = (position: Position, level: number) =>
  -mainBoardBuildingTop(level) - mainBoardTop + playerBoardTop + playerBuildingTop(position.y);

export const constructionTokenToBuildingTranslateX = (x: number) =>
  -playerConstructionTokenLeft + playerBuildingLeft(x) + buildingConstructionTokenLeft(1);

export const constructionTokenToBuildingTranslateY = (constructionToken: number, y: number) =>
  -playerConstructionTokenTop(constructionToken) + playerBuildingTop(y) + buildingConstructionTokenTop(1);

// Buy building to player panel
export const buyBuildingToPlayerPanelTranslateX = (activePlayerPanelIndex: number, column: number) =>
  -mainBoardBuildingLeft(column) - mainBoardLeft + playerPanelLeft(activePlayerPanelIndex);
export const buyBuildingToPlayerPanelTranslateY = (activePlayerPanelIndex: number, level: number) =>
  -mainBoardBuildingTop(level) - mainBoardTop + playerPanelTop(activePlayerPanelIndex);

// Refill building
export const refillBuildingTranslateX = () => -mainBoardBuildingLeft(0) + mainBoardBuildingLeft(1);

// Construction token
export const constructionTokenToReserveTranslateX = (x: number, actualConstructionTokenPosition: number) => {
  return (
    -playerBuildingConstructionTokenLeft(actualConstructionTokenPosition) -
    playerBuildingLeft(x) +
    playerConstructionTokenLeft
  );
};

export const constructionTokenToReserveTranslateY = (
  y: number,
  constructionTokens: number,
  actualConstructionTokenPosition: number
) => {
  return (
    -playerBuildingConstructionTokenTop(actualConstructionTokenPosition) -
    playerBuildingTop(y) +
    playerConstructionTokenTop(constructionTokens)
  );
};

// Move dunaia
export const moveDunaiaTranslateX = (actualPosition: number, newPosition: number) =>
  -DunaiaPosition[actualPosition].left + DunaiaPosition[newPosition].left;
export const moveDunaiaTranslateY = (actualPosition: number, newPosition: number) =>
  -DunaiaPosition[actualPosition].top + DunaiaPosition[newPosition].top;

// Recycle on building
export const recycleToBuildingTranslateX = (x: number, playerPanelIndex: number) =>
  -playerPanelDiceLeft -
  playerPanelLeft(playerPanelIndex) +
  playerBoardLeft +
  playerBuildingLeft(x) +
  diceOnBuildingLeft;
export const recycleToBuildingTranslateY = (y: number, playerPanelIndex: number) =>
  -playerPanelDiceTop - playerPanelTop(playerPanelIndex) + playerBoardTop + playerBuildingTop(y) + diceOnBuildingTop;

export const translateToBuildingTranslateX = (x: number) => playerBoardLeft +
  playerBuildingLeft(x) +
  diceOnBuildingLeft;

export const translateToBuildingTranslateY = (y: number) => playerBoardTop + playerBuildingTop(y) + diceOnBuildingTop




export const slideKeyframes = keyframes`
  0%, 60% {
    transform: translate(-33%, 33%);
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translate(33%, -33%);
    opacity: 0;
  }
`

export const shineEffect = (duration: number = 2.5) => css`
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    animation: ${slideKeyframes} ${duration}s infinite;
    z-index: 1;
    transform-style: preserve-3d;
    background: linear-gradient(to top right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  }
`

export const fadeOutDice = (duration: number) => css`
  > div > div {
    transition: opacity ${ duration }s;
    opacity: 0;
  }
`

const fadeInDiceAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const fadeInDice = css`
  animation: ${fadeInDiceAnimation} 1s linear both;
`

export const diceProjection = (monitor: DragLayerMonitor) => {
  const offset = monitor.getDifferenceFromInitialOffset()
  if (!offset) return null

  const x = offset.x < 0? offset.x: offset.x * 1.07;
  const y = offset.y * 1.1;

  return {x, y}
}

export const dunaiaProjection = (monitor: DragLayerMonitor) => {
  const offset = monitor.getDifferenceFromInitialOffset()
  if (!offset) return null

  const x = offset.x * 0.7;
  const y = offset.y * 0.7;

  return {x, y}
}

export const modalOverlay = css`
  position: fixed;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transform: translateZ(50em);
  -webkit-tap-highlight-color: transparent;
`;

/**
 * Scoring
 */
export const scoringMetalFlowerHeight = 4;
export const scoringMetalFlowerWidth = scoringMetalFlowerHeight * metalFlowerRatio;
