/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Player } from '@gamepark/dunaia/player/Player';
import { FC, HTMLAttributes, useCallback, useState } from 'react';
import Color from '@gamepark/dunaia/color/Color';
import { GamePoints, PlayerTimer, usePlay, usePlayer, usePlayerId } from '@gamepark/react-client';
import { getPlayerName } from '@gamepark/dunaia/DunaiaOptions';
import { useTranslation } from 'react-i18next';
import { displayPlayerMove } from '../../moves/DisplayPlayer';
import { PlayerArtefact } from './PlayerArtefact';
import { PlayerMetalFlower } from './PlayerMetalFlower';
import { avatarLeft, shineEffect } from '../../styles/Style';
import { PlayerPanelDice } from './PlayerPanelDice';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { useDrop } from 'react-dnd';
import { DragObjectType } from '../../draggable';
import { chooseDiceMove } from '@gamepark/dunaia/moves/ChooseDice';
import { DiceFromMainBoard } from '../../draggable/MainBoardDice';
import { getNonRecycledDie } from '@gamepark/dunaia/utils/dice.utils';
import { PlayerProphecies } from './PlayerProphecies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { getPlayerScore } from '@gamepark/dunaia/utils/is-over.utils';
import { PlayerAvatar } from './PlayerAvatar';
import { ScoreDetail } from './score';

export type PlayerPanelProps = {
  player: Player;
  panelIndex: number;
  activeColor: Color;
  displayedColor: Color;
  diceRecycledOnBoard: PlayerDice[];
  players: Player[];
  gameOver: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlayerPanel: FC<PlayerPanelProps> = (props) => {
  const { player, activeColor, panelIndex, diceRecycledOnBoard, players, displayedColor, gameOver, ...rest } = props;
  const { t } = useTranslation();
  const play = usePlay();
  const playerId = usePlayerId();
  const playerInfos = usePlayer(player.color);
  const isDisplayed = displayedColor === player.color;
  const nonPlacedDice = getNonRecycledDie(player.dice)?.dice;
  const [isScoreDisplayed,setDisplayScore] = useState(false)

  const toggleScoreDetail = (e?: any) => {
    e?.stopPropagation();
    e?.preventDefault();
    setDisplayScore((detail) => !detail);
  }
  const displayPlayer = useCallback(() => {
    if (!isDisplayed) {
      play(displayPlayerMove(player.color), { local: true });
    }
  }, [player.color, isDisplayed, play]);


  const [{ isOver }, ref] = useDrop({
    accept: DragObjectType.DiceFromMainBoard,
    canDrop: (_: DiceFromMainBoard) => activeColor === player.color && playerId === activeColor,
    drop: (item: DiceFromMainBoard) => chooseDiceMove(item.dice),
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  });

  return (
    <div ref={ref} css={[playerPanel, isDisplayed ? activated : displayable, isOver && overPlayerPanel]} {...rest} onClick={displayPlayer}>
      <PlayerAvatar player={player} css={avatarContainer} />
      {!gameOver && <PlayerTimer playerId={player.color} css={timer}/>}
      <GamePoints playerId={player.color} css={gamePoints}/>
      <span css={name}>{playerInfos?.name || getPlayerName(player.color, t)}</span>
      {gameOver && (
        <div css={actionStyle} onClick={toggleScoreDetail}>
          <FontAwesomeIcon icon={faTrophy} /> <span>{t('scoring.detail', { score: getPlayerScore(player) })}</span>
        </div>
      )}
      <PlayerArtefact player={player} activeColor={activeColor} />
      <PlayerMetalFlower metalFlowers={player.metalFlowers} />
      <PlayerProphecies player={player} />
      { !!nonPlacedDice && <PlayerPanelDice
        color={player.color}
        activeColor={activeColor}
        die={nonPlacedDice}
        panelIndex={panelIndex}
        displayedColor={displayedColor}
        diceRecycledOnBoard={diceRecycledOnBoard}
        players={players}
      /> }
      <ScoreDetail onClose={toggleScoreDetail} player={isScoreDisplayed? player: undefined} />
    </div>
  );
};

const overPlayerPanel = css`
  background-color: rgba(255, 255, 255, 1);
`

const playerPanel = css`
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
  transform-style: preserve-3d;
`;

const actionStyle = css`
  top: 5.2em;
  left: 15em;
  transform: translateX(-50%);
  position: absolute;
  border-radius: 1em;
  background-color: gold;
  border: 0.2em solid black;
  box-shadow: 0 0 0.3em black;
  max-width: 22.5em;
  padding: 1em 0.5em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${shineEffect()}

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 2.5em;
  }
  
  > span {
    padding-left: 0.4em;
    font-size: 2em;
    white-space: nowrap;
  } 
`;

const activated = css`
  box-shadow: 0 0 0 0.6em gold;
`;

const displayable = css`
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255);
  }
`;

const avatarContainer = css`
  top: 0.7em;
  left: ${avatarLeft}em;
`;

const name = css`
  position: absolute;
  left: 0.3em;
  bottom: 0.15em;
  font-size: 3em;
  color: black;
  max-width: 7.1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //z-index: 10;
`;

const timer = css`
  position: absolute;
  color: black;
  font-size: 2.8em;
  top: 0.4em;
  left: 3.4em;
`

const gamePoints = css`
  position: absolute;
  color: black;
  font-size: 2.8em;
  top: 0.4em;
  left: 3.4em;
`

export { PlayerPanel };
