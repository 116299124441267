/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import Color from '@gamepark/dunaia/color/Color'
import { FC, useState } from 'react';
import { Die } from '../dice/Die';
import { chosenDiceTranslateX, chosenDiceTranslateY, diceLeft, diceProjection, diceSize, diceTop, fadeInDice } from '../../styles/Style';
import { Dice } from '@gamepark/dunaia/common/Dice';
import { chooseDiceMove } from '@gamepark/dunaia/moves/ChooseDice';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { isChooseDice, isRollDice } from '@gamepark/dunaia/utils/is-move.utils';
import { diceFromMainBoard } from '../../draggable/MainBoardDice';
import Move from '@gamepark/dunaia/moves/Move';
import { Draggable } from '@gamepark/react-components';

type MainBoardDiceProps = {
  row: number;
  rowCount: number;
  column: number;
  playable: boolean;
  index: number;
  dice: Dice;
  activePlayerPanelIndex: number;
  activeColor: Color;
}

const MainBoardDice: FC<MainBoardDiceProps> = (props) => {
  const { index, column, row, rowCount, playable, dice, activeColor, activePlayerPanelIndex } = props;
  const play = usePlay();
  const playerId = usePlayerId();
  const [isDragging, setDragging] = useState(false);
  const animation = useAnimation((a) => isChooseDice(a.move) || isRollDice(a.move));
  const chooseDiceAnimation = animation && isChooseDice(animation.move) ? animation : undefined;
  const itsMe = activeColor === playerId;

  const playDice = () => {
    if (!playable || animation) {
      return;
    }

    play(chooseDiceMove(index));
  };

  const draggableObject = diceFromMainBoard(index);
  const onDrag = () => {
    setDragging(true);
    return draggableObject;
  };

  const onEndDrag = (_: any, monitor: any) => {
    if(!monitor.didDrop()) {
      setDragging(false);
    }
  };

  const onDrop = (move: Move) => {
    if (move) {
      play(move);
    }
  };

  const translate = `translate(${diceLeft(column)}em, ${diceTop(row, rowCount)}em)`

  return (
    <Draggable
      type={ draggableObject.type }
      item={ onDrag }
      drop={ onDrop }
      end={ onEndDrag }
      projection={diceProjection}
      canDrag={ playable && !animation }
      css={ [dicePosition, chooseDiceAnimation &&
      chooseDiceAnimation.move.dice === index &&
      translateDiceToPlayer(
        column,
        row,
        rowCount,
        activePlayerPanelIndex,
        chooseDiceAnimation.duration,
        itsMe,
        isDragging
      )] }
      preTransform={ `${translate} ${playable ? `translateZ(${ diceSize + 1 }em)` : 'translateZ(0)'}` }
    >
      <Die
        key={ `${ dice.value }-${ column }` }
        color={ dice.color }
        value={ dice.value }
        onClick={ playDice }
        lightning={ playable && !animation }
        css={ [
          fadeIn,
          playable && selectable
        ] }
      />
    </Draggable>
  );
};

const dicePosition = css`
  position: absolute;
  transform-style: preserve-3d;
`;

const fadeIn = css`
  > div > div {
    ${ fadeInDice }
  }
`;

const animateDiceToPlayer = (column: number, row: number, rowCount: number, activePlayerPanelIndex: number, itsMe: boolean) => {
  const translateY = chosenDiceTranslateY(row, rowCount, activePlayerPanelIndex);
  const translateX = chosenDiceTranslateX(column, activePlayerPanelIndex);
  return keyframes`
    ${ itsMe ? 'from' : '10%' } {
      transform: translate(${diceLeft(column)}em, ${diceTop(row, rowCount)}em) translateZ(${ diceSize + 1 }em);
    }
    50% {
      transform: translate(${diceLeft(column)}em, ${diceTop(row, rowCount)}em) translate(${ translateX / 2 }em, ${ translateY / 2 }em) translateZ(${ diceSize + 5 }em);
    }
    to {
      transform: translate(${diceLeft(column)}em, ${diceTop(row, rowCount)}em) translate(${ translateX }em, ${ translateY }em) translateZ(0);
    }
  `;
};


const translateDiceToPlayer = (
  column: number,
  row: number,
  rowCount: number,
  activePlayerPanelIndex: number,
  duration: number,
  itsMe: boolean,
  isDragging: boolean,
) => {
  if (isDragging) {
    const translateY = chosenDiceTranslateY(row, rowCount, activePlayerPanelIndex);
    const translateX = chosenDiceTranslateX(column, activePlayerPanelIndex);
    return css`
      transition: transform ${duration}s;
      transform: translate(${diceLeft(column)}em, ${diceTop(row, rowCount)}em) translate(${ translateX }em, ${ translateY }em) translateZ(0);
    `;
  }

  return css`
    animation: ${ animateDiceToPlayer(column, row, rowCount, activePlayerPanelIndex, itsMe) } ${ duration }s linear both;
  `;
};

const selectable = css`
  cursor: pointer;
`;

export {
  MainBoardDice
};
