import { Player } from '../../player/Player';
import { Building } from '../building/Building';

export enum ProphecyColor {
  GREEN = 1,
  PURPLE,
  ORANGE,
}

export type Prophecy = {
  color: ProphecyColor;
  score: number;
  canPlayerGet: (player: Player, buildings: Building[]) => boolean;
};
