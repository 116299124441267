import { BuyBuildingEffect } from './BuyBuildingEffect';
import { GainMemoryChipsEffect } from './GainMemoryChipsEffect';
import { GainMetalFlowerEffect } from './GainMetalFlowerEffect';
import { MoveConstructionTokenEffect } from './MoveConstructionTokenEffect';
import { MoveDunaiaEffect } from './MoveDunaiaEffect';
import { PlaceColorTokenEffect } from './PlaceColorTokenEffect';
import { GainArtefactPartEffect } from './GainArtefactPartEffect';

export enum GameEffectType {
  BuyBuilding = 1,
  GainMemoryChips,
  GainMetalFlower,
  MoveConstructionToken,
  MoveDunaia,
  PlaceColorToken,
  GainArtefactPart,
  Score,
}

export type GameEffect = {
  type: GameEffectType;
  count?: number;
  building?: number;
  automatic?: boolean;
};

export type GameEffects = GameEffect &
  (
    | BuyBuildingEffect
    | GainMemoryChipsEffect
    | GainMetalFlowerEffect
    | MoveConstructionTokenEffect
    | MoveDunaiaEffect
    | PlaceColorTokenEffect
    | GainArtefactPartEffect
  );
