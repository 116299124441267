/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { HelpModal } from './HelpModal';

type MetalFlowerHelpModalProps = {
  onClose: () => void;
}

const MetalFlowerHelpModal: FC<MetalFlowerHelpModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  const modal = (
    <HelpModal onClose={onClose} title={t('help.metal-flower.title')}>
      <div css={description}>
        <span>{t('help.metal-flower.explanation')}</span>
      </div>
    </HelpModal>
  );

  let letterBox = document.getElementById('letterbox')!;
  return createPortal(modal, letterBox);
};

const description = css`
  > span {
    font-size: 3em;
  }
`;

export {
  MetalFlowerHelpModal
};
