/**
 * Does the player win the prophecy ?
 * @param player the given player
 * @param prophecy The prophecy
 * @return boolean indicating that the prophecy is won or not
 */
import { Player } from '../player/Player';
import { Prophecy } from '../material/prophecy/Prophecy';
import { Building } from '../material/building/Building';

export const isPlayerWinProphecy = (player: Player, prophecy: Prophecy, buildings: Building[]) => {
  return prophecy.canPlayerGet(player, buildings);
};
