/** @jsxImportSource @emotion/react */
import { Player } from '@gamepark/dunaia/player/Player';
import Color from '@gamepark/dunaia/color/Color';
import { FC } from 'react';
import { DunaiaCoordinate, DunaiaPosition } from '../dunaia/DunaiaPosition';
import { PlayerBoardSpace } from './PlayerBoardSpace';

type PlayerBoardSpacesProps = {
  player: Player;
  activeColor: Color;
  selectedDunaia?: number;
};

const PlayerBoardSpaces: FC<PlayerBoardSpacesProps> = (props) => {
  const { player, activeColor, selectedDunaia } = props;

  return (
    <>
      {DunaiaPosition.map((coordinates: DunaiaCoordinate, index) => (
        <PlayerBoardSpace
          key={index}
          index={index}
          activeColor={activeColor}
          player={player}
          coordinates={coordinates}
          selectedDunaia={selectedDunaia} />
      ))}
    </>
  );
};

export { PlayerBoardSpaces };
