import { GameEffectRules } from './GameEffectRules';
import { GainMemoryChipsEffect } from '../GainMemoryChipsEffect';
import GameState from '../../GameState';
import { Player } from '../../player/Player';
import Move from '../../moves/Move';
import { getCombinations } from '../../utils/building.utils';
import { gainMemoryChipsMove } from '../../moves/GainMemoryChips';

class GainMemoryChipsEffectRules extends GameEffectRules<GainMemoryChipsEffect>{
  constructor(effect: GainMemoryChipsEffect) {
    super(effect);
  }

  legalMoves(_state: GameState, player: Player): Move[] {
    if (this.effect.count === 0 || this.effect.automatic) {
      return [];
    }

    const allowedMemoryChips = [1, 2, 3, 4, 5, 6].filter((m) => !player.memoryChips.includes(m));
    return getCombinations(allowedMemoryChips, this.effect.count || 1).map((m: number[]) => gainMemoryChipsMove(m));
  }

  automaticMoves(): Move[] {
    if (this.effect.automatic && this.effect.memoryChip) {
      return [gainMemoryChipsMove([this.effect.memoryChip])];
    }

    return [];
  }
}

export { GainMemoryChipsEffectRules };
