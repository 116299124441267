import { BuildingEffectType } from './BuildingEffectType';
import { GameEffects } from '../../effect/GameEffect';
import { BuildingEffectCondition } from './BuildingEffectCondition';
import { Player } from '../../player/Player';
import { Position } from '../../common/Position';
import { PlayerBuilding } from '../../player/PlayerBuilding';
import { hasDice, isBuildingOfColor, isBuiltBuilding } from '../../utils/building.utils';
import { Direction } from '../../common/Direction';
import { Buildings } from './Buildings';

export type BuildingEffect = {
  type: BuildingEffectType;
  effect: GameEffects;
  condition?: BuildingEffectCondition;
};

class BaseBuildingEffect {
  type: BuildingEffectType;
  condition?: BuildingEffectCondition;

  constructor(type: BuildingEffectType, condition?: BuildingEffectCondition) {
    this.type = type;
    this.condition = condition;
  }

  isConditionValid = (buildings: (PlayerBuilding | undefined)[][], position: Position) => {
    if (!this.condition) {
      return true;
    }

    if (!position) {
      throw new Error('Impossible to extract building position');
    }

    let otherBuilding = undefined;
    switch (this.condition.direction) {
      case Direction.Top:
        otherBuilding = buildings[position.x][position.y - 1];
        break;
      case Direction.Right:
        otherBuilding = buildings[position.x + 1]?.[position.y];
        break;
      case Direction.Bottom:
        otherBuilding = buildings[position.x][position.y + 1];
        break;
      case Direction.Left:
        otherBuilding = buildings[position.x - 1]?.[position.y];
        break;
    }

    return isBuiltBuilding(otherBuilding) && isBuildingOfColor(otherBuilding, Buildings, this.condition.color);
  };
}

class RecyclingBuildingEffect extends BaseBuildingEffect {
  type: BuildingEffectType.Recycling = BuildingEffectType.Recycling;
  effect: GameEffects;

  constructor(effect: GameEffects, condition?: BuildingEffectCondition) {
    super(BuildingEffectType.Recycling, condition);
    this.effect = effect;
  }

  public isActive = (players: Player[], playerBuilding: PlayerBuilding) => {
    return (
      !playerBuilding.constructionToken &&
      !hasDice(playerBuilding.building, players)
    );
  };
}

class StandardBuildingEffect extends BaseBuildingEffect {
  type: BuildingEffectType.DunaiaAwakening = BuildingEffectType.DunaiaAwakening;
  effect: GameEffects;

  constructor(effect: GameEffects, condition?: BuildingEffectCondition) {
    super(BuildingEffectType.DunaiaAwakening, condition);
    this.effect = effect;
  }

  public isActive = (buildings: (PlayerBuilding | undefined)[][], playerBuilding: PlayerBuilding, position: Position) => {
    return !playerBuilding.constructionToken && this.isConditionValid(buildings, position);
  };
}

class ScoringBuildingEffect extends BaseBuildingEffect {
  type: BuildingEffectType.Scoring = BuildingEffectType.Scoring;
  score: number;

  constructor(score: number, condition: BuildingEffectCondition) {
    super(BuildingEffectType.Scoring, condition);
    this.score = score;
  }

  public isActive = (buildings: (PlayerBuilding | undefined)[][], playerBuilding: PlayerBuilding, position: Position) => {
    return !playerBuilding.constructionToken && this.isConditionValid(buildings, position);
  };
}

export { RecyclingBuildingEffect, StandardBuildingEffect, ScoringBuildingEffect };
