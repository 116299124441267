import MoveType from './MoveType';
import { Position } from '../common/Position';
import GameState from '../GameState';
import { Player } from '../player/Player';
import { BoardBuildingPrices } from '../material/board/BoardBuildingPrices';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';

export type BuyBuilding = {
  type: MoveType.BuyBuilding;
  building: number;
  target: Position;
};

export const buyBuildingMove = (building: number, target: Position): BuyBuilding => ({
  type: MoveType.BuyBuilding,
  building,
  target,
});

export const buyBuilding = (state: GameState, move: BuyBuilding) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  state.buildings.forEach((col, x) => col.forEach((_, y) => applyBuyBuilding(state, player, { x, y }, move)));
};

const applyBuyBuilding = (state: GameState, player: Player, position: Position, move: BuyBuilding) => {
  const pile = state.buildings[position.x][position.y];
  if (!pile.includes(move.building)) {
    return;
  }

  const building = pile.find((b) => b === move.building);
  if (!player.constructionTokens) {
    throw new Error('You are trying to buy a construction token while you have not more tokens...');
  }

  if (building === undefined) {
    throw new Error('You are currently trying to get a building on the wrong place...');
  }

  player.constructionTokens--;
  player.metalFlowers -= BoardBuildingPrices[position.x][position.y];
  player.buildings[move.target.x][move.target.y] = {
    building: building,
    constructionToken: 1,
    additionalColors: [],
  };

  state.buildings[position.x][position.y] = pile.filter((b) => b !== move.building);
  if (position.x === 1 && state.buildings[0][position.y].length) {
    state.buildings[position.x][position.y].push(state.buildings[0][position.y].shift()!);
  }

  if (!state.last && player.buildings.flat().every((b) => b !== undefined && b !== null && !b.constructionToken)) {
    state.last = state.round + 1;
  }

  shiftFirstEffectOfType(player.pending, GameEffectType.BuyBuilding);
};
