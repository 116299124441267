export type DunaiaCoordinate = {
  top: number;
  left: number;
};

export const DunaiaPosition: DunaiaCoordinate[] = [
  { top: 19.2, left: 4.9 }, // 0
  { top: 19.2, left: 11.8 }, // 1
  { top: 19.2, left: 18.7 }, // 2

  { top: 19.2, left: 34.7 }, // 3
  { top: 19.2, left: 41.6 }, // 4
  { top: 19.2, left: 48.5 }, // 5

  { top: 19.2, left: 64.8 }, // 6
  { top: 19.2, left: 71.4 }, // 7
  { top: 19.2, left: 78.4 }, // 8

  { top: 40.8, left: 4.9 }, // 9
  { top: 40.8, left: 11.8 }, // 10
  { top: 40.8, left: 18.7 }, // 11

  { top: 40.8, left: 34.7 }, // 12
  { top: 40.8, left: 41.6 }, // 13
  { top: 40.8, left: 48.5 }, // 14

  { top: 40.8, left: 64.8 }, // 15
  { top: 40.8, left: 71.4 }, // 16
  { top: 40.8, left: 78.4 }, // 17

  { top: 2.7, left: 26.4 }, // 18
  { top: 8.7, left: 26.4 }, // 19
  { top: 14.5, left: 26.4 }, // 20

  { top: 24.2, left: 26.4 }, // 21
  { top: 30.2, left: 26.4 }, // 22
  { top: 36.1, left: 26.4 }, // 23

  { top: 45.6, left: 26.4 }, // 24
  { top: 51.6, left: 26.4 }, // 25
  { top: 57.6, left: 26.4 }, // 26

  { top: 2.7, left: 56.6 }, // 27
  { top: 8.7, left: 56.6 }, // 28
  { top: 14.5, left: 56.6 }, // 29

  { top: 24.2, left: 56.6 }, // 30
  { top: 30.2, left: 56.6 }, // 31
  { top: 36.1, left: 56.6 }, // 32

  { top: 45.6, left: 56.6 }, // 33
  { top: 51.6, left: 56.6 }, // 34
  { top: 57.6, left: 56.6 }, // 35
];
