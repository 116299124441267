/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { Avatar } from '@gamepark/react-client';
import Color from '@gamepark/dunaia/color/Color';
import { avatarHeight, avatarWidth } from '../../styles/Style';
import { HtmlColors } from '../common/HtmlColors';
import { Player } from '@gamepark/dunaia/player/Player';

type PlayerAvatarProps = {
  player: Player;
} & HTMLAttributes<HTMLDivElement>

const PlayerAvatar: FC<PlayerAvatarProps> = (props) => {
  const { player } = props;
  return (
    <div css={avatarContainer(player.color)}{ ...props }>
      <Avatar playerId={player.color} css={avatar} />
    </div>
  );
}

const avatarContainer = (color: Color) => css`
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 1em 0.2em black;
  width: ${avatarWidth}em;
  height: ${avatarHeight}em;
  background-color: ${`rgba(${HtmlColors.get(color)!.rgba}, 0.78)`};
`;

const avatar = css`
  position: absolute;
  top: 1em;
  left: 1em;
  width: 5em;
  height: 5em;
`;

export {
  PlayerAvatar
}
