import MoveType from './MoveType';
import GameState from '../GameState';
import GameView from '../GameView';
import { Phase } from '../common/Phase';

export type ChooseDice = {
  type: MoveType.ChooseDice;
  dice: number;
};

export const chooseDiceMove = (dice: number): ChooseDice => ({
  type: MoveType.ChooseDice,
  dice,
});

export const chooseDice = (state: GameState | GameView, move: ChooseDice) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;
  const dice = state.dice[move.dice];
  player.dice.push({
    dice,
  });

  state.dice = state.dice.filter((_, index) => index !== move.dice);
  player.phase = Phase.DunaiaAwakening;
};
