export const BuyBuildingDuration = 1.5;
export const BuyBuildingRefillDuration = 1;
export const AddConstructionTokenDuration = 1;
export const BuyBuildingRefillPercent = (column: number) => {
  if (column === 0) {
    return 0;
  }
  return BuyBuildingRefillDuration / (BuyBuildingDuration + BuyBuildingRefillDuration + AddConstructionTokenDuration);
};

export const AddConstructionTokenPercent = (column: number) =>
  AddConstructionTokenDuration /
  (BuyBuildingDuration + (column === 1 ? BuyBuildingRefillDuration : 0) + AddConstructionTokenDuration);

export const BuildBuildingDuration = 1;
export const RemoveConstructionTokenDuration = 1;
export const RemoveConstructionTokenPercent =
  RemoveConstructionTokenDuration / (RemoveConstructionTokenDuration + BuildBuildingDuration);
