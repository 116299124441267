/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from '@gamepark/dunaia/player/Player';
import { getPlayerScore } from '@gamepark/dunaia/utils/is-over.utils';

type TotalScoreProps = {
  player: Player;
  rank?: number;
};

const TotalScore: FC<TotalScoreProps> = (props) => {
  const { player } = props;
  const { t } = useTranslation();

  const totalScore = getPlayerScore(player);
  return (
    <div css={subtitle}>
      <span>{t('scoring.total')}</span>
      <div css={visuals} />
      <div css={score}>
        <span>=&nbsp; { totalScore }</span>
      </div>
    </div>
  );
};

const subtitle = css`
  grid-column: span 24;
  padding: 1em;
  border-radius: 0.6em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: gold;
  border: 0.06em solid gray;

  > span {
    font-size: 4em;
    text-align: left;
    font-weight: bold;
    flex: 2;
  }
`;
const visuals = css`
  display: flex;
  flex: 2;
  align-items: center;

  > span {
    font-size: 3em;
    margin-left: 1.1em;
  }
`

const score = css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > span {
    font-size: 4em;
    font-family: monospace;
  }
`
export { TotalScore };
