/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, useState } from 'react';
import { BuildingTile } from '../building/BuildingTile';
import { selectBuildingMove } from '../../moves/SelectBuilding';
import {
  buildingRatio,
  buyBuildingToPlayerBoardTranslateX,
  buyBuildingToPlayerBoardTranslateY,
  buyBuildingToPlayerPanelTranslateX,
  buyBuildingToPlayerPanelTranslateY,
  mainBoardBuildingLeft,
  mainBoardBuildingTop,
  mainBoardBuildingTranslateZ,
  playerPanelHeight,
  refillBuildingTranslateX,
  shineEffect
} from '../../styles/Style';
import last from 'lodash/last';
import first from 'lodash/first';
import { isPlayedDisplayed } from '@gamepark/dunaia/utils/player.utils';
import { Animation, useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { BuyBuilding } from '@gamepark/dunaia/moves/BuyBuilding';
import { isBuyBuilding } from '@gamepark/dunaia/utils/is-move.utils';
import { canBuyBuilding, canBuyOneBuilding, getBuyableBuildings } from '@gamepark/dunaia/utils/building.utils';
import Color from '@gamepark/dunaia/color/Color';
import { Player } from '@gamepark/dunaia/player/Player';
import { AddConstructionTokenPercent, BuyBuildingRefillPercent } from '../../animations/AnimationConstants';
import { Draggable } from '@gamepark/react-components';
import { mainBoardBuilding } from '../../draggable';
import { selectToBuyBuildingMove } from '../../moves/SelectForBuyingBuilding';
import Move from '@gamepark/dunaia/moves/Move';

type MainBoardBuildingProps = {
  building: number;
  level: number;
  row: number[];
  col: number;
  z: number;
  buildings: number[][][];
  displayedColor?: Color;
  activePlayer: Player;
  activePlayerPanelIndex: number;
  toBuyBuilding?: number;
}

const MainBoardBuilding: FC<MainBoardBuildingProps> = (props) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const { level, col, row, z, activePlayer, building, toBuyBuilding, displayedColor, activePlayerPanelIndex, buildings } = props;
  const animation = useAnimation<BuyBuilding>((a) => isBuyBuilding(a.move));
  const itsMe = !displayedColor || displayedColor === playerId;
  const [isDragging, setDragging] = useState(false)

  const canBuyOneOrMoreBuilding = row.length === 1? canBuyBuilding(activePlayer): (canBuyOneBuilding(activePlayer))
  const isOnTop = row[0] === building;
  const iCanBuy = canBuyOneOrMoreBuilding && activePlayer.color === playerId;
  const buyableBuildings = iCanBuy ? getBuyableBuildings(activePlayer.metalFlowers, buildings) : [];
  const isBuyable = !animation && iCanBuy && isOnTop && buyableBuildings.includes(building);
  const draggableObject = mainBoardBuilding(building);

  const onDrag = () => {
    if (!isBuyable) {
      return;
    }
    setDragging(true);
    play(selectToBuyBuildingMove(building), { local: true });
    return draggableObject;
  };

  const onEndDrag = (_: any, monitor: any) =>{
    if(!monitor.didDrop()) {
        setDragging(false);
    }
    play(selectToBuyBuildingMove(), { local: true });
  }
  const onDrop = (move: Move) => {
    if (move) {
      play(move);
    }
  };

  const selectBuilding = (building: number) => {
    if (animation || !isOnTop) {
      return;
    }
    setDragging(false);
    play(selectBuildingMove(building), { local: true });
  };

  const getTranslateZ = (row: number[], building: number, zIndex: number) => {
      return mainBoardBuildingTranslateZ(row.length - zIndex) + (toBuyBuilding !== building ? 0 : 2);
  };

  const getBuyAnimation = () => {
    if (!isAnimationToDisplayedPlayer(building)) {
      return;
    }

    if (isDragging) {
      return translateBuildingToPlayerBoard(
        animation!,
        level,
        col
      );
    }

    return animateBuyBuildingToPlayerBoard(
      animation!,
      level,
      col,
      itsMe,
      getTranslateZ(row, building, z)
    )
  }

  const isAnimationRefill = (b: number, level: number) =>
    animation && animation.move.building === last(buildings[1][level]) && b === first(buildings[0][level]);
  const isAnimationToDisplayedPlayer = (b: number) =>
    animation && animation.move.building === b && isPlayedDisplayed(displayedColor, activePlayer.color, playerId);
  const isAnimationToPlayerPanel = (b: number) =>
    animation && animation.move.building === b && !isPlayedDisplayed(displayedColor, activePlayer.color, playerId);
  return (
    <Draggable
      key={building}
      type={draggableObject.type}
      item={onDrag}
      canDrag={isBuyable}
      drop={onDrop}
      end={onEndDrag}
      css={ [
          buildingPosition(level, col),
          !animation && !z && viewableBuilding,
          !animation && toBuyBuilding === building && isSelected,
          getBuyAnimation(),
          isAnimationRefill(building, level) &&
          animateBuyBuildingRefill(animation!, getTranslateZ(row, building, z)),
          isAnimationToPlayerPanel(building) &&
          animateBuyBuildingToPlayerPanel(
            animation!,
            activePlayerPanelIndex,
            level,
            col,
            itsMe,
            getTranslateZ(row, building, z)
          )
      ] }
      onClick={ () => selectBuilding(building) }
      preTransform={ `translateZ(${ getTranslateZ(row, building, z) }em)` }>
      <BuildingTile
        draggable="false"
        key={ building }
        building={ building }
      />
      { isBuyable && !isDragging && !toBuyBuilding && <div key={`${building}-shine`} css={[shineEffect(), buildingShine]} /> }
    </Draggable>

  );
};

const buildingShine = css`
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  border-radius: 1em;
`

const buyBuildingToPlayerPanel = (
  activePlayerPanelIndex: number,
  level: number,
  column: number,
  itsMe: boolean,
  additionalTranslateZ: number
) => {
  const translateX = buyBuildingToPlayerPanelTranslateX(activePlayerPanelIndex, column);
  const translateY = buyBuildingToPlayerPanelTranslateY(activePlayerPanelIndex, level);
  return keyframes`
    10% {
      transform: translate(${ translateX * 0.1 }em, ${ translateY * 0.1 }em) translateZ(${
              additionalTranslateZ + (itsMe ? 0 : 2) + 3.5
      }em);
    }
    30% {
      transform: translate(${ translateX * 0.3 }em, ${ translateY * 0.3 }em) translateZ(10em);
    }
    70% {
      height: ${ playerPanelHeight }em;
      width: ${ playerPanelHeight * buildingRatio }em;
      transform: translate(${ translateX }em, ${ translateY }em) translateZ(0);
    }
    to {
      height: ${ playerPanelHeight }em;
      width: ${ playerPanelHeight * buildingRatio }em;
      transform: translate(${ translateX }em, ${ translateY }em) translateZ(0) scale(0);
    }
  `;
};

const animateBuyBuildingToPlayerPanel = (
  animation: Animation<BuyBuilding>,
  activePlayerPanelIndex: number,
  level: number,
  column: number,
  itsMe: boolean,
  additionalTranslateZ: number
) => {
  const animationDuration =
    animation.duration * (1 - BuyBuildingRefillPercent(column) - AddConstructionTokenPercent(column));
  return css`
    animation: ${ buyBuildingToPlayerPanel(activePlayerPanelIndex, level, column, itsMe, additionalTranslateZ) } ${ animationDuration }s linear forwards;
  `;
};

const buyBuildingToPlayerBoard = (
  move: BuyBuilding,
  level: number,
  column: number,
  itsMe: boolean,
  additionalTranslateZ: number
) => {
  const translateX = buyBuildingToPlayerBoardTranslateX(move.target, column);
  const translateY = buyBuildingToPlayerBoardTranslateY(move.target, level);
  return keyframes`
    10% {
      transform: translate(${ translateX * 0.1 }em, ${ translateY * 0.1 }em) translateZ(${
              additionalTranslateZ + (itsMe ? 0 : 2) + 3.5
      }em);
    }
    30% {
      transform: translate(${ translateX * 0.3 }em, ${ translateY * 0.3 }em) translateZ(10em);
    }
    90% {
      transform: translate(${ translateX }em, ${ translateY }em) translateZ(10em);
    }
    to {
      transform: translate(${ translateX }em, ${ translateY }em) translateZ(0.1em);
    }
  `;
};

const translateBuildingToPlayerBoard = (
  animation: Animation<BuyBuilding>,
  level: number,
  column: number
) => {
  const translateX = buyBuildingToPlayerBoardTranslateX(animation.move.target, column);
  const translateY = buyBuildingToPlayerBoardTranslateY(animation.move.target, level);

  return css`
    transition: transform 1s;
    transform: translate(${translateX}em, ${translateY}em);
  `;
}

const animateBuyBuildingToPlayerBoard = (
  animation: Animation<BuyBuilding>,
  level: number,
  column: number,
  itsMe: boolean,
  additionalTranslateZ: number
) => {
  const animationDuration =
    animation.duration * (1 - BuyBuildingRefillPercent(column) - AddConstructionTokenPercent(column));
  return css`
    animation: ${ buyBuildingToPlayerBoard(animation.move, level, column, itsMe, additionalTranslateZ) } ${ animationDuration }s linear forwards;
  `;
};

const buildingPosition = (level: number, col: number) => css`
  position: absolute;
  top: ${ mainBoardBuildingTop(level) }em;
  left: ${ mainBoardBuildingLeft(col) }em;
  border-radius: 1em;
  
  &:hover {
    filter: brightness(110%);
  }
`;
const isSelected = css`
  box-shadow: 0 0 0 0.7em green;
`;

const viewableBuilding = css`
  cursor: pointer;
`;

const refillBuilding = (baseTranslateZ: number) => {
  const translateX = refillBuildingTranslateX();
  return keyframes`
    from {
      transform: translateZ(${ baseTranslateZ }em)
    }
    75% {
      transform: translateX(${ translateX * 0.75 }em) translateZ(${ baseTranslateZ }em);
    }
    to {
      transform: translateX(${ translateX }em) translateZ(0);
    }
  `;
};

const animateBuyBuildingRefill = (animation: Animation<BuyBuilding>, baseTranslateZ: number) => {
  const animationDuration = animation.duration * BuyBuildingRefillPercent(1);
  const animationDelay = animation.duration * (1 - BuyBuildingRefillPercent(1));
  return css`
    animation: ${ animationDuration * 0.8 }s ${ refillBuilding(baseTranslateZ) } ${ animationDelay + animationDuration * 0.2 }s linear forwards;
  `;
};

export {
  MainBoardBuilding
};
