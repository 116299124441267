/**
 * Enumeration of all the types of Move in you game.
 * Even though it is not strictly required to use a type like that, it helps a lot in practice!
 */

enum MoveType {
  ChooseDice = 1,
  MoveDunaia,
  RecycleDice,
  RetrieveProphecy,
  GainMetalFlowers,
  GainMemoryChips,
  BuyBuilding,
  MoveConstructionToken,
  PlaceColorToken,
  CallElder,
  GainArtefactPart,
  RollDice,
  EndTurn,
  AwakeDunaia,
}

export default MoveType;
