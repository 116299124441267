/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Images } from '../../images/Images';
import { useTranslation } from 'react-i18next';

type IconHelpProps = {
  onClose: () => void;
};

const IconHelp: FC<IconHelpProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  const viewer = (
    <>
      <div css={overlay} onClick={onClose} />
      <div css={actionStyle} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div css={modal}>
        <div css={header}>{t('icons.help.header')}</div>
        <div css={iconTable}>
        { IconHelps.map((icon, index) => (
          <div key={index} css={iconRow}>
              <div css={[iconCell, iconIcon(icon.icon)]} />
              <div css={[iconCell, iconDescription]}>{t(icon.description)}</div>
          </div>
          )
        )}
        </div>
      </div>
    </>
  )


  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(viewer, letterBox);
  }

  return null;
}

const IconHelps: { icon: any; description: string }[] = [
  { icon: Images.AnyMemoryChipIcon, description: 'icons.help.any-memory-chip' },
  { icon: Images.BuildingIcon, description: 'icons.help.building' },
  { icon: Images.DunaiaMemoryChipIcon, description: 'icons.help.dunaia-memory-chip' },
  { icon: Images.MetalFlowerIcon, description: 'icons.help.metal-flower' },
  { icon: Images.MoveDunaiaIcon, description: 'icons.help.move-dunaia' },
  { icon: Images.MoveToken, description: 'icons.help.move-token' },
]

const modal = css`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 58em;
  border-radius: 2em;
  transform: translate(-50%, -50%) translateZ(50em);
  background-color: white;
  border: 0.5em solid black;
  box-shadow: 0 0 1em white;
  padding-bottom: 1em;
`;

const header = css`
  width: calc(100% - 2em);
  margin: 1em 1em 0 1em;
  padding-bottom: 1em;
  font-size: 2.5em;
  text-transform: uppercase;
  text-align: center;
  color: black;
  border-bottom: 0.1em solid black;
`

const iconTable = css`
  display: table;
  width: 90%;
  margin: 5%;
`

const iconRow = css`
  display: table-row;
  height: 8em;
`

const iconCell = css`
  display: table-cell;
`

const iconIcon = (image: any) => css`
  width: 20%;
  background: url(${image}) no-repeat center 1em;
  background-size: 8em 8em;
`

const iconDescription = css`
  width: 80%;
  font-size: 2.5em;
  color: black;
  padding-top: 1.5em;
`;

const overlay = css`
  position: absolute;
  top: -1000%;
  bottom: -1000%;
  left: -1000%;
  right: -1000%;
  background-color: rgba(0, 0, 0, 0.77);
  cursor: pointer;
  transform: translateZ(50em);
`;

const actionStyle = css`
  border-radius: 50%;
  background-color: white;
  padding: 0.5em;
  height: 7em;
  width: 7em;
  position: absolute;
  top: 15em;
  right: 20em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateZ(50em);
  
  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 4em;
  }
`;

export {
  IconHelp
}
