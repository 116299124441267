/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { Building } from '@gamepark/dunaia/material/building/Building';
import {
  Buildings,
  Level1GreenBuilding1,
  Level1GreenBuilding2,
  Level1GreenBuilding3,
  Level1GreenBuilding4,
  Level1PurpleBuilding1,
  Level1PurpleBuilding2,
  Level1PurpleBuilding3,
  Level1PurpleBuilding4,
  Level1RedBuilding1,
  Level1RedBuilding2,
  Level1RedBuilding3,
  Level1RedBuilding4,
  Level1YellowBuilding1,
  Level1YellowBuilding2,
  Level1YellowBuilding3,
  Level1YellowBuilding4,
  Level2GreenBuilding1,
  Level2GreenBuilding2,
  Level2GreenBuilding3,
  Level2PurpleBuilding1,
  Level2PurpleBuilding2,
  Level2PurpleBuilding3,
  Level2RedBuilding1,
  Level2RedBuilding2,
  Level2RedBuilding3,
  Level2YellowBuilding1,
  Level2YellowBuilding2,
  Level2YellowBuilding3,
  Level3GreenBuilding1,
  Level3GreenBuilding2,
  Level3MultiBuildings,
  Level3PurpleBuilding1,
  Level3PurpleBuilding2,
  Level3RedBuilding1,
  Level3RedBuilding2,
  Level3YellowBuilding1,
  Level3YellowBuilding2
} from '@gamepark/dunaia/material/building/Buildings';
import { Images } from '../../images/Images';
import { buildingHeight, buildingWidth } from '../../styles/Style';

export type BuildingTileProps = {
  building: number;
  constructed?: boolean;
  preTransform?: string;
  scale?: number;
} & HTMLAttributes<HTMLDivElement>;

const BuildingTile: FC<BuildingTileProps> = (props) => {
  const { building, constructed, preTransform, scale, ...rest } = props;
  const b = Buildings[building];
  return (
    <div draggable="false" css={[style(scale), !constructed && hidden(preTransform)]} {...rest}>
      <div css={[frontFace(!!constructed, b), !!props.onClick && selectable]} />
      <div css={[backFace(!!constructed, b), !!props.onClick && selectable]} />
    </div>
  );
};

const selectable = css`
  &:hover {
    filter: brightness(110%);
  }
`;

const style = (scale?: number) => css`
  height: ${buildingHeight * (scale || 1)}em;
  width: ${buildingWidth * (scale || 1)}em;
  border-radius: 1em;
  transform-style: preserve-3d;
  transform: translateZ(0);
`;

const backFace = (constructed: boolean, building: Building) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1em;
  background-size: cover;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  border: 0.1em solid white;
  ${!constructed ? 'box-shadow: 0 0 0.2em 0.1em #000000;' : ''}
  // noinspection CssInvalidPropertyValue
  image-rendering: -webkit-optimize-contrast;
  background-image: url(${BuildingImagesBack.get(building)});
`

const frontFace = (constructed: boolean, building: Building) => css`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-size: cover;
  transform-style: preserve-3d;
  border-radius: 1em;
  border: 0.1em solid white;
  ${constructed ? 'box-shadow: 0 0 0.2em 0.1em #000000;' : ''}
  // noinspection CssInvalidPropertyValue
  image-rendering: -webkit-optimize-contrast;
  background-image: url(${BuildingImagesFront.get(building)});
`;

const hidden = (preTransform?: string) => css`
  transform: ${preTransform || ''} rotateY(180deg);
`;

const BuildingImagesBack = new Map<Building, any>();
BuildingImagesBack.set(Level1GreenBuilding1, Images.Level1GreenBuilding1Back);
BuildingImagesBack.set(Level1GreenBuilding2, Images.Level1GreenBuilding2Back);
BuildingImagesBack.set(Level1GreenBuilding3, Images.Level1GreenBuilding3Back);
BuildingImagesBack.set(Level1GreenBuilding4, Images.Level1GreenBuilding4Back);
BuildingImagesBack.set(Level1YellowBuilding1, Images.Level1YellowBuilding1Back);
BuildingImagesBack.set(Level1YellowBuilding2, Images.Level1YellowBuilding2Back);
BuildingImagesBack.set(Level1YellowBuilding3, Images.Level1YellowBuilding3Back);
BuildingImagesBack.set(Level1YellowBuilding4, Images.Level1YellowBuilding4Back);
BuildingImagesBack.set(Level1PurpleBuilding1, Images.Level1PurpleBuilding1Back);
BuildingImagesBack.set(Level1PurpleBuilding2, Images.Level1PurpleBuilding2Back);
BuildingImagesBack.set(Level1PurpleBuilding3, Images.Level1PurpleBuilding3Back);
BuildingImagesBack.set(Level1PurpleBuilding4, Images.Level1PurpleBuilding4Back);
BuildingImagesBack.set(Level1RedBuilding1, Images.Level1RedBuilding1Back);
BuildingImagesBack.set(Level1RedBuilding2, Images.Level1RedBuilding2Back);
BuildingImagesBack.set(Level1RedBuilding3, Images.Level1RedBuilding3Back);
BuildingImagesBack.set(Level1RedBuilding4, Images.Level1RedBuilding4Back);
BuildingImagesBack.set(Level2GreenBuilding1, Images.Level2GreenBuilding1Back);
BuildingImagesBack.set(Level2GreenBuilding2, Images.Level2GreenBuilding2Back);
BuildingImagesBack.set(Level2GreenBuilding3, Images.Level2GreenBuilding3Back);
BuildingImagesBack.set(Level2YellowBuilding1, Images.Level2YellowBuilding1Back);
BuildingImagesBack.set(Level2YellowBuilding2, Images.Level2YellowBuilding2Back);
BuildingImagesBack.set(Level2YellowBuilding3, Images.Level2YellowBuilding3Back);
BuildingImagesBack.set(Level2PurpleBuilding1, Images.Level2PurpleBuilding1Back);
BuildingImagesBack.set(Level2PurpleBuilding2, Images.Level2PurpleBuilding2Back);
BuildingImagesBack.set(Level2PurpleBuilding3, Images.Level2PurpleBuilding3Back);
BuildingImagesBack.set(Level2RedBuilding1, Images.Level2RedBuilding1Back);
BuildingImagesBack.set(Level2RedBuilding2, Images.Level2RedBuilding2Back);
BuildingImagesBack.set(Level2RedBuilding3, Images.Level2RedBuilding3Back);
BuildingImagesBack.set(Level3GreenBuilding1, Images.Level3GreenBuilding1Back);
BuildingImagesBack.set(Level3GreenBuilding2, Images.Level3GreenBuilding2Back);
BuildingImagesBack.set(Level3YellowBuilding1, Images.Level3YellowBuilding1Back);
BuildingImagesBack.set(Level3YellowBuilding2, Images.Level3YellowBuilding2Back);
BuildingImagesBack.set(Level3PurpleBuilding1, Images.Level3PurpleBuilding1Back);
BuildingImagesBack.set(Level3PurpleBuilding2, Images.Level3PurpleBuilding2Back);
BuildingImagesBack.set(Level3RedBuilding1, Images.Level3RedBuilding1Back);
BuildingImagesBack.set(Level3RedBuilding2, Images.Level3RedBuilding2Back);
BuildingImagesBack.set(Level3MultiBuildings, Images.Level3MultiBuildingsBack);

const BuildingImagesFront = new Map<Building, any>();
BuildingImagesFront.set(Level1GreenBuilding1, Images.Level1GreenBuilding1Front);
BuildingImagesFront.set(Level1GreenBuilding2, Images.Level1GreenBuilding2Front);
BuildingImagesFront.set(Level1GreenBuilding3, Images.Level1GreenBuilding3Front);
BuildingImagesFront.set(Level1GreenBuilding4, Images.Level1GreenBuilding4Front);
BuildingImagesFront.set(Level1YellowBuilding1, Images.Level1YellowBuilding1Front);
BuildingImagesFront.set(Level1YellowBuilding2, Images.Level1YellowBuilding2Front);
BuildingImagesFront.set(Level1YellowBuilding3, Images.Level1YellowBuilding3Front);
BuildingImagesFront.set(Level1YellowBuilding4, Images.Level1YellowBuilding4Front);
BuildingImagesFront.set(Level1PurpleBuilding1, Images.Level1PurpleBuilding1Front);
BuildingImagesFront.set(Level1PurpleBuilding2, Images.Level1PurpleBuilding2Front);
BuildingImagesFront.set(Level1PurpleBuilding3, Images.Level1PurpleBuilding3Front);
BuildingImagesFront.set(Level1PurpleBuilding4, Images.Level1PurpleBuilding4Front);
BuildingImagesFront.set(Level1RedBuilding1, Images.Level1RedBuilding1Front);
BuildingImagesFront.set(Level1RedBuilding2, Images.Level1RedBuilding2Front);
BuildingImagesFront.set(Level1RedBuilding3, Images.Level1RedBuilding3Front);
BuildingImagesFront.set(Level1RedBuilding4, Images.Level1RedBuilding4Front);
BuildingImagesFront.set(Level2GreenBuilding1, Images.Level2GreenBuilding1Front);
BuildingImagesFront.set(Level2GreenBuilding2, Images.Level2GreenBuilding2Front);
BuildingImagesFront.set(Level2GreenBuilding3, Images.Level2GreenBuilding3Front);
BuildingImagesFront.set(Level2YellowBuilding1, Images.Level2YellowBuilding1Front);
BuildingImagesFront.set(Level2YellowBuilding2, Images.Level2YellowBuilding2Front);
BuildingImagesFront.set(Level2YellowBuilding3, Images.Level2YellowBuilding3Front);
BuildingImagesFront.set(Level2PurpleBuilding1, Images.Level2PurpleBuilding1Front);
BuildingImagesFront.set(Level2PurpleBuilding2, Images.Level2PurpleBuilding2Front);
BuildingImagesFront.set(Level2PurpleBuilding3, Images.Level2PurpleBuilding3Front);
BuildingImagesFront.set(Level2RedBuilding1, Images.Level2RedBuilding1Front);
BuildingImagesFront.set(Level2RedBuilding2, Images.Level2RedBuilding2Front);
BuildingImagesFront.set(Level2RedBuilding3, Images.Level2RedBuilding3Front);
BuildingImagesFront.set(Level3GreenBuilding1, Images.Level3GreenBuilding1Front);
BuildingImagesFront.set(Level3GreenBuilding2, Images.Level3GreenBuilding2Front);
BuildingImagesFront.set(Level3YellowBuilding1, Images.Level3YellowBuilding1Front);
BuildingImagesFront.set(Level3YellowBuilding2, Images.Level3YellowBuilding2Front);
BuildingImagesFront.set(Level3PurpleBuilding1, Images.Level3PurpleBuilding1Front);
BuildingImagesFront.set(Level3PurpleBuilding2, Images.Level3PurpleBuilding2Front);
BuildingImagesFront.set(Level3RedBuilding1, Images.Level3RedBuilding1Front);
BuildingImagesFront.set(Level3RedBuilding2, Images.Level3RedBuilding2Front);
BuildingImagesFront.set(Level3MultiBuildings, Images.Level3MultiBuildingsFront);

export { BuildingTile };
