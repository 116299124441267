import MoveType from './MoveType';
import GameState from '../GameState';
import { shiftFirstEffectOfType } from '../utils/pending-effect.utils';
import { GameEffectType } from '../effect/GameEffect';

export type GainMetalFlowers = {
  type: MoveType.GainMetalFlowers;
  metalFlowers: number;
}

export const gainMetalFlowersMove = (metalFlowers: number): GainMetalFlowers => ({
  type: MoveType.GainMetalFlowers,
  metalFlowers
});

export const gainMetalFlowers = (state: GameState, move: GainMetalFlowers) => {
  const player = state.players.find(p => p.color === state.activePlayer)!;

  player.metalFlowers += move.metalFlowers;

  shiftFirstEffectOfType(player.pending, GameEffectType.GainMetalFlower);
};
