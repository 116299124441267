/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Images } from '../images/Images';

type DunaiaButtonProps = {
  labelKey: string;
  height?: number;
  highlighted?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

const DunaiaButton: FC<DunaiaButtonProps> = (props) => {
  const { labelKey, height, highlighted, disabled, ...rest } = props;
  const { t } = useTranslation();
  return (
    <button css={[buttonStyle(height), highlighted && highlightedButton, disabled && disabledButton]} {...rest}>
      <span>{t(labelKey)}</span>
    </button>
  );
};

const buttonRatio = 400 / 88;
const defaultButtonHeight = 8;
const buttonStyle = (height?: number) => css`
  height: ${height || defaultButtonHeight}em;
  width: ${(height || defaultButtonHeight) * buttonRatio}em;
  background-image: url(${Images.Button});
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding-top: ${1.6 * ((height || defaultButtonHeight) / defaultButtonHeight)}em;

  > span {
    font-size: ${3.5 * ((height || defaultButtonHeight) / defaultButtonHeight)}em;
    text-transform: uppercase;
    font-weight: bold;
  }
`;

const disabledButton = css`
  filter: grayscale(1);
  cursor: not-allowed;
`;

const highlightedButton = css`
  cursor: pointer;
  filter: drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold)
    drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold)
    drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold) drop-shadow(0 0 0.1em gold);

  &:hover {
    filter: drop-shadow(0 0 0.1em green) drop-shadow(0 0 0.1em green) drop-shadow(0 0 0.1em green)
      drop-shadow(0 0 0.1em green) drop-shadow(0 0 0.1em green) drop-shadow(0 0 0.1em green)
      drop-shadow(0 0 0.1em green) drop-shadow(0 0 0.1em green) drop-shadow(0 0 0.1em green)
      drop-shadow(0 0 0.1em green);
  }
`;

export { DunaiaButton };
