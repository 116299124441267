/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import {
  actionAreaHeight,
  actionAreaLeft,
  actionAreaTop,
  actionAreaWidth,
  fadeOutDice,
  mainBoardRecycledDiceLeft,
  mainBoardRecycledDiceTop,
  shineEffect
} from '../../styles/Style';
import { BoardActions } from '@gamepark/dunaia/material/board/BoardActions';
import { Die } from '../dice/Die';
import { canRecycleDieForAction } from '@gamepark/dunaia/utils/board.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import { useAnimation, usePlay, usePlayerId } from '@gamepark/react-client';
import { isRollDice } from '@gamepark/dunaia/utils/is-move.utils';
import { getNonRecycledDie } from '@gamepark/dunaia/utils/dice.utils';
import { recycleDiceOnBoardMove } from '@gamepark/dunaia/moves/RecycleDice';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { useDrop } from 'react-dnd';
import { DragObjectType } from '../../draggable';

type MailBoardActionProps = {
  action: number;
  player: Player;
  buildings: number[][][];
  recycledDice: PlayerDice[];
}

const MainBoardAction: FC<MailBoardActionProps> = (props) => {
  const playerId = usePlayerId();
  const play = usePlay();

  const { player, action, buildings, recycledDice = []} = props;
  const animation = useAnimation();
  const rollDiceAnimation = animation && isRollDice(animation.move)? animation: undefined;
  const canRecycleDie = !animation && playerId === player.color && !!getNonRecycledDie(player.dice) && canRecycleDieForAction(player, buildings, action);

  const recycleDie = () => {
      if (animation || !canRecycleDie) {
        return;
      }

      play(recycleDiceOnBoardMove(action));
  };

  const [{ isOver }, ref] = useDrop({
    accept: DragObjectType.PlayerPanelDice,
    canDrop: () => canRecycleDie,
    drop: () => recycleDiceOnBoardMove(action),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      ref={ref}
      css={actionArea(action)}
      onClick={recycleDie}
    >
      { canRecycleDie && <div css={ [shineEffect(), shining, !action && firstActionShining, action === BoardActions.length - 1 && lastActionShining, isOver && hoverShining] } /> }
      { recycledDice.map((dice, index) => {
        const die = dice.dice;
        return (
          <Die
            key={ index }
            color={ die.color }
            value={ die.value }
            css={ [recycledDicePosition(index, recycledDice.length),
              rollDiceAnimation && fadeOutDice(rollDiceAnimation.duration)] }
          />
        );
      }) }
    </div>
  )
}

const actionArea = (index: number) => css`
  position: absolute;
  height: ${ actionAreaHeight }em;
  width: ${ actionAreaWidth }em;
  top: ${ actionAreaTop }em;
  left: ${ actionAreaLeft(index) }em;
  transform-style: preserve-3d;
  transform: translateZ(0.1em);
`;

const hoverShining = css`
  background-color: rgba(255, 255, 255, 0.8);
`

const shining = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
  cursor: pointer;
  
  &:hover {
    ${hoverShining}
  }
`;

const firstActionShining = css`
  border-bottom-left-radius: 5em;
  transform: translateZ(0);
`;

const lastActionShining = css`
  border-bottom-right-radius: 5em;
  transform: translateZ(0);
`;

const recycledDicePosition = (index: number, count: number) => css`
  position: absolute;
  top: ${ mainBoardRecycledDiceTop(index) }em;
  left: ${ mainBoardRecycledDiceLeft(index) }em;
  pointer-events: none;
  z-index: ${ count - index };
`;

export {
  MainBoardAction
}
