/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC } from 'react';
import { ProphecyTile } from '../prophecy/ProphecyTile';
import {
  mainBoardProphecyLeft,
  mainBoardProphecyTop,
  playerPanelLeft,
  playerPanelTop,
  playerProphecyLeft,
  playerProphecyReducerHeight,
  playerProphecyReducerWidth,
  prophecyTileReducedLeft,
  playerProphecyTop,
  prophecyHeight,
  prophecyWidth
} from '../../styles/Style';
import { Animation, useAnimation, usePlay } from '@gamepark/react-client';
import { Player } from '@gamepark/dunaia/player/Player';
import { isRetrieveProphecy } from '@gamepark/dunaia/utils/is-move.utils';
import { selectProphecyMove } from '../../moves/SelectProphecy';

export type MainBoardPropheciesProps = {
  prophecies: number[];
  activePlayer: Player;
  activePlayerPanelIndex: number;
};

const GameProphecies: FC<MainBoardPropheciesProps> = (props) => {
  const play = usePlay();
  const { activePlayer, activePlayerPanelIndex, prophecies } = props;
  const animation = useAnimation((a) => isRetrieveProphecy(a.move));

  const selectProphecy = (prophecy: number) => {
    play(selectProphecyMove(prophecy), { local: true });
  }

  return (
    <>
      {prophecies.map((p, index) => {
        const animated = animation && animation.move.prophecy === p
        return (
          <div
            key={p}
            css={[prophecyHolder(index), animated && animateProphecy(animation, activePlayerPanelIndex, activePlayer.prophecies.length)]}
          >
            <ProphecyTile key={p} prophecy={p} css={ animated && moveProphecyTile(animation)} onClick={() => selectProphecy(p)} />
          </div>
        );
      })}
    </>
  );
};

const retrieveProphecyAnimation = (activePlayerPanelIndex: number, _index: number) => {
  const targetTop = playerPanelTop(activePlayerPanelIndex) + playerProphecyTop(_index);
  const targetLeft = playerPanelLeft(activePlayerPanelIndex) + playerProphecyLeft;
  return keyframes`
    0% {
      transform: translateZ(10em);
      overflow: hidden;
    }
    70% {
      transform: translateZ(10em);
      overflow: hidden;
    }
    to {
      top: ${targetTop}em;
      left: ${targetLeft}em;
      width: ${playerProphecyReducerWidth}em;
      height: ${playerProphecyReducerHeight}em;
      border-radius: 0.5em;
      box-shadow: 0 0 0.5em 0 black;
      transform: scale(1.3);
      overflow: hidden;
    }
  `;
};

const animateProphecy = (animation: Partial<Animation>, activePlayerPanelIndex: number, index: number) => css`
  animation: ${retrieveProphecyAnimation(activePlayerPanelIndex, index)} ${animation.duration}s linear both;
`

const moveProphecyTileAnimation = keyframes`
  from {
    position: absolute;
    left: 0;
  }
  to {
    position: absolute;
    left: ${prophecyTileReducedLeft}em;
  }
`;

const moveProphecyTile = (animation: Partial<Animation>) => css`
  animation: ${moveProphecyTileAnimation} ${animation.duration}s linear both;
`

const prophecyHolder = (index: number) => css`
  position: absolute;
  transition: top 0.5s;
  top: ${mainBoardProphecyTop(index)}em;
  left: ${mainBoardProphecyLeft(index)}em;
  width: ${prophecyWidth}em;
  height: ${prophecyHeight}em;
  // TODO always be on top of component to prevent animation glitching
  transform: translateZ(10em);
`;

export { GameProphecies };
