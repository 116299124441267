import { GameEffects } from "../effect/GameEffect";
import { GainMemoryChipsEffect } from "../effect/GainMemoryChipsEffect";
import { GainMetalFlowerEffect } from "../effect/GainMetalFlowerEffect";

export const DefaultBoardEffects: GameEffects[][] = [
  [
    new GainMemoryChipsEffect(true),
    new GainMetalFlowerEffect(1),
    new GainMemoryChipsEffect(true),
  ],
  [
    new GainMemoryChipsEffect(true),
    new GainMetalFlowerEffect(1),
    new GainMemoryChipsEffect(true),
  ],
  [
    new GainMemoryChipsEffect(true),
    new GainMetalFlowerEffect(1),
    new GainMemoryChipsEffect(true),
  ],
];
