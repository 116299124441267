import { GameEffect, GameEffectType } from './GameEffect';

export class GainArtefactPartEffect implements GameEffect {
  type: GameEffectType.GainArtefactPart = GameEffectType.GainArtefactPart;
  artefactPart: number;

  constructor(artefactPart: number) {
    this.artefactPart = artefactPart;
  }
}
