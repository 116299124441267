/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { Images } from '../images/Images';
import { useTranslation } from 'react-i18next';
import { DunaiaButton } from '../button/DunaiaButton';


type TutorialModalProps = {
  titleKey: string;
  messageKey: string;
  modalStyle?: any;
  onNext: () => void;
  onPrevious: () => void;
  hasPrevious?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const TutorialModal: FC<TutorialModalProps> = (props) => {
  const { titleKey, messageKey, modalStyle, hasPrevious, onNext, onPrevious} = props;
  const { t } = useTranslation();
  return (
    <div css={[modalContainer, modalAdditionalStyle(modalStyle)]}>
      <div css={modalBackground} />
      <div css={title}><span>{t(titleKey)}</span></div>
      <div css={content}><span>{t(messageKey)}</span></div>
      <div css={buttonsContainer}>
        { hasPrevious && <DunaiaButton css={button} labelKey="button.previous" onClick={onPrevious} height={5} />}
        <DunaiaButton css={button} labelKey="button.validate" onClick={onNext} height={5} />
      </div>
    </div>
  )
}

const modalContainer = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%; // TODO: Get from tuto state
  top: 50%; // TODO: Get from tuto state
  border: 0.5em solid gold;
  border-radius: 2em;
  box-shadow: 0 0 1em black;
  color: white;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding-left: 1em;
  padding-right: 1em;
`;

const modalAdditionalStyle = (additionalStyle: any) => {
  const { transform = 'translate(-50%, -50%)', ...rest} = additionalStyle ?? {};
  return css`
    transform: translateZ(40em) ${transform};
    ${rest}
  `;
}

const modalBackground = css`
  border-radius: 1.4em;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: url(${Images.ModalBackground}) no-repeat;
  background-size: cover;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 1.4em;
  }
`

const title = css`
  flex: 1;
  margin: 0;
  > span {
    font-size: 3.5em;
    font-weight: bold;
  }
  text-align: center;
  padding: 1em 1.5em;
`;

const content = css`
  flex: 3;
  width: 100%;
  color: white;
  > span {
    font-size: 3em;
    white-space: pre-wrap;
  }
  text-align: center;
  padding: 1em 1.5em;
  
`;

const button = css`
  margin: 1em 0;
`

export {
  TutorialModal
}
