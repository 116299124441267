/** @jsxImportSource @emotion/react */
import GameView from '@gamepark/dunaia/GameView';
import { TFunction, useTranslation } from 'react-i18next';
import { getName } from '@gamepark/dunaia/DunaiaOptions';
import { Phase } from '@gamepark/dunaia/common/Phase';
import { Player } from '@gamepark/dunaia/player/Player';
import { GameEffectType } from '@gamepark/dunaia/effect/GameEffect';
import { Animation, useAnimation, usePlayerId, usePlayers } from '@gamepark/react-client';
import Color from '@gamepark/dunaia/color/Color';
import MoveType from '@gamepark/dunaia/moves/MoveType';
import { getNonRecycledDie } from '@gamepark/dunaia/utils/dice.utils';
import { canCallElder } from '@gamepark/dunaia/utils/elder-power.utils';
import { ElderPowers } from '@gamepark/dunaia/material/power/ElderPowers';
import { getWinner, isGameOver } from '@gamepark/dunaia/utils/is-over.utils';

type Props = {
  loading: boolean;
  game?: GameView;
};

export default function HeaderText({ loading, game }: Props) {
  const { t } = useTranslation();
  const playerId = usePlayerId();
  const players = usePlayers<Color>();
  const animation = useAnimation();

  //const playerId = usePlayerId();
  if (loading || ! game) return <>{t('Game loading...')}</>;
  const activePlayer = game.players.find(p => p.color === game.activePlayer)!;
  const itsMe = activePlayer.color === playerId;
  const playerName = getName(game.activePlayer, players, t);

  let message;

  if (isGameOver(game)) {
    const winner = getWinner((game.players))

    if (winner.color === playerId) {
        return <>{t('end-of-game.you')}</>
    }
    return <>{t('end-of-game.other', { player: getName(winner.color, players, t)})}</>
  }

  if (animation) {
    if (itsMe) {
      message = getMyAnimationMessage(animation, t);
    } else {
      message = getOtherAnimationMessage(animation, playerName, t);
    }
  }

  if (message) {
    return <>{message}</>
  }

  if (activePlayer?.pending?.length) {
    if (itsMe) {
      message = getMyPendingEffectMessage(activePlayer, t)
    } else {
      message = getOtherPendingEffectMessage(playerName, activePlayer, t);
    }
  } else {
    if (itsMe) {
      message = getMyPossibleActionMessage(activePlayer, t)
    } else {
      message = getOtherPossibleActionMessage(playerName, activePlayer, t)
    }
  }

  if (message) {
    return <>{message}</>
  }

  if (game.elderPowers.some((e, index) => canCallElder(activePlayer, index, ElderPowers[e]))) {
    message = itsMe? t('player.me.can-call-elder-or-end'): t('player.other.can-call-elder-or-end', { player: playerName })
  }

  if (!message) {
    message = itsMe? t('player.me.end.turn'): t('player.other.end.turn', { player: playerName });
  }

  return <>{message}</>
}

const getMyAnimationMessage = (animation: Animation, t: TFunction): string | undefined => {
  const move = animation.move;
  switch (move.type) {
    case MoveType.GainMemoryChips:
      return t('player.me.gain-memory-chip', { memoryChips: move.memoryChips?.join(','), count: move.memoryChips?.length ?? 1, });
    case MoveType.GainArtefactPart:
      return t('player.me.gain-artefact-part');
    case MoveType.RollDice:
      return t('rolling-dice');
  }

  return;
}

const getOtherAnimationMessage = (animation: Animation, playerName: string, t: TFunction): string | undefined => {
  const move = animation.move;
  switch (move.type) {
    case MoveType.GainMemoryChips:
      return t('player.other.gain-memory-chip', { player: playerName, count: move.memoryChips?.length ?? 1, memoryChips: move.memoryChips?.join(',') });
    case MoveType.GainArtefactPart:
      return t('player.other.gain-artefact-part', { player: playerName });
    case MoveType.RollDice:
      return t('rolling-dice');


  }

  return;
}

const getMyPossibleActionMessage = (player: Player, t: TFunction): string | undefined => {
  const diceToRecycle = getNonRecycledDie(player.dice);

  switch (player.phase) {
    case Phase.ChoosingDie:
      return !diceToRecycle? t('player.me.choose-dice'): undefined;
    case Phase.RecyclingDie:
      return diceToRecycle ? t('player.me.recycling-dice'): undefined;
  }

  return;
}

const getOtherPossibleActionMessage = (playerName: string, player: Player, t: TFunction): string | undefined => {
  const diceToRecycle = getNonRecycledDie(player.dice);
  switch (player.phase) {
    case Phase.ChoosingDie:
      return !diceToRecycle? t('player.other.choose-dice', { player: playerName }): undefined;
     case Phase.RecyclingDie:
      return diceToRecycle ? t('player.other.recycling-dice', { player: playerName }): undefined;
  }

  return;
}

const getMyPendingEffectMessage = (player: Player, t: TFunction): string | undefined => {
  const pending = player.pending[0];
  if (pending) {
    switch (pending.type) {
      case GameEffectType.BuyBuilding:
        return pending.any ? t('player.me.buy-any-building'): t('player.me.buy-one-building')
      case GameEffectType.PlaceColorToken:
        return t('player.me.place-color-token');
      case GameEffectType.GainMemoryChips:
        return t('player.me.choose-memory-chip', { count: pending.count || 1 });
      case GameEffectType.MoveConstructionToken:
        return t('player.me.move-construction-token');
      case GameEffectType.MoveDunaia:
        return t('player.me.move-dunaia', { count: pending.count });
    }
  }

  return;
}

const getOtherPendingEffectMessage = (playerName: string, player: Player, t: TFunction): string | undefined => {
  const pending = player.pending[0];
  if (pending) {
    switch (pending.type) {
      case GameEffectType.BuyBuilding:
        return t(pending.any ? 'player.other.buy-any-building': 'player.other.buy-one-building', { player: playerName });
      case GameEffectType.PlaceColorToken:
        return t('player.other.place-color-token', { player: playerName });
      case GameEffectType.GainMemoryChips:
        return t('player.other.choose-memory-chip', { player: playerName, count: pending.count || 1 });
      case GameEffectType.MoveConstructionToken:
        return t('player.other.move-construction-token', { player: playerName });
      case GameEffectType.MoveDunaia:
        return t('player.other.move-dunaia', { player: playerName, count: pending.count });

    }

  }

  return;
}
