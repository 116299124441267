/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { HelpModal } from './HelpModal';

type ArtefactHelpModalProps = {
  onClose: () => void;
}

const ArtefactHelpModal: FC<ArtefactHelpModalProps> = (props) => {
  const { onClose } = props;
  const { t } = useTranslation();

  const modal = (
      <HelpModal onClose={onClose} title={t('help.artefact.title')}>
        <div css={description}>
          <span>{t('help.artefact.explanation')}</span>
        </div>
        <div css={content}>
          <div css={row}>
            <div css={[column, firstLine]}><span>Artefacts</span></div>
            <div css={[column, firstLine]}><span>1</span></div>
            <div css={[column, firstLine]}><span>2</span></div>
            <div css={[column, firstLine]}><span>3</span></div>
            <div css={[column, firstLine]}><span>4</span></div>
            <div css={[column, firstLine]}><span>...</span></div>
          </div>
          <div css={row}>
            <div css={column}><span>Points</span></div>
            <div css={column}><span>1</span></div>
            <div css={column}><span>3</span></div>
            <div css={column}><span>6</span></div>
            <div css={column}><span>10</span></div>
            <div css={column}><span>+4</span></div>
          </div>
        </div>
      </HelpModal>

  )

  let letterBox = document.getElementById('letterbox')!;
  return createPortal(modal, letterBox);
}

const description = css`
  > span {
    font-size: 3em;
  }
`

const content = css`
  display: table;
  width: 90%;
  border: 0.2em solid black;
  color: black;
  margin-top: 3em;
`;

const row = css`
  display: table-row;
  background-color: white;
  
  &:first-of-type {
    background-color: gray;
    color: white;
  }
`

const firstLine = css`
  color: white;
  border-bottom: 0.2em solid black;
`

const column = css`
  display: table-cell;
  padding: 0.5em;
  
  &:not(:first-of-type) {
    border-left: 0.2em solid black;
  }
  
  &:not(:first-of-type) {
    text-align: center;
  }
  
  &:first-of-type {
    margin-left: 0.2em;
  }
  
  > span {
    font-size: 3em;
  }
`

  export {
    ArtefactHelpModal
  }
