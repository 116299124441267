/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type HelpBackgroundProps = {
  onClose: () => void;
}

const HelpBackground: FC<HelpBackgroundProps> = (props) => {
  const { onClose } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => setVisible(true), 100);
    }
  }, [visible])

  return (
    <>
    <div css={[helpContainer, !visible && hidden]} />

      <div css={[actionStyle, !visible && hidden]} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </>
  )
}

const hidden = css`
  opacity: 0;
`;

const helpContainer = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(50em);
  height: 80em;
  width: 80%;
  background-color: lightgray;
  border-radius: 2em;
  opacity: 1;
  transition: opacity 0.5s;
`;

const actionStyle = css`
  border-radius: 50%;
  background-color: white;
  padding: 0.5em;
  height: 7em;
  width: 7em;
  position: absolute;
  top: 11.5em;
  right: 20em;
  border: 0.1em solid black;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;
  transform: translateZ(50em);

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 4em;
  }
`;

export {
  HelpBackground
}
