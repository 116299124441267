/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, useEffect, useState } from 'react';
import { Buildings } from '@gamepark/dunaia/material/building/Buildings';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

type BuildingHelpContentProps = {
  building: number;
  onClose: () => void;
}

const BuildingHelpContent: FC<BuildingHelpContentProps> = (props) => {
  const { t } = useTranslation();
        const { building: buildingNumber } = props;
  const building = Buildings[buildingNumber];
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => setVisible(true), 100);
    }
  }, [visible])

  const modal = (
    <div css={[helpContainer, !visible && hidden]}>
      <div css={levelLine}></div>
      <div css={levelText}><span css={text}>{t('building.help.level')}</span></div>
      <div css={constructionLine}></div>
      <div css={constructionText}><span css={text}>{t('building.help.construction-token')}</span></div>
      <div css={constructionFaceText}><span css={text}>{t('building.help.construction-face')}</span></div>
      <div css={builtFaceText}><span css={text}>{t('building.help.built-face')}</span></div>
      { building.level === 1 && <div css={recyclingLine} /> }
      { building.level === 1 && <div css={recyclingText}><span css={text}>{t('building.help.recycling-bonus')}</span></div> }
      { !!building.color && building.level !== 1 && <div css={topPowerLine}></div>}
      { !!building.color && building.level !== 1 && <div css={topPowerText}><span css={text}>{t('building.help.condition-top')}</span></div>}
      { !!building.color && building.level !== 1 && <div css={leftPowerLine}></div>}
      { !!building.color && building.level !== 1 && <div css={leftPowerText}><span css={text}>{t('building.help.condition-left')}</span></div>}
      { !!building.color && !!building.mainEffect && <div css={mainPowerLine}></div>}
      { !!building.color && !!building.mainEffect && <div css={mainPowerText}><span css={text}>{t('building.help.main-power')}</span></div>}
      { !!building.color && building.level !== 1 && <div css={bottomPowerLine}></div>}
      { !!building.color && building.level !== 1 && <div css={bottomPowerText}><span css={text}>{t('building.help.condition-bottom')}</span></div>}
      { !!building.color && building.level !== 1 && <div css={rightPowerLine}></div>}
      { !!building.color && building.level !== 1 && <div css={rightPowerText}><span css={text}>{t('building.help.condition-right')}</span></div>}
    </div>
  )

  let letterBox = document.getElementById('letterbox')!;
  if (letterBox) {
    return createPortal(modal, letterBox);
  }

  return null;
}

const constructionLine = css`
  transform: rotateZ(73deg);
  position: absolute;
  top: 31em;
  left: 8.4em;
  width: 28em;
  height: 0.3em;
  background-color: black;
`

const recyclingLine = css`
  transform: rotateZ(-45deg);
  position: absolute;
  top: 48em;
  left: 86.4em;
  width: 23em;
  height: 0.3em;
  background-color: black;
`;

const recyclingText = css`
  position: absolute;
  transform: translateX(50%);
  background-color: white;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 56em;
  right: 65em;
  max-width: 60em;
  border-radius: 1em;
`;

const levelLine = css`
  transform: rotateZ(40deg);
  position: absolute;
  top: 23.7em;
  left: 72em;
  width: 18em;
  height: 0.3em;
  background-color: black;
`

const mainPowerLine = css`
  transform: rotateZ(-30deg);
  position: absolute;
  top: 30.8em;
  left: 103.3em;
  width: 24em;
  height: 0.3em;
  background-color: black;
`

const leftPowerLine = css`
  transform: rotateZ(-55deg);
  position: absolute;
  top: 47.5em;
  left: 73.1em;
  width: 18em;
  height: 0.3em;
  background-color: black;
`

const topPowerLine = css`
  transform: rotateZ(90deg);
  position: absolute;
  top: 21.2em;
  left: 94.6em;
  width: 15em;
  height: 0.3em;
  background-color: black;
`

const topPowerText = css`
  position: absolute;
  transform: translateX(50%);
  background-color: white;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  bottom: 66em;
  right: 40em;
  max-width: 60em;
  border-radius: 1em;
`

const constructionFaceText = css`
  position: absolute;
  padding: 0.5em 1em;
  top: 20em;
  right: 85em;
`

const builtFaceText = css`
  position: absolute;
  padding: 0.5em 1em;
  top: 20em;
  right: 25em;
`

const mainPowerText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  bottom: 54.8em;
  left: 125.2em;
  max-width: 21em;
  border-radius: 1em;
  background-color: white;
`

const levelText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  bottom: 61.5em;
  right: 73.5em;
  max-width: 52em;
  border-radius: 1em;
  background-color: white;
`

const constructionText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  bottom: 61.5em;
  transform: translateX(-40%);
  left: 18.2em;
  max-width: 52em;
  border-radius: 1em;
  background-color: white;
`

const leftPowerText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 55em;
  max-width: 65em;
  right: 65em;
  border-radius: 1em;
  background-color: white;
`

const bottomPowerText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 66.4em;
  max-width: 65em;
  right: 52.5em;
  border-radius: 1em;
  background-color: white;
`

const rightPowerText = css`
  position: absolute;
  padding: 0.5em 1em;
  border: 0.3em solid black;
  top: 55.1em;
  max-width: 46em;
  right: 1.5em;
  border-radius: 1em;
  background-color: white;
`

const text = css`
  color: black;
  font-size: 3em;
`

const bottomPowerLine = css`
  transform: rotateZ(-55deg);
  position: absolute;
  top: 57.7em;
  left: 85.6em;
  width: 21em;
  height: 0.3em;
  background-color: black;
`

const rightPowerLine = css`
  transform: rotateZ(90deg);
  position: absolute;
  top: 47.8em;
  left: 109.7em;
  width: 15em;
  height: 0.3em;
  background-color: black;
`

const hidden = css`
  opacity: 0;
`;

const helpContainer = css`
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateZ(50em);
  height: 80em;
  width: 80%;
`;

export {
  BuildingHelpContent
}
