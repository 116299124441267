/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC } from 'react';
import { ElderPowerTile } from '../elderpower/ElderPowerTile';
import { usePlay, usePlayerId } from '@gamepark/react-client';
import { canCallElder } from '@gamepark/dunaia/utils/elder-power.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import { ElderPowers } from '@gamepark/dunaia/material/power/ElderPowers';
import { selectElderPowerMove } from '../../moves/SelectElderPower';
import { shineEffect } from '../../styles/Style';

export type MainBoardElderPowersProps = {
  elderPowers: number[];
  activePlayer: Player;
};

const MainBoardElderPowers: FC<MainBoardElderPowersProps> = (props) => {
  const { elderPowers, activePlayer } = props;
  const play = usePlay();
  const playerId = usePlayerId();
  const canCall = (index: number) =>
    activePlayer.color === playerId && canCallElder(activePlayer, index, ElderPowers[elderPowers[index]]);

  const callElder = (index: number) => play(selectElderPowerMove(index), { local: true });

  return (
    <>
      <ElderPowerTile
        css={[power, firstElderPower, canCall(0) && shineEffect()]}
        elderPower={elderPowers[0]}
        onClick={() => callElder(0)}
      />
      <ElderPowerTile
        css={[power, secondElderPower, canCall(1) && shineEffect()]}
        elderPower={elderPowers[1]}
        onClick={() => callElder(1)}
      />
      <ElderPowerTile
        css={[power, thirdElderPower, canCall(2) && shineEffect()]}
        elderPower={elderPowers[2]}
        onClick={() => callElder(2)}
      />
    </>
  );
};

const power = css`
  border-radius: 5em 5em 1.5em 1.5em;
  transform: translateZ(0);
`

const firstElderPower = css`
  position: absolute;
  top: 34.27em;
  right: 7.05em;
  cursor: pointer;
`;

const secondElderPower = css`
  position: absolute;
  top: 50.4em;
  right: 15.72em;
  cursor: pointer;
`;

const thirdElderPower = css`
  position: absolute;
  top: 66.6em;
  right: 7.1em;
  cursor: pointer;
`;

export { MainBoardElderPowers };
