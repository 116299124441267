/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { memoryChipHeight, memoryChipWidth } from '../../styles/Style';
import { Images } from '../../images/Images';

export type MemoryChipProps = {
  memoryChip: number;
} & HTMLAttributes<HTMLDivElement>;

const MemoryChip: FC<MemoryChipProps> = ({ memoryChip, ...props }) => {
  return <div css={chip(memoryChip)} {...props} />;
};

const chip = (memoryChip: number) => css`
  height: ${memoryChipHeight}em;
  width: ${memoryChipWidth}em;
  background-image: url(${MemoryChipImages[memoryChip - 1]});
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
`;

const MemoryChipImages: any[] = [
  Images.MemoryChip1,
  Images.MemoryChip2,
  Images.MemoryChip3,
  Images.MemoryChip4,
  Images.MemoryChip5,
  Images.MemoryChip6,
];

export { MemoryChip };
