import MoveType from './MoveType';
import GameState from '../GameState';
import { Player } from '../player/Player';
import { hasDice } from '../utils/building.utils';
import { getNonRecycledDie } from '../utils/dice.utils';
import { GameEffects } from '../effect/GameEffect';
import { Building } from '../material/building/Building';
import { addAndReorganizeEffects, getEffectRule } from '../utils/pending-effect.utils';

export type RecycleDice = {
  type: MoveType.RecycleDice;
  boardAction?: number;
  building?: number;
};

export const recycleDiceOnBuildingMove = (building: number): RecycleDice => ({
  type: MoveType.RecycleDice,
  building,
});

export const recycleDiceOnBoardMove = (boardAction: number): RecycleDice => ({
  type: MoveType.RecycleDice,
  boardAction,
});

export const recycleDice = (state: GameState, move: RecycleDice, actions: GameEffects[], buildings: Building[]) => {
  const player = state.players.find((p) => p.color === state.activePlayer)!;

  const die = getNonRecycledDie(player.dice)!;
  die.recyclingOrder = state.players.flatMap((p) =>
    p.dice.filter((b) => b.building !== undefined || b.boardAction !== undefined)
  ).length;
  if (move.building !== undefined) {
    recycleDiceOnBuilding(state, player, move, buildings);
    die.building = move.building;
  }

  if (move.boardAction !== undefined) {
    addAndReorganizeEffects(player, [{ ...actions[move.boardAction] }]);
    die.boardAction = move.boardAction;
  }
};

const recycleDiceOnBuilding = (state: GameState, player: Player, move: RecycleDice, buildings: Building[]) => {
  const building = buildings[move.building!];
  let buildingPlayer = state.players.find((p) => p.buildings.flat().some((b) => b && b.building === move.building))!;

  if (hasDice(move.building!, state.players)) {
    throw new Error('Trying to use a dice on a building that already has a dice');
  }

  if (buildingPlayer && buildingPlayer.color !== player.color) {
    buildingPlayer.metalFlowers++;
    player.metalFlowers--;
  }

  const effect = building.recyclingEffect!.effect;
  const rule = getEffectRule(effect)
  if (!rule.isIgnored?.(player, state))  {
    addAndReorganizeEffects(player, [{ ...effect }]);
  }
};
