/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes, useEffect, useState } from 'react';
import { playerBoardHeight, playerBoardLeft, playerBoardTop, playerBoardWidth } from '../../styles/Style';
import { Player } from '@gamepark/dunaia/player/Player';
import { Images } from '../../images/Images';
import { PlayerMemoryChips } from './PlayerMemoryChips';
import { PlayerConstructionTokens } from './PlayerConstructionTokens';
import { PlayerDunaias } from './PlayerDunaias';
import { PlayerBuildings } from './PlayerBuildings';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PlayerBoardSpaces } from './PlayerBoardSpaces';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import Color from '@gamepark/dunaia/color/Color';
import { useAnimation, usePlayerId } from '@gamepark/react-client';
import { isRecycleOnBuilding } from '@gamepark/dunaia/utils/is-move.utils';

export type PlayerBoardProps = {
  player: Player;
  buildings: number[][][];
  recycledDice: PlayerDice[];
  activeColor: Color;
  canRecycleDie: boolean;
  toBuyBuilding?: number;
  selectedDunaia?: number;
  gameOver: boolean;
} & HTMLAttributes<HTMLDivElement>;

const PlayerBoard: FC<PlayerBoardProps> = (props) => {
  const { player, toBuyBuilding, selectedDunaia, activeColor, recycledDice, buildings, canRecycleDie, gameOver, ...rest } = props;
  const playerId = usePlayerId();
  const animation = useAnimation(a => isRecycleOnBuilding(a.move) && activeColor === playerId);
  const [zoomed, setZoomed] = useState(false);

  useEffect(() => {
    if (zoomed && animation) {
      setZoomed(false);
    }
  }, [zoomed, animation])


  return (
    <div {...rest} css={[playerBoard, zoomed && zoomedBoard]}>
      <FontAwesomeIcon icon={faSearch} css={zoomButton} onClick={() => setZoomed((zoomed) => !zoomed)} />
      <div css={[boardOverlay, zoomed && overlayVisible]} onClick={() => setZoomed(false)} />
      <PlayerBuildings
        dice={recycledDice}
        selectedBuilding={toBuyBuilding}
        player={player}
        canRecycleDie={canRecycleDie}
        gameOver={gameOver}
      />
      <PlayerMemoryChips chips={player.memoryChips} color={player.color} activeColor={activeColor} />
      <PlayerConstructionTokens
        activeColor={activeColor}
        constructionTokens={player.constructionTokens}
        color={player.color}
        buildings={buildings}
      />
      <PlayerDunaias
        dunaias={player.dunaias}
        player={player}
        activeColor={activeColor}
        selectedDunaia={selectedDunaia}
        zoomed={zoomed}
      />
      <PlayerBoardSpaces player={player} activeColor={activeColor} selectedDunaia={selectedDunaia} />
    </div>
  );
};

const unZoomAnimation = keyframes`
  from {
    transform: translateZ(10em) scale(1.4) translate(29em, -9em);
  }
  85% {
    transform: translateZ(6em);
  }
`;

const playerBoard = css`
  position: absolute;
  top: ${playerBoardTop}em;
  left: ${playerBoardLeft}em;
  height: ${playerBoardHeight}em;
  width: ${playerBoardWidth}em;
  image-rendering: -webkit-optimize-contrast;
  transform-style: preserve-3d;
  animation: 0.5s ${unZoomAnimation} ease-out both;
  background-image: url(${Images.PlayerBoard});
  background-size: cover;
`;

const zoomAnimation = keyframes`
  15% {
    transform: translateZ(3em);
  }
  to {
    transform: translateZ(12em) scale(1.4) translate(29em, -9em);
  }
`;

const zoomedBoard = css`
  animation: 0.5s ${zoomAnimation} ease-in both;
`;

const boardOverlay = css`
  position: absolute;
  top: -1000%;
  right: -1000%;
  bottom: -1000%;
  left: -1000%;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  transform: translateZ(-1em);
  opacity: 0;
  transition: opacity 0.1s ease-in 0.2s;
`;

const overlayVisible = css`
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
`;

const zoomButton = css`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  height: 5em;
  width: 5em !important;
  padding: 1em;
  background-color: white;
  color: black;
  cursor: pointer;
  border-radius: 50%;
  border: 0.1em solid black;
  box-sizing: border-box !important;

  &:hover {
    background-color: gray;
  }
`;

export { PlayerBoard };
