/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import Color from '@gamepark/dunaia/color/Color';
import { FC, HTMLAttributes } from 'react';
import { ConstructionToken } from '../constructiontoken/ConstructionToken';
import {
  constructionTokenToBuildingTranslateX,
  constructionTokenToBuildingTranslateY,
  playerConstructionTokenLeft,
  playerConstructionTokenTop,
} from '../../styles/Style';
import { Animation, useAnimation } from '@gamepark/react-client';
import { BuyBuilding } from '@gamepark/dunaia/moves/BuyBuilding';
import { isBuyBuilding } from '@gamepark/dunaia/utils/is-move.utils';
import { AddConstructionTokenPercent, BuyBuildingRefillPercent } from '../../animations/AnimationConstants';
import { getBuildingColumn } from '@gamepark/dunaia/utils/building.utils';

export type PlayerConstructionTokensProps = {
  constructionTokens: number;
  activeColor: Color;
  buildings: number[][][];
  color: Color;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

const PlayerConstructionTokens: FC<PlayerConstructionTokensProps> = (props) => {
  const { constructionTokens, color, activeColor, buildings } = props;
  const animation = useAnimation<BuyBuilding>((a) => isBuyBuilding(a.move) && activeColor === color);

  return (
    <>
      {Array.from(Array(constructionTokens)).map((_, index) => (
        <ConstructionToken
          key={index}
          color={color}
          css={[
            playerConstructionToken(index),
            animation &&
              index === constructionTokens - 1 &&
              animateConstructionTokenToBuilding(animation, buildings, index),
          ]}
        />
      ))}
    </>
  );
};

const playerConstructionToken = (index: number) => css`
  position: absolute;
  top: ${playerConstructionTokenTop(index)}em;
  left: ${playerConstructionTokenLeft}em;
  transform: translateZ(0);
`;

const constructionTokenToBuilding = (move: BuyBuilding, constructionToken: number) => {
  const translateX = constructionTokenToBuildingTranslateX(move.target.x);
  const translateY = constructionTokenToBuildingTranslateY(constructionToken, move.target.y);
  return keyframes`
    10% {
      transform: translateZ(9.5em);
    }
    90% {
      transform: translate(${translateX}em, ${translateY}em) translateZ(9.5em);
    }
    to {
      transform: translate(${translateX}em, ${translateY}em) translateZ(0.2em);
    }
  `;
};

const animateConstructionTokenToBuilding = (
  animation: Animation<BuyBuilding>,
  buildings: number[][][],
  constructionToken: number
) => {
  const column = getBuildingColumn(buildings, animation.move.building);
  const animationDuration = animation.duration * AddConstructionTokenPercent(column);
  const animationDelay =
    animation.duration * (1 - BuyBuildingRefillPercent(column) - AddConstructionTokenPercent(column));
  return css`
    animation: ${animationDuration}s ${constructionTokenToBuilding(animation.move, constructionToken)}
      ${animationDelay}s linear both;
  `;
};

export { PlayerConstructionTokens };
