enum DragObjectType {
  MainBoardBuilding = 'MainBoardBuilding',
  PlayerDunaia = 'PlayerDunaia',
  DiceFromMainBoard = 'DiceFromMainBoard',
  PlayerPanelDice = 'PlayerPanelDice',
}

export  {
  DragObjectType
}
