import GameView from '@gamepark/dunaia/GameView';

export type SelectProphecy = {
  type: 'SelectProphecy';
  prophecy?: number;
};

export const selectProphecyMove = (prophecy?: number): SelectProphecy => ({
  type: 'SelectProphecy',
  prophecy,
});

export function selectProphecy(state: GameView, move: SelectProphecy) {
  state.selectedProphecy = move.prophecy;
}
