import { Building } from "./Building";
import { GainMemoryChipsEffect } from "../../effect/GainMemoryChipsEffect";
import {
  RecyclingBuildingEffect,
  ScoringBuildingEffect,
  StandardBuildingEffect,
} from "./BuildingEffect";
import Color from "../../color/Color";
import { GainMetalFlowerEffect } from "../../effect/GainMetalFlowerEffect";
import { MoveConstructionTokenEffect } from "../../effect/MoveConstructionTokenEffect";
import { MoveDunaiaEffect } from "../../effect/MoveDunaiaEffect";
import { Direction } from "../../common/Direction";

/**
 * LEVEL 1 BUILDINGS
 * Only with Recycling bonuses
 */
export const Level1GreenBuilding1: Building = {
  level: 1,
  color: Color.Green,
  recyclingEffect: new RecyclingBuildingEffect(
    new GainMemoryChipsEffect(false, 2)
  ),
  constructionSteps: 3,
};

export const Level1GreenBuilding2: Building = {
  level: 1,
  color: Color.Green,
  recyclingEffect: new RecyclingBuildingEffect(new GainMetalFlowerEffect(3)),
  constructionSteps: 3,
};

export const Level1GreenBuilding3: Building = {
  level: 1,
  color: Color.Green,
  recyclingEffect: new RecyclingBuildingEffect(
    new MoveConstructionTokenEffect()
  ),
  constructionSteps: 3,
};

export const Level1GreenBuilding4: Building = {
  level: 1,
  color: Color.Green,
  recyclingEffect: new RecyclingBuildingEffect(new MoveDunaiaEffect(3)),
  constructionSteps: 3,
};

export const Level1YellowBuilding1: Building = {
  level: 1,
  color: Color.Yellow,
  recyclingEffect: new RecyclingBuildingEffect(
    new GainMemoryChipsEffect(false, 2)
  ),
  constructionSteps: 3,
};

export const Level1YellowBuilding2: Building = {
  level: 1,
  color: Color.Yellow,
  recyclingEffect: new RecyclingBuildingEffect(new GainMetalFlowerEffect(3)),
  constructionSteps: 3,
};

export const Level1YellowBuilding3: Building = {
  level: 1,
  color: Color.Yellow,
  recyclingEffect: new RecyclingBuildingEffect(
    new MoveConstructionTokenEffect()
  ),
  constructionSteps: 3,
};

export const Level1YellowBuilding4: Building = {
  level: 1,
  color: Color.Yellow,
  recyclingEffect: new RecyclingBuildingEffect(new MoveDunaiaEffect(3)),
  constructionSteps: 3,
};

export const Level1PurpleBuilding1: Building = {
  level: 1,
  color: Color.Purple,
  recyclingEffect: new RecyclingBuildingEffect(
    new GainMemoryChipsEffect(false, 2)
  ),
  constructionSteps: 3,
};

export const Level1PurpleBuilding2: Building = {
  level: 1,
  color: Color.Purple,
  recyclingEffect: new RecyclingBuildingEffect(new GainMetalFlowerEffect(3)),
  constructionSteps: 3,
};

export const Level1PurpleBuilding3: Building = {
  level: 1,
  color: Color.Purple,
  recyclingEffect: new RecyclingBuildingEffect(
    new MoveConstructionTokenEffect()
  ),
  constructionSteps: 3,
};

export const Level1PurpleBuilding4: Building = {
  level: 1,
  color: Color.Purple,
  recyclingEffect: new RecyclingBuildingEffect(new MoveDunaiaEffect(3)),
  constructionSteps: 3,
};

export const Level1RedBuilding1: Building = {
  level: 1,
  color: Color.Red,
  recyclingEffect: new RecyclingBuildingEffect(
    new GainMemoryChipsEffect(false, 2)
  ),
  constructionSteps: 3,
};

export const Level1RedBuilding2: Building = {
  level: 1,
  color: Color.Red,
  recyclingEffect: new RecyclingBuildingEffect(new GainMetalFlowerEffect(3)),
  constructionSteps: 3,
};

export const Level1RedBuilding3: Building = {
  level: 1,
  color: Color.Red,
  recyclingEffect: new RecyclingBuildingEffect(
    new MoveConstructionTokenEffect()
  ),
  constructionSteps: 3,
};

export const Level1RedBuilding4: Building = {
  level: 1,
  color: Color.Red,
  recyclingEffect: new RecyclingBuildingEffect(new MoveDunaiaEffect(3)),
  constructionSteps: 3,
};

/**
 * LEVEL 2 BUILDINGS
 */

export const Level2GreenBuilding1: Building = {
  level: 2,
  color: Color.Green,
  mainEffect: new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Green,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Green,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2GreenBuilding2: Building = {
  level: 2,
  color: Color.Green,
  mainEffect: new StandardBuildingEffect(new GainMetalFlowerEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2GreenBuilding3: Building = {
  level: 2,
  color: Color.Green,
  mainEffect: new StandardBuildingEffect(new MoveDunaiaEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Green,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Green,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Green,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2YellowBuilding1: Building = {
  level: 2,
  color: Color.Yellow,
  mainEffect: new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Yellow,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Yellow,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2YellowBuilding2: Building = {
  level: 2,
  color: Color.Yellow,
  mainEffect: new StandardBuildingEffect(new GainMetalFlowerEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2YellowBuilding3: Building = {
  level: 2,
  color: Color.Yellow,
  mainEffect: new StandardBuildingEffect(new MoveDunaiaEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Yellow,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Yellow,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Yellow,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2PurpleBuilding1: Building = {
  level: 2,
  color: Color.Purple,
  mainEffect: new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Purple,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Purple,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2PurpleBuilding2: Building = {
  level: 2,
  color: Color.Purple,
  mainEffect: new StandardBuildingEffect(new GainMetalFlowerEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2PurpleBuilding3: Building = {
  level: 2,
  color: Color.Purple,
  mainEffect: new StandardBuildingEffect(new MoveDunaiaEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Purple,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Purple,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Purple,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2RedBuilding1: Building = {
  level: 2,
  color: Color.Red,
  mainEffect: new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Red,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMemoryChipsEffect(false, 1), {
      color: Color.Red,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2RedBuilding2: Building = {
  level: 2,
  color: Color.Red,
  mainEffect: new StandardBuildingEffect(new GainMetalFlowerEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

export const Level2RedBuilding3: Building = {
  level: 2,
  color: Color.Red,
  mainEffect: new StandardBuildingEffect(new MoveDunaiaEffect(1)),
  adjacentEffects: [
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Left,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Red,
      direction: Direction.Top,
    }),
    new StandardBuildingEffect(new GainMetalFlowerEffect(1), {
      color: Color.Red,
      direction: Direction.Right,
    }),
    new StandardBuildingEffect(new MoveDunaiaEffect(1), {
      color: Color.Red,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 4,
};

/**
 * LEVEL 3 BUILDINGS
 */
export const Level3GreenBuilding1: Building = {
  level: 3,
  color: Color.Green,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3GreenBuilding2: Building = {
  level: 3,
  color: Color.Green,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3YellowBuilding1: Building = {
  level: 3,
  color: Color.Yellow,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3YellowBuilding2: Building = {
  level: 3,
  color: Color.Yellow,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3PurpleBuilding1: Building = {
  level: 3,
  color: Color.Purple,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Red,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3PurpleBuilding2: Building = {
  level: 3,
  color: Color.Purple,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3RedBuilding1: Building = {
  level: 3,
  color: Color.Red,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Yellow,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Green,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3RedBuilding2: Building = {
  level: 3,
  color: Color.Red,
  adjacentEffects: [
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Left,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Top,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Right,
    }),
    new ScoringBuildingEffect(2, {
      color: Color.Purple,
      direction: Direction.Bottom,
    }),
  ],
  constructionSteps: 5,
};

export const Level3MultiBuildings: Building = {
  copies: 4,
  level: 3,
  constructionSteps: 5,
};

export const Buildings: Building[] = [
  Level1GreenBuilding1,
  Level1GreenBuilding2,
  Level1GreenBuilding3,
  Level1GreenBuilding4,
  Level1YellowBuilding1,
  Level1YellowBuilding2,
  Level1YellowBuilding3,
  Level1YellowBuilding4,
  Level1PurpleBuilding1,
  Level1PurpleBuilding2,
  Level1PurpleBuilding3,
  Level1PurpleBuilding4,
  Level1RedBuilding1,
  Level1RedBuilding2,
  Level1RedBuilding3,
  Level1RedBuilding4,
  Level2GreenBuilding1,
  Level2GreenBuilding2,
  Level2GreenBuilding3,
  Level2YellowBuilding1,
  Level2YellowBuilding2,
  Level2YellowBuilding3,
  Level2PurpleBuilding1,
  Level2PurpleBuilding2,
  Level2PurpleBuilding3,
  Level2RedBuilding1,
  Level2RedBuilding2,
  Level2RedBuilding3,
  Level3GreenBuilding1,
  Level3GreenBuilding2,
  Level3YellowBuilding1,
  Level3YellowBuilding2,
  Level3PurpleBuilding1,
  Level3PurpleBuilding2,
  Level3RedBuilding1,
  Level3RedBuilding2,
  Level3MultiBuildings,
].flatMap((building) => Array(building.copies || 1).fill(building));
