import MoveView from '../moves/MoveView';
import Move from '../moves/Move';
import Color from '../color/Color';
import { Action } from '@gamepark/rules-api';
import MoveType from '../moves/MoveType';

/**
 * Can the player undo the action ?
 * @param action the given action
 * @param activePlayer The active player
 */
export const undoUtils = (action: Action<Move | MoveView, Color>, activePlayer: Color) => {
  return action.playerId === activePlayer && action.move.type !== MoveType.EndTurn
}
