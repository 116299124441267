/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { Prophecy } from '@gamepark/dunaia/material/prophecy/Prophecy';
import { Images } from '../../images/Images';
import {
  Prophecies,
  Prophecy1,
  Prophecy2,
  Prophecy3,
  Prophecy4,
  Prophecy5,
  Prophecy6,
  Prophecy7,
  Prophecy8,
  Prophecy9,
} from '@gamepark/dunaia/material/prophecy/Prophecies';
import { prophecyHeight, prophecyWidth } from '../../styles/Style';

export type ProphecyTileProps = {
  prophecy: number;
  scale?: number;
} & HTMLAttributes<HTMLDivElement>;

const ProphecyTile: FC<ProphecyTileProps> = ({ prophecy, scale = 1, ...props }) => {
  return <div css={prophecyStyle(prophecy, scale)} {...props} />;
};

const prophecyStyle = (prophecy: number, scale: number) => css`
  background: url(${ProphecyImages.get(Prophecies[prophecy])});
  background-size: 100% 100%;
  box-shadow: 0 0 0.5em 0.1em black;
  height: ${prophecyHeight * scale }em;
  width: ${prophecyWidth * scale}em;
  image-rendering: -webkit-optimize-contrast;
  border-radius: 1em;
  cursor: pointer;
`;

const ProphecyImages = new Map<Prophecy, any>();
ProphecyImages.set(Prophecy1, Images.Prophecy1);
ProphecyImages.set(Prophecy2, Images.Prophecy2);
ProphecyImages.set(Prophecy3, Images.Prophecy3);
ProphecyImages.set(Prophecy4, Images.Prophecy4);
ProphecyImages.set(Prophecy5, Images.Prophecy5);
ProphecyImages.set(Prophecy6, Images.Prophecy6);
ProphecyImages.set(Prophecy7, Images.Prophecy7);
ProphecyImages.set(Prophecy8, Images.Prophecy8);
ProphecyImages.set(Prophecy9, Images.Prophecy9);

export { ProphecyTile };
