/** @jsxImportSource @emotion/react */
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { FC, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { BoardActions } from '@gamepark/dunaia/material/board/BoardActions';
import { Player } from '@gamepark/dunaia/player/Player';
import { MainBoardAction } from './MainBoardAction';

export type MainBoardRecycledDiceProps = {
  player: Player;
  dice: PlayerDice[];
  buildings: number[][][];
};

const MainBoardActions: FC<MainBoardRecycledDiceProps> = (props) => {
  const { player, dice, buildings } = props;
  const recycledDiceByAction: Record<number, PlayerDice[]> = useMemo(
    () => groupBy(sortBy(dice, 'recyclingOrder'), 'boardAction'),
    [dice]
  );

  return (
    <>
      { BoardActions.map((_, index) => (
        <MainBoardAction key={index} action={index} player={player} buildings={buildings} recycledDice={recycledDiceByAction[index]} />
      )) }
    </>
  );
};

export { MainBoardActions };
