import { GameEffect, GameEffectType } from './GameEffect';

export class BuyBuildingEffect implements GameEffect {
  type: GameEffectType.BuyBuilding = GameEffectType.BuyBuilding;
  any?: boolean;

  constructor(any?: boolean) {
    this.any = any;
  }
};
