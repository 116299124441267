/**
 * Is the game over ?
 * @param state The game state
 */
import GameState from '../GameState';
import { Player } from '../player/Player';
import times from 'lodash/times';
import sum from 'lodash/sum';
import { getBuildingsScore } from './building.utils';
import { Prophecies } from '../material/prophecy/Prophecies';
import { maxBy } from 'lodash';

export const isGameOver = (state: GameState): boolean => {
  return state.round === state.last && state.players.every((e) => e.end);
};

export const getCompletedArtefacts = (artefacts: number[][]) => artefacts.filter((a) => a.length === 3)

export const getArtefactScoring = (artefacts: number[][]) => {
  const artefactCount = getCompletedArtefacts(artefacts).length
  const scoring = [1, 3, 6, ...times(Math.max(0, artefactCount - 3), (n) => 10 + n * 4)]
  return scoring[artefactCount - 1] ?? 0;
}

export const getMetalFlowerScoring = (metalFlowers: number) => {
  return Math.floor(metalFlowers / 3)
}

export const getProphecyScoring = (prophecies: number[]) => {
  return sum(prophecies.map((p) => Prophecies[p].score));
}

export const getPlayerScore = (player: Player): number => {
  const flowerScore = getMetalFlowerScoring(player.metalFlowers)
  const prophecyScore = getProphecyScoring(player.prophecies);
  const artefactScore = getArtefactScoring(player.artefacts);
  const buildingScore = getBuildingsScore(player.buildings);

  return flowerScore
    + prophecyScore
    + artefactScore
    + buildingScore;
}

export const getWinner = (players: Player[]): Player => {
  return maxBy(players, (p) => [getPlayerScore(p), p.prophecies.length, p.metalFlowers])!;
}
