import { GameEffect, GameEffectType } from './GameEffect';

export class GainMemoryChipsEffect implements GameEffect {
  type: GameEffectType.GainMemoryChips = GameEffectType.GainMemoryChips;
  automatic?: boolean;
  count?: number;
  memoryChip?: number;

  constructor(automatic: boolean = false, count?: number, memoryChip?: number) {
    this.automatic = automatic;
    this.count = count;
    this.memoryChip = memoryChip;
  }

}

