import Move from '../../moves/Move';
import { moveDunaiaMove } from '../../moves/MoveDunaia';
import { Player } from '../../player/Player';
import GameState from '../../GameState';
import { GameEffectRules } from './GameEffectRules';
import { MoveDunaiaEffect } from '../MoveDunaiaEffect';

class MoveDunaiaEffectRules extends GameEffectRules<MoveDunaiaEffect> {

  constructor(effect: MoveDunaiaEffect) {
    super(effect);
  }

  legalMoves(_state: GameState, player: Player): Move[] {
    return player.dunaias.flatMap((_: number, index: number) =>
      Array.from(Array(36).entries())
        .filter((entry) => !player.dunaias.includes(entry[0]))
        .map((entry) => moveDunaiaMove(index, entry[0]))
    );
  }
}

export { MoveDunaiaEffectRules };
