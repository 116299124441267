/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { BlackDice, DiceColor } from '@gamepark/dunaia/common/DiceColor';
import { FC, HTMLAttributes, memo } from 'react';
import Color from '@gamepark/dunaia/color/Color';
import { diceSize } from '../../styles/Style';

export type DieProps = {
  color: DiceColor;
  fadeIn?: boolean;
  value: number;
  lightning?: boolean;
  preTransform?: string;
  postTransform?: string;
  scale?: number;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

const Die: FC<DieProps> = memo((props) => {
  const { color, value, onClick, lightning, preTransform, scale, postTransform, fadeIn, ...rest } = props
  const dotColor = (faceValue: number) => (color === Color.Yellow ? 'black' : faceValue === value ? 'white' : '#c8c8c8');

  const onFaceClick = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    onClick?.(event);
  };

  const facesByValue = {
    1: [
      1, 6, 4, 3, 2, 5
    ],
    2: [
      2, 5, 3, 4, 1, 6
    ],
    3:[
      3, 4, 6, 1, 5, 2
    ],
    4:[
      4, 3, 1, 6, 5, 2
    ],
    5:[
      5, 2, 6, 1, 4, 3
    ],
    6:[
      6, 1, 3, 4, 2, 5
    ],
  }

  const faces = [
    [
      <span key="1" css={[dot(dotColor(1)), centerDot]} />
    ],
    [
      <span key="1" css={[dot(dotColor(2)), topLeftDot]} />,
      <span key="2" css={[dot(dotColor(2)), bottomRightDot]} />
    ],
      [
        <span key="1" css={[dot(dotColor(3)), topRightDot]} />,
        <span key="2" css={[dot(dotColor(3)), centerDot]} />,
        <span key="3" css={[dot(dotColor(3)), bottomLeftDot]} />
      ],
    [
      <span key="1" css={[dot(dotColor(4)), topLeftDot]} />,
      <span key="2" css={[dot(dotColor(4)), topRightDot]} />,
      <span key="3" css={[dot(dotColor(4)), bottomLeftDot]} />,
      <span key="4" css={[dot(dotColor(4)), bottomRightDot]} />
    ],
    [
      <span key="1" css={[dot(dotColor(5)), topRightDot]} />,
      <span key="2" css={[dot(dotColor(5)), centerDot]} />,
      <span key="3" css={[dot(dotColor(5)), bottomLeftDot]} />,
      <span key="4" css={[dot(dotColor(5)), topLeftDot]} />,
      <span key="5" css={[dot(dotColor(5)), bottomRightDot]} />
    ],

    [
      <span key="1" css={[dot(dotColor(6)), topLeftDot]} />,
      <span key="2" css={[dot(dotColor(6)), topRightDot]} />,
      <span key="3" css={[dot(dotColor(6)), bottomLeftDot]} />,
      <span key="4" css={[dot(dotColor(6)), bottomRightDot]} />,
      <span key="5" css={[dot(dotColor(6)), centerLeftDot]} />,
      <span key="6" css={[dot(dotColor(6)), centerRightDot]} />
    ],

  ]

  const orientation = (index: number) => {
    switch (index) {
      case 0:
        return topOrientation;
      case 1:
        return bottomOrientation;
      case 2:
        return behindOrientation;
      case 3:
        return frontOrientation;
      case 4:
        return leftOrientation;
      case 5:
        return rightOrientation;
    }
    return;
  }

  return (
    <>
      { lightning && <div css={[dice, lightningStyle(preTransform)]} {...rest} /> }
      <div css={[diceContainer, dice, diceScale(scale, preTransform, postTransform)]} {...rest}>
        <div css={[dice]}>
          {facesByValue[value].map((v: number, index: number) => (
            <div
              key={index}
              onClick={onFaceClick}
              css={[dieStyle(color, value, value === v), orientation(index)]}
            >
              {faces[v - 1]}
            </div>
          ))}
        </div>
      </div>
    </>

  );
});

const diceContainer = css`
  transform-style: preserve-3d;
`;
const dice = css`
  transform-style: preserve-3d;
  width: ${diceSize}em;
  height: ${diceSize}em;
  transition: 0.5s transform;
  transform: translateZ(0);
`;

const diceScale = (scale?: number, preTransform?: string, postTransform?: string) => css`
  transform: ${preTransform || 'translateZ(0)'} scale(${scale || 1}) ${postTransform || ''};
`;

const dieStyle = (color: DiceColor, _value: number, highlight?: boolean) => css`
  position: absolute;
  //backface-visibility: hidden;
  background-color: ${getDiceColor(color, highlight)};
  border: 0.1em solid gray;

  width: 100%;
  height: 100%;
`;

const lightningAnimation = keyframes`
  0% {
    box-shadow: unset;
  }
  50% {
    box-shadow: 0 0 .7em .7em gold;
  }
  100% {
    box-shadow: unset;
  }
`;

const lightningStyle = (preTransform?: string) => css`
  position: absolute;
  ${preTransform? `transform: ${preTransform};`: ''}
  animation: 1.5s ${lightningAnimation} ease-in-out infinite;
`;

const topOrientation = css`
  transform: translateZ(${diceSize}em);
`;

const bottomOrientation = css`
  transform: rotateX(180deg);
`;

const leftOrientation = css`
  transform: rotateY(-90deg);
  transform-origin: left;
`;

const rightOrientation = css`
  transform: rotateY(90deg);
  transform-origin: right;
`;

const behindOrientation = css`
  transform: rotateX(90deg);
  transform-origin: top;
`;

const frontOrientation = css`
  transform: rotateX(-90deg);
  transform-origin: bottom;
`;

const dotSize = diceSize / 5;
const dot = (color?: string) => css`
  position: absolute;
  height: ${dotSize}em;
  width: ${dotSize}em;
  background-color: ${color || 'white'};
  border-radius: 50%;
`;

const centerDot = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const topRightDot = css`
  top: 10%;
  right: 10%;
`;

const bottomLeftDot = css`
  bottom: 10%;
  left: 10%;
`;

const topLeftDot = css`
  top: 10%;
  left: 10%;
`;

const bottomRightDot = css`
  bottom: 10%;
  right: 10%;
`;
const centerLeftDot = css`
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
`;

const centerRightDot = css`
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
`;

const getDiceColor = (color: DiceColor, highlight?: boolean) => {
  switch (color) {
    case Color.Purple:
      return 'purple';
    case Color.Red:
      return highlight ? 'red' : '#ae0a0a';
    case Color.Green:
      return 'green';
    case Color.Yellow:
      return highlight ? 'yellow' : '#cabe12';
    case BlackDice.Black:
      return 'black';
  }

  return 'blue';
};

export { Die };
