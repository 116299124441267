import Color from "@gamepark/dunaia/color/Color";
import GameView from "@gamepark/dunaia/GameView";

export type DisplayPlayer = {
  type: "DisplayPlayer";
  color?: Color;
};

export const displayPlayerMove = (color?: Color): DisplayPlayer => ({
  type: "DisplayPlayer",
  color,
});

export function displayPlayer(state: GameView, move: DisplayPlayer) {
  state.displayedPlayer = move.color;
}
