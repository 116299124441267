/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, Fragment, HTMLAttributes, useEffect, useState } from 'react';
import { Player } from '@gamepark/dunaia/player/Player';
import Color from '@gamepark/dunaia/color/Color';
import { MainBoardBuilding } from './MainBoardBuilding';
import { BuildingsViewer } from '../building/BuildingsViewer';
import { useAnimation, usePlayerId } from '@gamepark/react-client';
import { canBuyAnyBuilding } from '@gamepark/dunaia/utils/building.utils';
import {
  buildingHeight,
  buildingWidth,
  mainBoardBuildingLeft,
  mainBoardBuildingTop,
  mainBoardBuildingTranslateZ,
  shineEffect
} from '../../styles/Style';
import { BoardBuildingPrices } from '@gamepark/dunaia/material/board/BoardBuildingPrices';

export type MainBoardBuildingsProps = {
  displayedColor?: Color;
  activePlayer: Player;
  activePlayerPanelIndex: number;
  buildings: number[][][];
  toBuyBuilding?: number;
} & HTMLAttributes<HTMLDivElement>;

const MainBoardBuildings: FC<MainBoardBuildingsProps> = (props) => {
  const playerId = usePlayerId();
  const { activePlayer, buildings, toBuyBuilding, displayedColor, activePlayerPanelIndex } = props;
  const [selectedBuildings, setSelectedBuildings] = useState<number[]>([]);
  const canChooseAnyBuilding = playerId === activePlayer.color && canBuyAnyBuilding(activePlayer);
  const animation = useAnimation();

  useEffect(() => {
    if (toBuyBuilding) {
      setSelectedBuildings([]);
    }
  }, [toBuyBuilding]);

  const selectBuildings = (e: any, buildings: number[]) => {
    if (!canChooseAnyBuilding) {
      return;
    }

    if (buildings?.length === 1) {
      return;
    }

    setSelectedBuildings(buildings);
    e.preventDefault();
    e.stopPropagation();
  };

  const mustShineStack = (level: number, colIndex: number, stackSize: number): boolean =>
    canChooseAnyBuilding && stackSize > 1 && BoardBuildingPrices[colIndex][level] <= activePlayer.metalFlowers && !animation;

  return (
    <>
      {buildings.map((col: number[][], colIndex: number) =>
        col.map((row, level) => {
            return (
              <Fragment key={`${colIndex}-${level}`}>
                {mustShineStack(level, colIndex, row.length) && (
                  <div
                    key={`${colIndex}-${level}-stack`}
                    css={[stackOverlay(level, colIndex, row.length), shineEffect()]}
                    onClick={(e) => selectBuildings(e, row)} />
                )}
                {row.flatMap((building, zIndex) => {
                  return (
                    <MainBoardBuilding
                      key={building}
                      toBuyBuilding={toBuyBuilding}
                      displayedColor={displayedColor}
                      building={building}
                      level={level}
                      row={row}
                      col={colIndex}
                      z={zIndex}
                      buildings={buildings}
                      activePlayer={activePlayer}
                      activePlayerPanelIndex={activePlayerPanelIndex}
                    />
                  );
                })}
              </Fragment>
            );
          }
        )
      )}
      {!!selectedBuildings?.length &&
        <BuildingsViewer key='building-viewver' buildings={selectedBuildings} onClose={() => {
          setSelectedBuildings([]);
        }} />}
    </>
  );
};

const stackOverlay = (level: number, col: number, stackSize: number) => css`
  position: absolute;
  width: ${buildingWidth}em;
  height: ${buildingHeight}em;
  top: ${mainBoardBuildingTop(level)}em;
  left: ${mainBoardBuildingLeft(col)}em;
  transform: translateZ(${mainBoardBuildingTranslateZ(stackSize + 1)}em);
  overflow: hidden;
  transform-style: preserve-3d;
  border-radius: 1em;
  cursor: pointer;
`;

export { MainBoardBuildings };
