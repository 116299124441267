/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import Color from '@gamepark/dunaia/color/Color';
import { FC, HTMLAttributes, useState } from 'react';
import { dunaiaAreaHeight, dunaiaAreaWidth, dunaiaProjection } from '../../styles/Style';
import { Draggable } from '@gamepark/react-components';
import { DraggableProps } from '@gamepark/react-components/dist/Draggable/Draggable';
import { playerDunaia } from '../../draggable/PlayerDunaia';
import { Animation, useAnimation, usePlay } from '@gamepark/react-client';
import { MoveDunaia } from '@gamepark/dunaia/moves/MoveDunaia';
import { isMoveDunaia } from '@gamepark/dunaia/utils/is-move.utils';
import { selectDunaiaMove } from '../../moves/SelectDunaia';
import { Player } from '@gamepark/dunaia/player/Player';
import { DunaiaPosition } from './DunaiaPosition';
import Move from '@gamepark/dunaia/moves/Move';
import { DunaiaFace } from './DunaiaFace';
import { DunaiaBase } from './DunaiaBase';

export type DunaiaProps = {
  dunaia: number;
  position: number;
  player: Player;
  activeColor: Color;
  selectedDunaia?: number;
  canBeMoved?: boolean;
  zoomed: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'> & Omit<DraggableProps, 'type'>;

const Dunaia: FC<DunaiaProps> = (props) => {
  const {
    dunaia,
    activeColor,
    player,
    position,
    preTransform,
    selectedDunaia: currentDunaia,
    zoomed,
    canBeMoved,
    ...rest
  } = props;
  const play = usePlay();
  const animation = useAnimation<MoveDunaia>((a) => isMoveDunaia(a.move) && player.color === activeColor);
  const [isDragging, setDragging] = useState(false);
  const highlight = !animation && canBeMoved;
  const selected = currentDunaia === dunaia;
  const draggableObject = playerDunaia(dunaia);

  const selectDunaia = () => {
    if (animation || !canBeMoved) {
      return;
    }

    play(selectDunaiaMove(selected ? undefined : dunaia), { local: true });
  };

  const onDrag = () => {
    setDragging(true);
    play(selectDunaiaMove(dunaia), { local: true });
    return draggableObject;
  };

  const onDrop = (move: Move) => {
    if (move) {
      play(move);
    }
  };

  const getAnimation = (animation: Animation<MoveDunaia>) => {
    if (isDragging) {
      return translateDunaiaToSquare(animation.move.position);
    }

    return animateDunaiaMove(animation);
  };

  const translate = `translate(${DunaiaPosition[position].left}em, ${DunaiaPosition[position].top}em) `;

  const isDunaiaAnimated = (d: number) => animation && d === animation.move.dunaia;
  return (
    <Draggable
      css={[
        dunaiaAreaStyle,
        highlight && selectableDunaiaArea,
        selected && selectedDunaiaArea,
        isDunaiaAnimated(dunaia) && getAnimation(animation!)
      ]}
      type={draggableObject.type}
      projection={zoomed ? dunaiaProjection : undefined}
      item={onDrag}
      preTransform={translate}
      postTransform={`translateZ(${selected || isDunaiaAnimated(dunaia) ? 3 : 0.1}em) rotateZ(15deg)`}
      canDrag={canBeMoved}
      drop={onDrop}
      end={selectDunaia}
      {...rest}
    >
      <DunaiaFace color={player.color} dunaia={dunaia} selected={selected} highlight={highlight}
                  css={canBeMoved && draggable} />
      <DunaiaBase color={player.color} selected={selected} highlight={highlight} css={canBeMoved && draggable} />
    </Draggable>
  );
};

const draggable = css`
  cursor: grab;
`;
const dunaiaMove = (newPosition: number) => {
  const translateX = DunaiaPosition[newPosition].left;
  const translateY = DunaiaPosition[newPosition].top;
  return keyframes`
    90% {
      transform: translate(${translateX}em, ${translateY}em) translateZ(9.5em) rotateZ(15deg);
    }
    to {
      transform: translate(${translateX}em, ${translateY}em) rotateZ(15deg);
    }
  `;
};

const translateDunaiaToSquare = (newPosition: number) => {
  const translateX = DunaiaPosition[newPosition].left;
  const translateY = DunaiaPosition[newPosition].top;
  return css`
    transition: transform 1s;
    transform: translate(${translateX}em, ${translateY}em) rotateZ(15deg);
  `;
};

const animateDunaiaMove = (animation: Animation<MoveDunaia>) => css`
  animation: ${dunaiaMove(animation.move.position)} ${animation.duration}s linear both;
`;

const dunaiaAreaStyle = css`
  position: absolute;
  height: ${dunaiaAreaHeight}em;
  width: ${dunaiaAreaWidth}em;
  background: radial-gradient(at center center,
  rgb(0, 0, 0) 0%,
  rgba(255, 255, 255, 0.02) 62%,
  rgba(255, 255, 255, 0) 63%);
  transform-style: preserve-3d;
`;

const selectedDunaiaArea = css`
  background: radial-gradient(at center center, green 50%, rgba(255, 255, 255, 0.02) 62%, rgba(255, 255, 255, 0) 63%);
`;

const selectableDunaiaArea = css`
  background: radial-gradient(at center center, gold 50%, rgba(255, 255, 255, 0.02) 62%, rgba(255, 255, 255, 0) 63%);
  cursor: pointer;

  &:hover {
    ${selectedDunaiaArea}
  }
`;


export { Dunaia };
