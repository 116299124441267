/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import { FC, HTMLAttributes } from 'react';
import { BuildingTile } from '../building/BuildingTile';
import { Die } from '../dice/Die';
import { ConstructionToken } from '../constructiontoken/ConstructionToken';
import { PlayerBuilding } from '@gamepark/dunaia/player/PlayerBuilding';
import {
  buildingConstructionTokenLeft,
  buildingConstructionTokenTop, buildingHeight,
  buildingWidth,
  constructionTokenToReserveTranslateX,
  constructionTokenToReserveTranslateY,
  diceOnBuildingLeft,
  diceOnBuildingTop,
  fadeOutDice,
  getDiceSize,
  playerBuildingColorTokenChooserLeft,
  playerBuildingColorTokenChooserTop, shineEffect
} from '../../styles/Style';
import { PlayerDice } from '@gamepark/dunaia/player/PlayerDice';
import { useAnimation, useAnimations, usePlay, usePlayerId } from '@gamepark/react-client';
import { isMoveConstructionToken, isRollDice } from '@gamepark/dunaia/utils/is-move.utils';
import { MoveConstructionToken, moveConstructionTokenMove } from '@gamepark/dunaia/moves/MoveConstructionToken';
import { RemoveConstructionTokenPercent } from '../../animations/AnimationConstants';
import { Position } from '@gamepark/dunaia/common/Position';
import { recycleDiceOnBuildingMove } from '@gamepark/dunaia/moves/RecycleDice';
import { selectBuildingMove } from '../../moves/SelectBuilding';
import { Buildings } from '@gamepark/dunaia/material/building/Buildings';
import { RollDice } from '@gamepark/dunaia/moves/RollDice';
import { useDrop } from 'react-dnd';
import { DragObjectType } from '../../draggable';
import { ColorToken } from '../colorToken/ColorToken';
import { Player } from '@gamepark/dunaia/player/Player';
import { canMoveConstructionToken, canPlaceColorToken } from '@gamepark/dunaia/utils/building.utils';
import { PlayerBuildingScoreOverlay } from './PlayerBuildingScoreOverlay';

type PlayerBuildingProps = {
  building: PlayerBuilding;
  dice: PlayerDice[];
  position: Position;
  scale?: number;
  force3D?: boolean;
  player: Player;
  canRecycleDie?: boolean;
  gameOver: boolean;
  notTargetable?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'color'>;

const PlayerBuildingTile: FC<PlayerBuildingProps> = (props) => {
  const play = usePlay();
  const playerId = usePlayerId();
  const {
    building,
    dice,
    scale,
    force3D,
    position,
    canRecycleDie,
    player,
    gameOver,
    notTargetable,
    ...rest
  } = props;


  const getDie = (building?: number) => dice.find((d) => d.building === building);
  const die = !!building && getDie(building.building);
  const canRecycleDieOnBuilding =
    !!canRecycleDie &&
    !!position &&
    !die &&
    !building.constructionToken &&
    !!Buildings[building.building].recyclingEffect;

  // If there is no position, it means that the building is not on the board
  const animation = useAnimation<MoveConstructionToken | RollDice>(
    (a) => (isMoveConstructionToken(a.move) && a.move.building === building.building && !!position) || isRollDice(a.move)
  );
  const moveTokenAnimation = animation && isMoveConstructionToken(animation.move)? animation: undefined;
  const rollDiceAnimation = animation && isRollDice(animation.move)? animation: undefined;

  const iCanMoveConstructionToken = canMoveConstructionToken(player, building) && !!position && player.color === playerId;

  const iCanPlaceColorToken = !!position && canPlaceColorToken(player, building, Buildings)

  const hasAnimation = !!useAnimations().length;

  const [{ isOver }, ref] = useDrop({
    accept: DragObjectType.PlayerPanelDice,
    canDrop: () => canRecycleDieOnBuilding,
    drop: () => recycleDiceOnBuildingMove(building.building),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const onBuildingOverlayClick = () => {
    if (canRecycleDieOnBuilding) {
      play(recycleDiceOnBuildingMove(building.building));
      return;
    }

    if (iCanMoveConstructionToken) {
      play(moveConstructionTokenMove(building.building));
      return;
    }

    if (iCanPlaceColorToken) {
      showBuilding()
      return;
    }
  };

  const  showBuilding = () => play(selectBuildingMove(building.building), { local: true });
  const additionalColors = building.additionalColors ?? [];
  return (
    <>
      <div {...rest}>
          <BuildingTile
            key={building.building}
            scale={scale}
            building={building.building}
            constructed={!building.constructionToken}
            css={[moveTokenAnimation && animateBuildingBuilt(moveTokenAnimation.duration)]}
            onClick={showBuilding}
          />
        {!!die && (
          <Die
            color={die.dice.color}
            value={die.dice.value}
            css={[
              diceIndicator(scale),
              rollDiceAnimation && fadeOutDice(rollDiceAnimation.duration)]}
            scale={scale}
            postTransform={force3D ? 'rotateZ(-10deg)' : undefined}
          />
        )}
        {!!building.constructionToken && (
          <ConstructionToken
            scale={scale}
            color={player.color}
            css={[
              constructionTokenPosition(building.constructionToken, scale),
              moveTokenAnimation &&
                animateConstructionTokenToReserve(
                  moveTokenAnimation.duration,
                  position!,
                  player.constructionTokens!,
                  building.constructionToken
                ),
            ]}
          />
        )}
        {additionalColors.map((c, index) => (
          <ColorToken key={c} color={c} css={playerBuildingColorToken(index, scale)} scale={scale}/>
        ))}
        {!notTargetable && !hasAnimation && (canRecycleDieOnBuilding || iCanMoveConstructionToken || iCanPlaceColorToken) && (
          <div
            ref={ref}
            key={`${position.x}-${position.y}`}
            css={[targetStyle(scale),isOver && hoverBuilding, shineEffect()]}
            onClick={onBuildingOverlayClick}
          />
        )}
        {gameOver && !building.constructionToken && <PlayerBuildingScoreOverlay player={player} playerBuilding={building} position={position} scale={scale} /> }
      </div>
    </>
  );
};

const targetStyle = (scale: number = 1) => css`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1em;
  height: ${buildingHeight * scale}em;
  width: ${buildingWidth * scale}em;
  transform: translateZ(0);
  &:hover {
    ${hoverBuilding}
  }
`


const playerBuildingColorToken = (position: number, scale: number = 1) => css`
  position: absolute;
  left: ${playerBuildingColorTokenChooserLeft(scale)}em;
  top: ${playerBuildingColorTokenChooserTop(position, scale)}em;
  border-radius: 50%;
`


const diceIndicator = (scale: number = 1) => css`
  position: absolute;
  left: ${diceOnBuildingLeft * scale + (getDiceSize(scale) - getDiceSize()) / 2}em;
  top: ${diceOnBuildingTop * scale + (getDiceSize(scale) - getDiceSize()) / 2}em;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const constructionTokenPosition = (position: number, scale?: number) => css`
  position: absolute;
  transition: 0.2s left, 0.2s top;
  left: ${buildingConstructionTokenLeft(position) * (scale || 1)}em;
  top: ${buildingConstructionTokenTop(position) * (scale || 1)}em;
  filter: drop-shadow(0 0 0.2em black) drop-shadow(0 0 0.2em black);
`;

const constructionTokenToReserve = (
  position: Position,
  constructionTokens: number,
  actualConstructionTokenPosition: number
) => {
  const translateX = constructionTokenToReserveTranslateX(position.x, actualConstructionTokenPosition);
  const translateY = constructionTokenToReserveTranslateY(
    position.y,
    constructionTokens,
    actualConstructionTokenPosition
  );
  return keyframes`
    20% {
      transform: translate(${translateX / 10}em, ${translateY / 10}em) translateZ(10em);
    }
    to {
      transform: translate(${translateX}em, ${translateY}em);
    }
  `;
};


const animateConstructionTokenToReserve = (
  duration: number,
  position: Position,
  constructionTokens: number,
  actualConstructionTokenPosition: number
) => css`
  animation: ${constructionTokenToReserve(position, constructionTokens, actualConstructionTokenPosition)}
    ${duration * RemoveConstructionTokenPercent}s linear both;
`;

const buildingBuilt = keyframes`
    50% {
      transform-style: preserve-3d;
      transform: translateZ(${buildingWidth * 0.8}em) rotateY(90deg)
    }
    to {
      transform-style: preserve-3d;
      transform: rotateY(0deg);
    }
  `;


const animateBuildingBuilt = (duration: number) => css`
  animation: ${duration * (1 - RemoveConstructionTokenPercent)}s ${buildingBuilt}
    ${duration * RemoveConstructionTokenPercent}s linear both;
`;

const hoverBuilding = css`
  background-color: rgba(255, 255, 255, 0.8);
`

export { PlayerBuildingTile };
