import { DragObjectType } from './'

export type MainBoardBuilding = {
  type: typeof DragObjectType.MainBoardBuilding
  building: number
}

export function mainBoardBuilding(building: number): MainBoardBuilding {
    return {
        type: DragObjectType.MainBoardBuilding,
      building
    }
}
