/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import GameView from '@gamepark/dunaia/GameView';
import { Letterbox } from '@gamepark/react-components';
import { PlayerBoard } from './material/player/PlayerBoard';
import { MainBoard } from './material/board/MainBoard';
import { usePlay, usePlayerId, useTutorial } from '@gamepark/react-client';
import { PlayerPanels } from './material/player/PlayerPanels';
import { GameProphecies } from './material/board/GameProphecies';
import { canEndTurn } from '@gamepark/dunaia/utils/end.utils';
import { endTurnMove } from '@gamepark/dunaia/moves/EndTurn';
import { ElderPowerViewer } from './material/elderpower/ElderPowerViewer';
import { BuildingViewer } from './material/building/BuildingViewer';
import { getBuildingPosition, getPrice } from '@gamepark/dunaia/utils/building.utils';
import { MemoryChipChooser } from './material/memorychip/MemoryChipChooser';
import { useMemo, useState } from 'react';
import { getNonRecycledDie } from '@gamepark/dunaia/utils/dice.utils';
import { ProphecyViewer } from './material/prophecy/ProphecyViewer';
import { IconHelp } from './material/icon-help/IconHelp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { shineEffect } from './styles/Style';
import { isGameOver } from '@gamepark/dunaia/utils/is-over.utils';
import { useTranslation } from 'react-i18next';
import scenario from './Tutorial/scenario.json';
import { Scenario, Tutorial } from './Tutorial/Tutorial';

type Props = {
  game: GameView;
};

const ShowTutorialButton = (props: any) => {
  const { t } = useTranslation()
  return <div css={[greenButton, showTutorialButton]} {...props}><span>{t('button.show-tutorial')}</span></div>;
}

export default function GameDisplay({ game }: Props) {
  const playerId = usePlayerId();
  const play = usePlay();
  const { t } = useTranslation();
  const [showIconHelp, setShowIconHelp] = useState<boolean>(false);
  const gameOver = isGameOver(game);
  const tutorial = useTutorial();
  const displayedPlayer = game.players.find(
    (p) => p.color === (game.displayedPlayer || playerId || game.activePlayer)
  )!;
  const activePlayer = game.players.find((p) => p.color === game.activePlayer)!;
  const canEnd = playerId === game.activePlayer && canEndTurn(activePlayer, game.activePlayer);

  const sortedPlayers = useMemo(() => {
    if (!playerId) {
      return game.players;
    }
    return [game.players.find((p) => p.color === playerId)!, ...game.players.filter((p) => p.color !== playerId)];
  }, [playerId, game.players]);
  const activePlayerPanelIndex = useMemo(
    () => sortedPlayers.findIndex((p) => activePlayer.color === p.color),
    [sortedPlayers, activePlayer]
  );

  const diceRecycledOnBoard = useMemo(
    () => game.players.flatMap((p) => p.dice.filter((d) => d.boardAction !== undefined)),
    [game.players]
  );

  const hasEnoughFlowerForRecycling = displayedPlayer.color === playerId? true: activePlayer.metalFlowers > 0;
  const canRecycleDie = !!getNonRecycledDie(activePlayer.dice) && activePlayer.color === playerId && !activePlayer.pending?.length && hasEnoughFlowerForRecycling
  return (
    <Letterbox css={letterBoxStyle} width={185} height={100} id="letterbox">
      <div css={perspective}>
        <PlayerPanels
          sortedPlayers={sortedPlayers}
          displayedColor={displayedPlayer.color}
          activeColor={game.activePlayer}
          diceRecycledOnBoard={diceRecycledOnBoard}
          gameOver={gameOver}
        />
        <PlayerBoard
          player={displayedPlayer}
          toBuyBuilding={game.toBuyBuilding}
          selectedDunaia={game.selectedDunaia}
          activeColor={game.activePlayer}
          canRecycleDie={canRecycleDie}
          gameOver={gameOver}
          buildings={game.buildings}
          recycledDice={game.players.flatMap((p) => p.dice.filter((d) => d.building !== undefined))}
        />
        {canEnd && (<div css={[greenButton, shineEffect()]} onClick={() => play(endTurnMove)}><span>{t('end.turn')}</span></div>)}
        {/** Portal for Show tutorial button. Mandatory to display button in right place when closed **/}
        <div css={tutorialButton} id="tutorial-button"></div>
        <div css={actionStyle} onClick={() => setShowIconHelp(true)}>
          <FontAwesomeIcon icon={faQuestion} /> <span>{t('help.icons')}</span>
        </div>

        <MainBoard
          buildings={game.buildings}
          elderPowers={game.elderPowers}
          activePlayer={activePlayer}
          displayedColor={displayedPlayer.color}
          diceRecycledOnBoard={diceRecycledOnBoard}
          dice={game.dice}
          toBuyBuilding={game.toBuyBuilding}
          activePlayerPanelIndex={activePlayerPanelIndex}
        />
        <GameProphecies
          prophecies={game.prophecies}
          activePlayer={activePlayer}
          activePlayerPanelIndex={activePlayerPanelIndex}
        />
      </div>
      {game.selectedElderPower !== undefined && (
        <ElderPowerViewer
          elderPowerIndex={game.selectedElderPower}
          elderPowers={game.elderPowers}
          player={activePlayer}
        />
      )}
      {game.selectedBuilding !== undefined && (
        <BuildingViewer
          building={game.selectedBuilding}
          activePlayer={activePlayer}
          players={game.players}
          gameOver={gameOver}
          position={getBuildingPosition(displayedPlayer, game.selectedBuilding)}
          displayedColor={game.displayedPlayer}
          recycledDice={game.players.flatMap((p) => p.dice.filter((d) => d.building !== undefined))}
          price={getPrice(game.selectedBuilding, game.buildings)}
        />
      )}
      {game.selectedProphecy !== undefined && (
        <ProphecyViewer prophecy={game.selectedProphecy} />
      )}
      { showIconHelp && (<IconHelp onClose={() => setShowIconHelp(false)} />)}
      <MemoryChipChooser player={activePlayer} />
      { !!tutorial && (
        <Tutorial
          scenario={scenario as Scenario}
          tutorial={tutorial}
          state={game}
          showTutorialButton={ShowTutorialButton}
        />
      ) }
    </Letterbox>
  );
}

const fadeIn = keyframes`
  from, 50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const perspective = css`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  transform: translate(1%, -3%) perspective(250em) rotateX(10deg);
  transform-origin: 15% 125%;
  transform-style: preserve-3d;
`;

const tutorialButton = css`
  position: absolute;
  top: 14em;
  right: 68.5em;
`;

const showTutorialButton = css`
  position: unset;
  max-width: 30em;
  
  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
`

const greenButton = css`
  top: 7.2em;
  right: 68.5em;
  position: absolute;
  border-radius: 1em;
  background-color: darkgreen;
  border: 0.2em solid black;
  box-shadow: 0 0 0.3em black;
  max-width: 22.5em;
  padding: 1.3em 1em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateZ(0);

  &:hover {
    background-color: lightgray;
    > span {
      color: black;
    }
  }

  > span {
    color: white;
    margin: 0;
    font-size: 2.5em;
  }
`;

const actionStyle = css`
  top: 0.2em;
  right: 68.5em;
  position: absolute;
  border-radius: 1em;
  background-color: gold;
  border: 0.2em solid black;
  box-shadow: 0 0 0.3em black;
  max-width: 21.5em;
  padding: 1.3em 1em;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: lightgray;
  }

  > svg {
    font-size: 3em;
  }

  > span {
    padding-left: 0.4em;
    font-size: 2.5em;
  }
`;

const letterBoxStyle = css`
  animation: ${fadeIn} 3s ease-in forwards;
`;
