
import { GameEffectRules } from './GameEffectRules';
import { GainMetalFlowerEffect } from '../GainMetalFlowerEffect';
import { gainMetalFlowersMove } from '../../moves/GainMetalFlowers';
import Move from '../../moves/Move';

class GainMetalFlowerEffectRules extends GameEffectRules<GainMetalFlowerEffect>{

  constructor(effect: GainMetalFlowerEffect) {
    super(effect);
  }

  automaticMoves(): Move[] {
    return [gainMetalFlowersMove(this.effect.metalFlowers)];
  }
}

export {
  GainMetalFlowerEffectRules
};
