/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC, HTMLAttributes, useState } from 'react';
import { ArtefactPart } from '../artefact/ArtefactPart';
import {
  artefactPartLeft,
  artefactPartTop,
  completeArtefactLeft,
  partialArtefactLeft,
  playerArtefactPartHeight,
  playerArtefactPartWidth,
  playerArtefactTop,
  playerPanelHeight
} from '../../styles/Style';
import { Animation, useAnimation } from '@gamepark/react-client';
import { GainArtefactPart } from '@gamepark/dunaia/moves/GainArtefactPart';
import { isGainArtefactPart } from '@gamepark/dunaia/utils/is-move.utils';
import { Player } from '@gamepark/dunaia/player/Player';
import Color from '@gamepark/dunaia/color/Color';
import { ArtefactHelpModal } from '../../help/ArtefactHelpModal';

export type ArtefactProps = {
  activeColor: Color;
  player: Player;
} & HTMLAttributes<HTMLDivElement>;

const PlayerArtefact: FC<ArtefactProps> = (props) => {
  const { player, activeColor, ...rest } = props;
  const artefacts = player.artefacts;
  const completedArtefactsCount = artefacts.filter((a) => a.length === 3).length;
  const incompleteArtefact =
    !!artefacts?.length && artefacts[artefacts.length - 1].length < 3 ? artefacts[artefacts.length - 1] : undefined;
  const animation = useAnimation<GainArtefactPart>((a) => isGainArtefactPart(a.move) && activeColor === player.color && incompleteArtefact?.length === 2 );
  const [showHelp, setShowHelp] = useState(false);

  const displayHelp = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHelp(true)
  }

  return (
    <>
      <div key="completed" css={[playerArtefactStyle, completeArtefact]} onClick={displayHelp} {...rest}>
        {Array.from(Array(3)).map((_, p: number) => (
          <ArtefactPart key={p} part={p} css={artefactPart(p, true)} />
        ))}
        <div css={completeCount}>{completedArtefactsCount}</div>
      </div>
      {
        <div key="incomplete" css={[playerArtefactStyle, partialArtefact, animation && slideArtefactToComplete(animation)]} onClick={displayHelp} {...rest}>
          {Array.from(Array(3)).map((_, p) => (
            <ArtefactPart key={p} part={p} css={artefactPart(p, !!incompleteArtefact?.includes(p))} />
          ))}
        </div>
      }
      { showHelp && <ArtefactHelpModal onClose={() => setShowHelp(false)} /> }
    </>
  );
};

const completeCount = css`
  position: absolute;
  left: 50%;
  top: 54%;
  transform: translate(-50%, -50%);
  font-size: 6em;
  color: black;
  font-weight: bold;
  text-shadow: 0 0 0.1em white, 0 0 0.1em white, 0 0 0.1em white, 0 0 0.1em white;
`;

const completeArtefact = css`
  position: absolute;
  left: ${completeArtefactLeft}em;
  z-index: 2;
`;

const partialArtefact = css`
  position: absolute;
  left: ${partialArtefactLeft}em;
  z-index: 1;
`;

const playerArtefactStyle = css`
  height: ${playerPanelHeight * 0.6}em;
  width: ${playerPanelHeight * 0.6}em;
  filter: drop-shadow(0 0 0.2em black);
  top: ${playerArtefactTop}em;
  cursor: help;

  &:hover {
    filter: drop-shadow(0 0 0.2em black) brightness(110%);
  }
`;

const slideArtefactToComplete = (animation: Animation<GainArtefactPart>) => {
  const delay = animation.duration * (2 / 3);
  return css`
    transition: left ${animation.duration - (delay * 1.1)}s;
    transition-delay: ${delay * 1.1}s;
    left: ${completeArtefactLeft}em;
    > div {
      transition: opacity 0s;
      transition-delay: ${delay}s;
      opacity: 1;
    }
  `
}

const artefactPart = (part: number, hasPart: boolean = true) => css`
  position: absolute;
  top: ${artefactPartTop(part)}em;
  left: ${artefactPartLeft(part)}em;
  height: ${playerArtefactPartHeight(part)}em;
  width: ${playerArtefactPartWidth(part)}em;
  opacity: ${hasPart ? 1 : 0.15};
`;

export { PlayerArtefact };
